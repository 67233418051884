.series-list {
    margin-top:-20px;
    
    @include media-breakpoint-up(dsk) {
        margin-top:-30px;
    }
    
    .series {
        display:flex; float:left;
        width:100%;
        margin-top:20px;
        background-color:$col-band-red;
        overflow:hidden;
        @include prefix(border-radius, 8px, webkit moz o ms);
        flex-direction:row; align-items:center;
        
        @include media-breakpoint-up(dsk) {
            margin-top:30px;
            display:block;
        }
        
        > .cover {
            position:relative;
            display:block; float:left;
            width:40%;
            overflow:hidden;
            @include prefix(border-radius, 8px, webkit moz o ms);
            
            @include media-breakpoint-up(dsk) {
                width:282px;
            }
            
            &:before {
                content:'';
                position:absolute; left:0; top:0;
                width:100%; height:100%;
                background-color:$col-black25;
                z-index:2;
            }
            span {
                position:absolute; left:50%; top:50%;
                width:46px; height:46px;
                background-color:$col-red;
                @include prefix(border-radius, 50%, webkit moz o ms);
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                z-index:3;

                i {
                    position:absolute; left:50%; top:50%;
                    width:20px; height:20px;
                    font-size:20px; line-height:20px; color:$col-white;
                    @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                }
            }
            div {
                position:relative;
                float:left;
                width:100%; padding-bottom:138%;
                background-position:center top; background-repeat:no-repeat; background-size:cover;
                z-index:1;
                @include prefix(filter, grayscale(0), webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
                
                @include media-breakpoint-up(dsk) {
                    padding-bottom:88%;
                }
            }
        }
        > .content {
            position:relative;
            display:block; float:left;
            width:60%;
            padding:20px 16px;
            
            @include media-breakpoint-up(dsk) {
                width:calc(100% - 282px);
                padding:58px 100px 58px 46px;
            }
            
            .category {
                position:relative;
                display:block; float:left;
                width:auto;
                padding-bottom:2px;
                font-size:15px; line-height:18px; color:$col-red; font-weight:900; text-decoration:none;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                &:after {
                    content:'';
                    position:absolute; left:0; bottom:0;
                    width:100%; height:2px;
                    background-color:$col-red;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }

                @include media-breakpoint-up(lg) {
                    &:hover {
                        color:$col-light-red;

                        &:after {
                            background-color:$col-light-red;
                        }
                    }
                }
            }
            h3, .h3 {
                margin-top:12px;
                font-size:20px; line-height:28px; color:$col-black; font-weight:800;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
                
                @include media-breakpoint-up(dsk) {
                    margin-top:14px;
                    font-size:24px; line-height:32px;
                }
            }
        }
        
        @include media-breakpoint-up(lg) {
            &:hover {
                > .cover {
                    div {
                        @include prefix(filter, grayscale(100%), webkit moz o ms);
                    }
                }
                > .content {
                    h3, .h3 {
                        color:$col-grey;
                    }
                }
            }
        }
    }
    
    &.small {
        .series {
            > .cover {
                @include media-breakpoint-up(dsk) {
                    width:100%;
                }
                @include media-breakpoint-up(lg) {
                    width:calc(182px + 100 * ((100vw - 992px) / 608));
                }
                @include media-breakpoint-up(xxl) {
                    width:282px;
                }
                div {
                    @include media-breakpoint-up(dsk) {
                        padding-bottom:56.25%;
                    }
                    @include media-breakpoint-up(lg) {
                        padding-bottom:88%;
                    }
                }
            }
            > .content {
                @include media-breakpoint-up(dsk) {
                    width:100%;
                    padding:20px;
                }
                @include media-breakpoint-up(lg) {
                    width:calc(100% - (182px + 100 * ((100vw - 992px) / 608)));
                    padding:calc(36px + 2 * ((100vw - 992px) / 608)) calc(30px + 70 * ((100vw - 992px) / 608)) calc(36px + 2 * ((100vw - 992px) / 608)) calc(30px + 16 * ((100vw - 992px) / 608));
                }
                @include media-breakpoint-up(xxl) {
                    width:calc(100% - 282px);
                    padding:38px 100px 38px 46px;
                }

                .category {
                    position:relative;
                    display:block; float:left;
                    width:auto;
                    padding-bottom:2px;
                    font-size:15px; line-height:18px; color:$col-red; font-weight:900; text-decoration:none;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);

                    &:after {
                        content:'';
                        position:absolute; left:0; bottom:0;
                        width:100%; height:2px;
                        background-color:$col-light-red;
                        @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    }

                    @include media-breakpoint-up(lg) {
                        &:hover {
                            color:$col-light-red;

                            &:after {
                                background-color:$col-light-red;
                            }
                        }
                    }
                }
                h3, .h3 {
                    margin-top:12px;
                    font-size:20px; line-height:28px; color:$col-black; font-weight:800;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);

                    @include media-breakpoint-up(dsk) {
                        margin-top:14px;
                        font-size:24px; line-height:32px;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                &:hover {
                    > .cover {
                        div {
                            @include prefix(filter, grayscale(100%), webkit moz o ms);
                        }
                    }
                    > .content {
                        h3, .h3 {
                            color:$col-grey;
                        }
                    }
                }
            }
        }        
    }
}