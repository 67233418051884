input, textarea, select {
    @include prefix(border-radius, 0!important, webkit moz o ms);
    @include prefix(appearance, none!important, webkit moz o ms);
}
        
form {
    display:block; float:left;
    width:100%;

    fieldset {
        display:block; float:left;
        width:100%;
        
        .form-field {
            position:relative;
            float:left;
            width:100%;
        }
    }
}