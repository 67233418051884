.header {
    position:absolute; left:0; top:0; right:0;
    display:block; float:left;
    width:100%!important;
    padding:13px 16px;
    background-color:$col-black;
    z-index:9999;
    @include prefix(backdrop-filter, none, webkit moz o ms);
    //@include prefix(transition, all .4s ease-out, webkit moz o ms);

    > div {
        position:relative;
        float:left;
        width:100%;

        &:before, &:after {
            position:absolute; bottom:0px;
            width:65px; height:55px;
            z-index:3;
        }
        &:before {
            content:none;
            left:-16px;
            background:rgb(0,0,0);
            background:-moz-linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            background:-webkit-linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            background:linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
        }
        &:after {
            content:'';
            right:-16px;
            background:rgb(0,0,0);
            background:-moz-linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            background:-webkit-linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            background:linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
        }


        &.mobile-scroll-start {
            &:before {
                content:'';
            }
        }
        &.mobile-scroll-end {
            &:after {
                content:none;
            }
        }

        @include media-breakpoint-up(xl) {
            &:before, &:after {
                content:none;
            }
        }
    }

    @include media-breakpoint-up(md) {
        position:fixed; top:0!important;
    }
    @include media-breakpoint-up(lg) {
        padding:20px 30px;
    }
    @include media-breakpoint-up(xl-1440) {
        padding:calc(20px + 6 * ((100vw - 1440px) / 480)) calc(30px + 10 * ((100vw - 1440px) / 480));
    }
    @include media-breakpoint-up(xxl-1920) {
        padding:26px 40px;
        font-size:16px; line-height:28px;
    }

    &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        background-color:$col-black;
        z-index:2;
    }
    .header-logo {
        position:relative;
        float:initial;
        height:40px; width:145px;
        padding-left:0;
        z-index:3;

        img {
            height:100%; width:auto;
        }

        @include media-breakpoint-up(lg) {
            height:68px; width:200px;
        }
        @include media-breakpoint-up(lg) {
            float:left;
        }
        @include media-breakpoint-up(xl-1440) {
            height:calc(68px + 17 * ((100vw - 1440px) / 480)); width:calc(250px + 62 * ((100vw - 1440px) / 480));
            padding-left:calc(50px + 12 * ((100vw - 1440px) / 480)); margin-top:calc(0px + 2 * ((100vw - 1440px) / 480));
        }
        @include media-breakpoint-up(xxl-1920) {
            height:85px; width:312px;
            padding-left:62px; margin-top:2px;
        }

        a {
            display:block;
            height:100%;
        }
    }
    .header-main {
        position:relative;
        display:block; float:left;
        width:calc(100% + 32px); height:34px;
        margin:24px -16px 0 -16px;
        z-index:2;
        text-align:center; font-size:0;
        overflow:auto; overflow-y:hidden;

        &::-webkit-scrollbar-track {
            background-color:transparent;
        }
        &::-webkit-scrollbar {
            background-color:transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color:transparent;
        }

        /*&:before, &:after {
            position:fixed; top:65px;
            width:65px; height:55px;
            z-index:3;
        }
        &:before {
            content:none;
            left:0;
            background:rgb(0,0,0);
            background:-moz-linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            background:-webkit-linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            background:linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
        }
        &:after {
            content:'';
            right:0;
            background:rgb(0,0,0);
            background:-moz-linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            background:-webkit-linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            background:linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
            filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
        }*/

        /*&.mobile-scroll-start {
            &:before {
                content:'';
            }
        }
        &.mobile-scroll-end {
            &:after {
                content:none;
            }
        }*/

        @include media-breakpoint-up(xl) {
            width:auto; height:auto;
            margin:24px 0 0 30px;
            text-align:left;
            overflow:visible;

            /*&:before, &:after {
                content:none;
            }*/
        }
        @include media-breakpoint-up(xl-1440) {
            margin:calc(30px + 6 * ((100vw - 1440px) / 480)) 0 0 calc(38px + 12 * ((100vw - 1440px) / 480));
        }
        @include media-breakpoint-up(xxl-1920) {
            margin:36px 0 0 50px;
        }

        ul {
            display:inline-block; float:none;
            width:auto;
            white-space:nowrap;
            padding:0 16px 12px 16px;

            @include media-breakpoint-up(lg) {
                padding:0;
            }

            li {
                display:inline-block; float:none;
                width:auto;
                margin-right:20px;

                @include media-breakpoint-up(lg) {
                    display:block; float:left;
                }

                @include media-breakpoint-up(xl) {
                    margin-right:24px;
                }
                @include media-breakpoint-up(xl-1440) {
                    margin-right:calc(24px + 16 * ((100vw - 1440px) / 480));
                }
                @include media-breakpoint-up(xxl-1920) {
                    margin-right:40px;
                }

                &:first-child {
                    display:none;
                    margin-right:20px;

                    @include media-breakpoint-up(xl-1400) {
                        display:block;
                        margin-right:26px;
                    }
                    @include media-breakpoint-up(xl-1440) {
                        margin-right:calc(26px + 20 * ((100vw - 1440px) / 480));
                    }
                    @include media-breakpoint-up(xxl-1920) {
                        margin-right:46px;
                    }
                }
                &:last-child {
                    margin-right:0;
                }

                span, a {
                    position:relative;
                    display:block; float:left;
                    width:auto;
                    line-height:20px;

                    @include media-breakpoint-up(lg) {
                        line-height:32px;
                    }
                    @include media-breakpoint-up(xl-1440) {
                        line-height:calc(32px + 8 * ((100vw - 1440px) / 480));
                    }
                    @include media-breakpoint-up(xxl-1920) {
                        line-height:40px;
                    }

                    &:after {
                        content:'';
                        position:absolute; left:50%; bottom:-5px;
                        width:0%; height:1px;
                        background-color:$col-red;
                        @include prefix(transform, translateX(-50%), webkit moz o ms);
                        @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    }
                }
                span {
                    font-family:$font_caveat; font-size:15px; color:$col-red;

                    @include media-breakpoint-up(lg) {
                        font-size:25px;
                    }
                    @include media-breakpoint-up(xl-1440) {
                        font-size:calc(25px + 7 * ((100vw - 1440px) / 480));
                    }
                    @include media-breakpoint-up(xxl-1920) {
                        font-size:32px;
                    }

                    &:after {
                        width:100%;
                    }
                }
                a {
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    font-family:$font_raleway; font-weight:900; font-size:15px; color:$col-white; text-transform:uppercase; text-decoration:none;

                    @include media-breakpoint-up(dsk) {
                        font-size:17px;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size:19px;
                    }
                    @include media-breakpoint-up(xl-1440) {
                        font-size:calc(19px + 4 * ((100vw - 1440px) / 480));
                    }
                    @include media-breakpoint-up(xxl-1920) {
                        font-size:23px;
                    }

                    &:hover {
                        text-decoration:underline!important;
                    }

                    @include media-breakpoint-up(lg) {
                        &:hover {
                            text-decoration:none!important;
                            color:$col-grey;

                            &:after {
                                width:100%;
                            }
                        }
                    }
                }
            }
        }

        &.focus {
            ul {
                li {
                    span {
                        &:after {
                            width:0%;
                        }
                    }
                }
            }
        }
    }
    .mobile-scroller {
        position:absolute; left:50%; bottom:0/*8px*/;
        display:none;
        width:170px; height:3px;
        background-color:$col-scroll-grey;
        z-index:3;
        @include prefix(transform, translateX(-50%), webkit moz o ms);
        @include prefix(border-radius, 5px, webkit moz o ms);

        @include media-breakpoint-up(xl) {
            display:none!important;
        }

        &:before {
            content:'';
            position:absolute; left:0; top:0;
            width:50%; height:100%;
            background-color:$col-white;
            @include prefix(border-top-left-radius, 5px, webkit moz o ms);
            @include prefix(border-bottom-left-radius, 5px, webkit moz o ms);
        }
        span {
            position:absolute; left:50%; top:0;
            width:0%; height:100%; max-width:50%!important;
            background-color:$col-white;
            @include prefix(border-top-right-radius, 5px, webkit moz o ms);
            @include prefix(border-bottom-right-radius, 5px, webkit moz o ms);

            &:after {
                content:'';
                position:absolute; right:0; bottom:0;
                width:2px; height:6px;
                background-color:$col-white;
            }
        }
    }
    &.mobile-scroller {
        .mobile-scroller {
            display:block;
        }
    }
    .header-component {
        position:absolute; top:0; right:0;
        float:right;
        margin:0;
        z-index:4;

        @include media-breakpoint-up(xl) {
            position:relative; top:inherit; right:inherit;
            margin:8px 0;
        }
        @include media-breakpoint-up(xl-1440) {
            margin:calc(5px + 3 * ((100vw - 1440px) / 480)) 0;
        }
        @include media-breakpoint-up(xxl-1920) {
            margin:8px 0;
        }

        .header-search {
            position:relative;
            float:left;
            width:40px; height:40px;
            margin-right:14px;

            @include media-breakpoint-up(lg) {
                width:48px; height:48px;
                margin-right:22px;
            }
            @include media-breakpoint-up(xl-1440) {
                width:calc(48px + 16 * ((100vw - 1440px) / 480)); height:calc(48px + 16 * ((100vw - 1440px) / 480));
                margin-right:calc(22px + 8 * ((100vw - 1440px) / 480));
            }
            @include media-breakpoint-up(xxl-1920) {
                width:64px; height:64px;
                margin-right:30px;
            }

            .search-btn {
                position:absolute; right:0; top:0;
                width:40px; height:40px;
                z-index:2;
                opacity:1;
                @include prefix(transition, all .2s ease-out, webkit moz o ms);

                i {
                    position:absolute; left:50%; top:50%;
                    width:22px; height:22px;
                    font-size:22px; line-height:22px; color:$col-white;
                    @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }

                @include media-breakpoint-up(lg) {
                    width:48px; height:48px;

                    i {
                        width:20px; height:20px;
                        font-size:20px; line-height:20px;
                    }

                    &:hover {
                        i {
                            color:$col-grey;
                        }
                    }
                }
                @include media-breakpoint-up(xl-1440) {
                    width:calc(48px + 16 * ((100vw - 1440px) / 480)); height:calc(48px + 16 * ((100vw - 1440px) / 480));

                    i {
                        width:calc(20px + 8 * ((100vw - 1440px) / 480)); height:calc(20px + 8 * ((100vw - 1440px) / 480));
                        font-size:calc(20px + 8 * ((100vw - 1440px) / 480)); line-height:calc(20px + 8 * ((100vw - 1440px) / 480));
                    }
                }
                @include media-breakpoint-up(xxl-1920) {
                    width:64px; height:64px;

                    i {
                        width:28px; height:28px;
                        font-size:28px; line-height:28px;
                    }
                }
            }

            form {
                position:absolute; right:0; top:0;
                z-index:1;
                opacity:0;
                @include prefix(transition, all .2s ease-out, webkit moz o ms);

                fieldset {
                    > div {
                        position:relative;
                        float:right;
                        width:40px;
                        @include prefix(transition, all .2s ease-out, webkit moz o ms);

                        @include media-breakpoint-up(lg) {
                            width:48px;
                        }
                        @include media-breakpoint-up(xl-1440) {
                            width:calc(48px + 16 * ((100vw - 1440px) / 480));
                        }
                        @include media-breakpoint-up(xxl-1920) {
                            width:64px;
                        }

                        button {
                            position:absolute; right:0; top:0;
                            width:40px; height:40px;
                            padding:0; margin:0; border:0 none;
                            background-color:transparent;
                            z-index:1;

                            i {
                                position:absolute; left:50%; top:50%;
                                width:22px; height:22px;
                                font-size:22px; line-height:22px; color:$col-black;
                                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                            }

                            @include media-breakpoint-up(lg) {
                                width:48px; height:48px;

                                i {
                                    width:20px; height:20px;
                                    font-size:20px; line-height:20px;
                                }
                            }
                            @include media-breakpoint-up(xl-1440) {
                                width:calc(48px + 16 * ((100vw - 1440px) / 480)); height:calc(48px + 16 * ((100vw - 1440px) / 480));

                                i {
                                    width:calc(20px + 8 * ((100vw - 1440px) / 480)); height:calc(20px + 8 * ((100vw - 1440px) / 480));
                                    font-size:calc(20px + 8 * ((100vw - 1440px) / 480)); line-height:calc(20px + 8 * ((100vw - 1440px) / 480));
                                }
                            }
                            @include media-breakpoint-up(xxl-1920) {
                                width:64px; height:64px;

                                i {
                                    width:28px; height:28px;
                                    font-size:28px; line-height:28px;
                                }
                            }
                        }
                        input {
                            display:block; float:left;
                            width:100%;
                            padding:5px 40px 5px 20px; margin:0; border:0 none;
                            font-size:18px; line-height:30px; color:$col-dark-grey; font-family:$font_raleway; letter-spacing:0.4px; font-weight:600;
                            background-color:$col-white;
                            @include prefix(border-radius, 64px!important, webkit moz o ms);

                            &::-webkit-input-placeholder {
                                font-size:24px; color:$col-grey; font-family:$font_caveat; letter-spacing:-1.5px; font-weight:normal;
                            }
                            &::-moz-placeholder {
                                font-size:24px; color:$col-grey; font-family:$font_caveat; letter-spacing:-1.5px; font-weight:normal;
                            }
                            &:-ms-input-placeholder {
                                font-size:24px; color:$col-grey; font-family:$font_caveat; letter-spacing:-1.5px; font-weight:normal;
                            }
                            &:-moz-placeholder {
                                font-size:24px; color:$col-grey; font-family:$font_caveat; letter-spacing:-1.5px; font-weight:normal;
                            }

                            @include media-breakpoint-up(lg) {
                                padding:9px 48px 9px 20px;
                                font-size:18px;

                                &::-webkit-input-placeholder {
                                    font-size:24px;
                                }
                                &::-moz-placeholder {
                                    font-size:24px;
                                }
                                &:-ms-input-placeholder {
                                    font-size:24px;
                                }
                                &:-moz-placeholder {
                                    font-size:24px;
                                }
                            }
                            @include media-breakpoint-up(xl-1440) {
                                padding:calc(9px + 4 * ((100vw - 1440px) / 480)) calc(48px + 16 * ((100vw - 1440px) / 480)) calc(9px + 4 * ((100vw - 1440px) / 480)) calc(20px + 15 * ((100vw - 1440px) / 480));
                                font-size:calc(18px + 4 * ((100vw - 1440px) / 480));

                                &::-webkit-input-placeholder {
                                    font-size:calc(24px + 6 * ((100vw - 1440px) / 480));
                                }
                                &::-moz-placeholder {
                                    font-size:calc(24px + 6 * ((100vw - 1440px) / 480));
                                }
                                &:-ms-input-placeholder {
                                    font-size:calc(24px + 6 * ((100vw - 1440px) / 480));
                                }
                                &:-moz-placeholder {
                                    font-size:calc(24px + 6 * ((100vw - 1440px) / 480));
                                }
                            }
                            @include media-breakpoint-up(xxl-1920) {
                                padding:13px 64px 13px 35px;
                                font-size:22px;

                                &::-webkit-input-placeholder {
                                    font-size:30px;
                                }
                                &::-moz-placeholder {
                                    font-size:30px;
                                }
                                &:-ms-input-placeholder {
                                    font-size:30px;
                                }
                                &:-moz-placeholder {
                                    font-size:30px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .menu-layer-btn {
            position:relative;
            display:block; float:left;
            width:40px; height:40px;
            background-color:$col-light-red;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
            @include prefix(border-radius, 50%, webkit moz o ms);

            &:after {
                content:$icon-dea-menu;
                position:absolute; left:50%; top:50%;
                width:14px; height:14px;
                font-size:14px; line-height:14px; color:$col-black; font-family:'icomoon'!important;
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            }

            @include media-breakpoint-up(lg) {
                width:48px; height:48px;

                &:after {
                    width:16px; height:16px;
                    font-size:16px; line-height:16px;
                }

                &:hover {
                    background-color:$col-red;
                }
            }
            @include media-breakpoint-up(xl-1440) {
                width:calc(48px + 16 * ((100vw - 1440px) / 480)); height:calc(48px + 16 * ((100vw - 1440px) / 480));

                &:after {
                    width:calc(16px + 2 * ((100vw - 1440px) / 480)); height:calc(16px + 2 * ((100vw - 1440px) / 480));
                    font-size:calc(16px + 2 * ((100vw - 1440px) / 480)); line-height:calc(16px + 2 * ((100vw - 1440px) / 480));
                }
            }
            @include media-breakpoint-up(xxl-1920) {
                width:64px; height:64px;

                &:after {
                    width:20px; height:20px;
                    font-size:20px; line-height:20px;
                }
            }
        }
    }

    &.open-search {
        .header-logo {
            opacity:0;

            @include media-breakpoint-up(dsk) {
                opacity:1;
            }
        }
        .header-main {
            @include media-breakpoint-up(lg) {
                display:none;
            }
        }
        .header-component {
            .header-search {
                .search-btn {
                    z-index:1;
                    opacity:0;
                }
                form {
                    z-index:2;
                    opacity:1;

                    fieldset {
                        > div {
                            width:calc(100vw - 86px);

                            @include media-breakpoint-up(dsk) {
                                width:calc(390px + 200 * ((100vw - 700px) / 740));
                            }
                            @include media-breakpoint-up(xl-1440) {
                                width:590px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.open-layer {
        .header-component {
            .menu-layer-btn {
                &:after {
                    content:$icon-dea-close;
                    width:12px; height:12px;
                    font-size:12px; line-height:12px;
                }
                @include media-breakpoint-up(lg) {
                    &:after {
                        width:14px; height:14px;
                        font-size:14px; line-height:14px;
                    }
                }
                @include media-breakpoint-up(xl-1440) {
                    &:after {
                        width:calc(14px + 2 * ((100vw - 1440px) / 480)); height:calc(14px + 2 * ((100vw - 1440px) / 480));
                        font-size:calc(14px + 2 * ((100vw - 1440px) / 480)); line-height:calc(14px + 2 * ((100vw - 1440px) / 480));
                    }
                }
                @include media-breakpoint-up(xxl-1920) {
                    &:after {
                        width:16px; height:16px;
                        font-size:16px; line-height:16px;
                    }
                }
            }
        }
        .menu-layer {
            @include prefix(transform, translate(-50%, 0%), webkit moz o ms);
        }
    }

    .menu-layer {
        position:absolute; left:50%; top:100%;
        width:100vw; height:auto;
        padding:0 16px; margin-top:13px;
        background-color:$col-black;
        overflow:auto;
        opacity:0;
        z-index:1;
        @include prefix(transform, translate(-50%, -100%), webkit moz o ms);
        @include prefix(transition, transform .3s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
            padding:0 30px;
        }
        @include media-breakpoint-up(xl-1440) {
            padding:0 calc(80px + 22 * ((100vw - 1440px) / 480));
        }
        @include media-breakpoint-up(xxl-1920) {
            padding:0 102px;
        }

        > div {
            float:left;
            width:100%;
            padding:16px 0 70px 0;

            @include media-breakpoint-up(lg) {
                padding:calc(16px + 22 * ((100vw - 992px) / 448)) 0 calc(70px + 30 * ((100vw - 992px) / 448)) 0;
            }
            @include media-breakpoint-up(xl-1440) {
                padding:38px 0 100px 0;
            }

            > div {
                float:left;
                width:100%;
                margin-top:44px;

                &:first-child {
                    margin-top:0;
                }

                @include media-breakpoint-up(lg) {
                    width:33.3%;
                    margin-top:0;
                }
                @include media-breakpoint-up(xl) {
                    width:30%;

                    &:first-child {
                        width:40%;
                    }
                }

                h2, .h2 {
                    position:relative;
                    color:$col-white;

                    em {
                        font-size:24px;
                    }

                    @include media-breakpoint-up(dsk) {
                        em {
                            font-size:32px;
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        padding-bottom:12px;

                        &:after {
                            content:'';
                            position:absolute; left:0; bottom:0;
                            width:100%; max-width:280px; height:1px;
                            background-color:$col-white20;
                        }
                    }
                    @include media-breakpoint-up(xxl) {
                        font-size:50px;

                        em {
                            font-size:38px;
                        }
                    }
                }

                nav {
                    display:block; float:left;
                    width:100%;

                    &.channel-menu {
                        margin-top:-3px;

                        @include media-breakpoint-up(dsk) {
                            margin-top:12px;
                        }

                        > ul {
                            > li {
                                margin-top:17px;

                                @include media-breakpoint-up(dsk) {
                                    margin-top:22px;
                                }

                                > a {
                                    position:relative;
                                    display:block; float:left;
                                    width:auto;
                                    padding-left:30px;
                                    font-size:20px; line-height:24px; color:$col-light-red; font-weight:900; text-transform:uppercase; text-decoration:none;
                                    @include prefix(transition, all .3s ease-out, webkit moz o ms);

                                    &:hover {
                                        text-decoration:underline!important;
                                    }

                                    @include media-breakpoint-up(dsk) {
                                        font-size:32px; line-height:36px;
                                    }
                                    @include media-breakpoint-up(lg) {
                                        &:hover {
                                            text-decoration:none!important;
                                            color:$col-red;
                                        }
                                    }
                                    @include media-breakpoint-up(xxl) {
                                        padding-left:45px;
                                        font-size:40px; line-height:44px;
                                    }
                                }

                                &.sub {
                                    display:flex; flex-direction:column; align-items:flex-start;

                                    > a {
                                        &:before {
                                            content:$icon-dea-arrow-down;
                                            position:absolute; left:0; top:7px;
                                            width:10px; height:10px;
                                            font-size:10px; line-height:10px; color:$col-light-red; font-family:'icomoon'!important;
                                            @include prefix(transition, all .3s ease-out, webkit moz o ms);

                                            @include media-breakpoint-up(dsk) {
                                                top:12px;
                                                width:12px; height:12px;
                                                font-size:12px; line-height:12px12px;
                                            }
                                            @include media-breakpoint-up(xxl) {
                                                top:15px;
                                                width:14px; height:14px;
                                                font-size:14px; line-height:14px;
                                            }
                                        }

                                        @include media-breakpoint-up(lg) {
                                            &:hover {
                                                &:before {
                                                    color:$col-red;
                                                }
                                            }
                                        }
                                    }
                                    > div {
                                        display:none; float:left;
                                        width:100%; max-width:600px;
                                        padding-left:30px; margin-top:20px; margin-bottom:15px;
                                        text-align:center; font-size:0;

                                        @include media-breakpoint-up(xl) {
                                            margin-bottom:28px;
                                        }
                                        @include media-breakpoint-up(xxl) {
                                            padding-left:45px; margin-top:22px;
                                        }

                                        > a {
                                            position:relative;
                                            display:inline-block; float:none;
                                            padding-bottom:6px;
                                            font-size:13px; line-height:15px; color:$col-white; font-weight:800; text-transform:uppercase; letter-spacing:0.52px;
                                            @include prefix(transition, all .3s ease-out, webkit moz o ms);

                                            &:before {
                                                content:'';
                                                position:absolute; left:0; bottom:0;
                                                width:100%; height:1px;
                                                background-color:$col-light-red;
                                            }

                                            @include media-breakpoint-up(dsk) {
                                                padding-bottom:6px;
                                                font-size:15px; line-height:20px; letter-spacing:0.3px;

                                                &:before {
                                                    content:'';
                                                    position:absolute; left:0; bottom:0;
                                                    width:100%; height:1px;
                                                    background-color:$col-light-red;
                                                }
                                            }
                                            @include media-breakpoint-up(lg) {
                                                float:left;

                                                &:hover {
                                                    color:$col-red;

                                                    &:before {
                                                        background-color:$col-red;
                                                    }
                                                }
                                            }
                                        }

                                        ul {
                                            margin-top:5px;
                                            column-count:1; column-gap:30px;

                                            @include media-breakpoint-up(dsk) {
                                                margin-top:10px;
                                                column-count:2;
                                            }
                                            @include media-breakpoint-up(lg) {
                                                column-count:1;
                                            }
                                            @include media-breakpoint-up(xl) {
                                                column-count:2;
                                            }

                                            li {
                                                margin-top:24px;
                                                -webkit-column-break-inside:avoid; page-break-inside:avoid; break-inside:avoid;

                                                @include media-breakpoint-up(lg) {
                                                    margin-top:20px;
                                                }

                                                a {
                                                    position:relative;
                                                    display:block; float:left;
                                                    width:auto;
                                                    font-size:15px; line-height:18px; color:$col-light-green; font-weight:800; letter-spacing:0.6px; text-align:left;
                                                    @include prefix(transition, all .3s ease-out, webkit moz o ms);

                                                    &:before {
                                                        content:none;
                                                        position:absolute; left:0; bottom:0;
                                                        width:100%; height:1px;
                                                        background-color:$col-green;
                                                        @include prefix(transition, all .3s ease-out, webkit moz o ms);
                                                    }

                                                    @include media-breakpoint-up(lg) {
                                                        &:hover {
                                                            color:$col-white;

                                                            &:before {
                                                                background-color:$col-white;
                                                            }
                                                        }
                                                    }
                                                    @include media-breakpoint-up(xl) {
                                                        padding-bottom:4px;

                                                        &:before {
                                                            content:'';
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &.open {
                                        > a {
                                            &:before {
                                                @include prefix(transform, rotate(-180deg), webkit moz o ms);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.column-menu {
                        margin-top:0;

                        @include media-breakpoint-up(dsk) {
                            margin-top:4px;
                        }
                        @include media-breakpoint-up(lg) {
                            margin-top:18px;
                        }

                        ul {
                            li {
                                width:auto;
                                margin-top:14px; margin-right:40px;

                                &:last-child {
                                    margin-right:0;
                                }

                                @include media-breakpoint-up(lg) {
                                    width:100%;
                                    margin-top:22px; margin-right:0;
                                }

                                a {
                                    position:relative;
                                    display:block; float:left;
                                    width:auto;
                                    padding-bottom:6px;
                                    font-size:15px; line-height:18px; color:$col-white; font-weight:900; letter-spacing:0.6px;
                                    @include prefix(transition, all .3s ease-out, webkit moz o ms);

                                    &:after {
                                        content:'';
                                        position:absolute; left:0; bottom:0;
                                        width:100%; height:1px;
                                        background-color:$col-light-red;
                                    }
                                    &:hover {
                                        text-decoration:underline!important;
                                    }
                                    @include media-breakpoint-up(lg) {
                                        &:hover {
                                            text-decoration:none!important;
                                            color:$col-grey;
                                        }
                                    }
                                    @include media-breakpoint-up(xxl) {
                                        font-size:20px; line-height:26px;
                                    }
                                }
                            }
                        }
                    }
                    &.about-menu {
                        margin-top:0;

                        @include media-breakpoint-up(dsk) {
                            margin-top:15px;
                        }

                        ul {
                            li {
                                margin-top:18px;

                                @include media-breakpoint-up(dsk) {
                                    margin-top:25px;
                                }

                                a {
                                    display:block; float:left;
                                    width:auto;
                                    font-size:15px; line-height:18px; color:$col-white; font-weight:600; text-transform:uppercase;
                                    @include prefix(transition, all .3s ease-out, webkit moz o ms);

                                    &:hover {
                                        text-decoration:underline!important;
                                    }
                                    @include media-breakpoint-up(lg) {
                                        &:hover {
                                            text-decoration:none!important;
                                            color:$col-grey;
                                        }
                                    }
                                    @include media-breakpoint-up(xxl) {
                                        font-size:18px; line-height:26px;
                                    }
                                }
                            }
                        }
                    }
                }

                .social-list {
                    margin-top:44px;

                    @include media-breakpoint-up(lg) {
                        margin-top:82px;
                    }

                    li {
                        display:block; float:left;
                        width:auto;
                        margin-left:0; margin-right:22px;

                        @include media-breakpoint-up(sm-375) {
                            margin-right:32px;
                        }
                        @include media-breakpoint-up(lg) {
                            margin-right:calc(28px + 4 * ((100vw - 992px) / 448));
                        }
                        @include media-breakpoint-up(xl-1440) {
                            margin-right:32px;
                        }

                        a {
                            width:46px; height:46px;
                            @include prefix(box-shadow, none, webkit moz o ms);

                            i {
                                width:16px; height:16px;
                                font-size:16px; line-height:16px;
                                @include prefix(text-shadow, none, webkit moz o ms);
                            }
                            &:before {
                                @include prefix(box-shadow, none, webkit moz o ms);
                            }

                            @include media-breakpoint-up(xxl) {
                                width:65px; height:65px;

                                i {
                                    width:26px; height:26px;
                                    font-size:26px; line-height:26px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*body.header-scroll {
    .header {
        position:fixed;
    }
    .main-content {
        padding-top:142px;

        @include media-breakpoint-up(lg) {
            padding-top:142px;
        }
        @include media-breakpoint-up(xxl) {
            padding-top:142px;
        }
    }
}*/
