.expert-wrapper {
    .expert {
        display:block; float:left;
        width:100%;
        margin-top:52px;
        
        @include media-breakpoint-up(dsk) {
            margin-top:72px;
        }
        
        .expert-content {
            display:block; float:left;
            width:100%;
            text-align:center; text-decoration:none!important;
            
            @include media-breakpoint-up(xl) {
                padding:0 34px;
            }
            
            > span {
                display:inline-block; float:none;
                width:200px; height:200px;
                background-position:center center; background-repeat:no-repeat; background-size:cover;
                overflow:hidden;
                @include prefix(filter, grayscale(0), webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
                @include prefix(border-radius, 50%, webkit moz o ms);
                
                @include media-breakpoint-up(dsk) {
                    width:180px; height:180px;
                }
                @include media-breakpoint-up(lg) {
                    width:220px; height:220px;
                }
            }
            > .expert-head {
                float:left;
                width:100%;
                margin-top:22px;
                
                @include media-breakpoint-up(dsk) {
                    margin-top:25px;
                }
                
                h3, .h3 {
                    line-height:26px; color:$col-red;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    
                    @include media-breakpoint-up(dsk) {
                        line-height:28px;
                    }
                }
                p {
                    font-size:20px; line-height:26px; font-family:$font_caveat; color:$col-black; font-weight:bold;
                    
                    @include media-breakpoint-up(dsk) {
                        font-size:22px; line-height:28px;
                    }
                }
            }
            > p {
                margin-top:16px;
                line-height:20px; color:$col-black;
    
                @include media-breakpoint-up(dsk) {
                    margin-top:20px;
                    line-height:22px;
                }
            }
        }
        
        .expert-article {
            position:relative;
            float:left;
            width:100%;
            margin-top:18px; padding:22px 0 0 0;
            text-align:center;
            
            &:before {
                content:''; position:absolute; left:50%; top:0;
                width:50px; height:2px;
                background-color:$col-light-red;
                @include prefix(transform, translateX(-50%), webkit moz o ms);
            }
            
            @include media-breakpoint-up(dsk) {
                margin-top:22px; padding:26px 0 0 0;
                
                &:before {
                    height:3px;
                }
            }            
            @include media-breakpoint-up(xl) {
                padding:26px 24px 0 24px;
            }
            
            > p {
                font-size:20px; line-height:26px; font-family:$font_caveat; color:$col-black; font-weight:bold;

                @include media-breakpoint-up(dsk) {
                    font-size:22px; line-height:28px;
                }
            }
            > ul {
                margin-top:6px;
                
                li {
                    position:relative;
                    padding-top:14px; margin-top:12px;
            
                    &:before {
                        content:''; position:absolute; left:50%; top:0;
                        width:100%; max-width:198px; height:1px;
                        background-color:$col-grey20;
                        @include prefix(transform, translateX(-50%), webkit moz o ms);
                    }
                    
                    &:first-child {
                        margin-top:0; padding-top:0;
                        
                        &:before {
                            content:none;
                        }
                    }
                    
                    @include media-breakpoint-up(dsk) {
                        padding-top:16px; margin-top:14px;
                    }
                    
                    a {
                        display:block; float:left;
                        width:100%;
                        font-size:14px; line-height:20px; color:$col-dark-grey; font-weight:800; text-decoration:none!important;
                        @include prefix(transition, all .3s ease-out, webkit moz o ms);

                        @include media-breakpoint-up(dsk) {
                            font-size:16px; line-height:22px;
                        }
                        
                        @include media-breakpoint-up(lg) {
                            &:hover {
                                color:$col-grey;
                            }
                        }
                    }
                }
            }
        }
        
        @include media-breakpoint-up(lg) {
            &:hover {
                .expert-content {
                    span {
                        @include prefix(filter, grayscale(100%), webkit moz o ms);
                    }
                    .expert-head {
                        h3, .h3 {
                            color:$col-light-red;
                        }
                    }
                }
            }
        }
    }
}

.header-expert {
    margin-top:30px;
    
    @include media-breakpoint-up(dsk) {
        margin-top:36px;
    }
    
    .expert-cover {
        float:left;
        width:100%;
        margin-bottom:20px;
        text-align:center;
        
        @include media-breakpoint-up(dsk) {
            padding-top:10px; margin-bottom:0;
        }
        
        > span {
            display:inline-block; float:none;
            width:200px; height:200px;
            background-position:center center; background-repeat:no-repeat; background-size:cover;
            overflow:hidden;
            @include prefix(border-radius, 50%, webkit moz o ms);

            @include media-breakpoint-up(dsk) {
                display:block; float:right;
                width:100%; height:0;
                padding-bottom:100%;
            }
            @include media-breakpoint-up(lg) {
                width:280px; height:280px;
                padding-bottom:0;
            }
            @include media-breakpoint-up(xl) {
                width:320px; height:320px;
                margin-right:34px;
            }
        }
    }
    .expert-detail {
        float:left;
        width:100%;
        text-align:center;
        
        @include media-breakpoint-up(dsk) {
            text-align:left;
        }
        @include media-breakpoint-up(xl) {
            padding-right:14%;
        }
        
        h2, .h2 {
            position:relative;
            padding-bottom:22px;
            font-size:30px; line-height:40px; color:$col-dark-grey; text-transform:inherit;
            text-align:center;

            &:before {
                content:'';
                position:absolute; left:50%; bottom:0;
                width:34px; height:2px;
                background-color:$col-light-red;
                @include prefix(transform, translateX(-50%), webkit moz o ms);
            }

            @include media-breakpoint-up(dsk) {
                padding-bottom:42px;
                font-size:40px; line-height:50px;
                text-align:left;

                &:before {
                    left:0;
                    width:50px; height:3px;
                    @include prefix(transform, translateX(0), webkit moz o ms);
                }
            }
        }
        .social-list {
            margin-top:34px;

            li {
                display:inline-block; float:none;
                margin:0 16px;

                &:first-child {
                    margin-left:0;
                }
                &:last-child {
                    margin-right:0;
                }

                a {
                    width:42px; height:42px;
                    @include prefix(box-shadow, 0px 3px 6px 0px rgba(0,0,0,0.1), webkit moz o ms);

                    i {
                        width:18px; height:18px;
                        font-size:18px; line-height:18px;
                        @include prefix(text-shadow, 0px 0px 6px rgba(0,0,0,0.1), webkit moz o ms);
                    }
                    &:before {
                        @include prefix(box-shadow, inset 0px 3px 6px 0px rgba(0,0,0,0.1), webkit moz o ms);
                    }

                    @include media-breakpoint-up(dsk) {
                        width:52px; height:52px;

                        i {
                            width:24px; height:24px;
                            font-size:24px; line-height:24px;
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        &:hover {            
                            i {
                                @include prefix(text-shadow, none, webkit moz o ms);
                            }
                            &:before {
                                @include prefix(box-shadow, none, webkit moz o ms);
                            }
                        }
                    }
                }
            }

            @include media-breakpoint-up(dsk) {
                margin-top:48px;

                li {
                    display:block; float:left;
                    margin:0 20px;
                }
            }
        }
    }
    
    &.no-text {
        justify-content:center;
        
        .expert-cover {
            text-align:center;
            
            > span {
                display:inline-block; float:none;
                margin-right:0;
            }
        }
        .col-dsk-8 {
            display:none!important;
        }
    }
}