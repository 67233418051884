.header-love {
    margin-top:35px;
    
    @include media-breakpoint-up(dsk) {
        margin-top:65px;
    }
    
    .love-icon {
        float:left;
        width:100%;
        margin-bottom:24px;
        text-align:center;
        
        @include media-breakpoint-up(dsk) {
            margin-bottom:0;
        }
        
        img {
            display:inline-block; float:none;
            height:142px; width:auto;
            
            @include media-breakpoint-up(dsk) {
                height:232px;
            }
        }
    }
    .love-title {
        float:left;
        width:100%;
        
        h2, .h2 {
            position:relative;
            padding-bottom:22px;
            font-size:30px; line-height:40px; color:$col-white; text-transform:inherit;
            text-align:center;

            &:before {
                content:'';
                position:absolute; left:50%; bottom:0;
                width:34px; height:2px;
                background-color:$col-light-red;
                @include prefix(transform, translateX(-50%), webkit moz o ms);
            }

            @include media-breakpoint-up(dsk) {
                padding-bottom:42px;
                font-size:40px; line-height:50px;
                text-align:left;

                &:before {
                    left:0;
                    width:50px; height:3px;
                    @include prefix(transform, translateX(0), webkit moz o ms);
                }
            }
        }
        
        &.quote {
            position:relative;
            padding:0 40px;
            
            @include media-breakpoint-up(dsk) {
                padding:0 60px;
            }
            
            &:before, &:after {
                content:'';
                position:absolute;
                width:20px; height:20px;
                background-position:center center; background-repeat:no-repeat; background-size:contain;

                @include media-breakpoint-up(dsk) {
                    width:28px; height:28px;
                }
            }
            &:before {
                left:0; top:-12px;
                background-image:url('../img/quote-red-left.svg');
            }
            &:after {
                right:0; bottom:25px;
                background-image:url('../img/quote-red-right.svg');
            }
        }
    }
}

.quote-list {
    margin-top:-20px;
    
    @include media-breakpoint-up(dsk) {
        margin-top:-30px;
    }
    
    article {
        display:block; float:left;
        width:100%;
        margin-top:20px;
        background-color:$col-band-red;
        overflow:hidden;
        @include prefix(border-radius, 8px, webkit moz o ms);
        
        @include media-breakpoint-up(dsk) {
            margin-top:30px;
        }
        .cover {
            position:relative;
            display:block; float:left;
            width:100%;
            overflow:hidden;
            @include prefix(border-radius, 8px, webkit moz o ms);
            
            @include media-breakpoint-up(dsk) {
                width:282px;
            }
            
            &:before {
                content:'';
                position:absolute; left:0; top:0;
                width:100%; height:100%;
                background-color:$col-black25;
                z-index:2;
            }
            div {
                position:relative;
                float:left;
                width:100%; padding-bottom:56.25%;
                background-position:center top; background-repeat:no-repeat; background-size:cover;
                z-index:1;
                @include prefix(filter, grayscale(0), webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
                
                @include media-breakpoint-up(dsk) {
                    padding-bottom:88%;
                }
            }
        }
        .content {
            position:relative;
            display:block; float:left;
            width:100%;
            padding:20px 16px;
            
            @include media-breakpoint-up(dsk) {
                width:calc(100% - 282px);
                padding:28px 26px 28px 26px;
            }
            @include media-breakpoint-up(lg) {
                padding:28px 72px 28px 46px;
            }
            
            h3, .h3 {
                position:relative;
                margin-top:0; padding:12px 40px;
                font-size:20px; line-height:28px; color:$col-black; font-weight:800;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
                
                @include media-breakpoint-up(dsk) {
                    padding:16px 40px;
                    font-size:24px; line-height:32px;
                }
                @include media-breakpoint-up(lg) {
                    padding:16px 60px;
                }
                
                &:before, &:after {
                    content:'';
                    position:absolute;
                    width:20px; height:20px;
                    background-position:center center; background-repeat:no-repeat; background-size:contain;

                    @include media-breakpoint-up(dsk) {
                        width:28px; height:28px;
                    }
                }
                &:before {
                    left:0; top:0;
                    background-image:url('../img/quote-red-left.svg');
                }
                &:after {
                    right:0; bottom:0;
                    background-image:url('../img/quote-red-right.svg');
                }
            }
        }
        
        a {
            display:flex; float:left;
            width:100%;
            flex-direction:column; align-items:center;
            
            @include media-breakpoint-up(dsk) {
                flex-direction:row;
                
            }
            @include media-breakpoint-up(lg) {
                &:hover {
                    .cover {
                        div {
                            @include prefix(filter, grayscale(100%), webkit moz o ms);
                        }
                    }
                    .content {
                        h3, .h3 {
                            color:$col-grey;
                        }
                    }
                }
            }
        }
    }
}