.footer {
    float:left;
    width:100%!important;
    margin-top:20px;
    
    @include media-breakpoint-up(dsk) {
        margin-top:30px;
    }
    
    .footer-menu {
        float:left;
        width:100%;
        padding:0 0 20px 0;
        background-color:$col-black;
        
        @include media-breakpoint-up(dsk) {
            padding:32px 0 15px 0;
        }
        
        .footer-nav {
            position:relative;
            display:block; float:left;
            width:100%;
            padding-bottom:32px;
            text-align:center; font-size:0;
            
            &:before {
                content:'';
                position:absolute; left:50%; bottom:0;
                width:100%; height:1px;
                background-color:$col-white20;
                @include prefix(transform, translateX(-50%), webkit moz o ms);
            }
            
            @include media-breakpoint-up(dsk) {
                padding-bottom:0;
                
                &:before {
                    content:none;
                }
            }
            
            ul {
                display:inline-block; float:none;
                width:auto;
                
                li {
                    width:33.3%;
                    padding:0 20px; margin-top:28px;
                    
                    @include media-breakpoint-up(dsk) {
                        width:auto;
                        margin-top:0;
                    }
                    
                    a {
                        display:block; float:left;
                        width:100%;
                        font-size:15px; line-height:18px; color:$col-white; font-weight:600; text-align:center;
                        @include prefix(transition, all .3s ease-out, webkit moz o ms);
                        
                        @include media-breakpoint-up(dsk) {
                            width:auto;
                        }
                        @include media-breakpoint-up(lg) {
                            font-size:17px; line-height:20px;
                            
                            &:hover {
                                color:$col-light-red;
                            }
                        }
                    }
                }
            }
        }
        .footer-utility {
            display:block; float:left;
            width:100%;
            margin-top:22px;
            text-align:center; font-size:0;
            
            @include media-breakpoint-up(dsk) {
                margin-top:45px;
            }
            
            li {
                display:inline-block; float:none;
                width:auto;
                margin:0 16px;
                    
                a {
                    display:block; float:left;
                    width:auto;
                    font-size:12px; line-height:14px; color:$col-white; text-transform:uppercase; font-weight:900;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    
                    @include media-breakpoint-up(lg) {
                        &:hover {
                            color:$col-light-red;
                        }
                    }
                }
            }
        }
    }
    
    .footer-bottom {
        float:left;
        width:100%;
    }
    
    .footer-copyright {
        float:left;
        width:100%;
        padding:10px 0 14px 0;
        
        @include media-breakpoint-up(dsk) {
            padding:14px 0 28px 0;
        }
        
        p {
            text-align:center; font-size:12px; line-height:19px; color:$col-medium-grey; font-weight:600;
            
            @include media-breakpoint-up(dsk) {
                font-size:11px; line-height:18px;
            }
        }
    }
}

.de-agostini-footer {
    text-align:center;
    
    .groupfooter {
        display:inline-block; float:none;
        width:auto;
        padding:0 25px;
        font-size:65%;
        
        ul {
            float:left;
            width:50%;
            padding:0; margin:0 auto 20px auto;
            list-style-type:none;
            
            &:first-child {
                width:100%; height:20px;
                padding:0; margin:20px auto 20px auto; padding:0;
                text-align:center;
                
                li {
                    padding-right:20px;
                    text-align:center;
                    
                    a {
                        display:inline-block; float:none;
                    }                    
                }
            }
            &:last-child {
                padding-right:0;
                border-right:none;
            }
            li {
                float:none;
                width:auto;
                text-align:left;
                
                a {
                    color:#5C6670; font-size:10px;
                }
                img {
                    width:110px; height:14px;
                }
            }
        }
        
        @include media-breakpoint-up(dsk) {
            height:110px;
            padding:10px 25px;
            font-size:60%;
            
            ul {
                width:auto; height:70px;
                padding:0 36px 0 10px; margin:20px auto;
                border-left:1px solid #B9C8D3;
            
                &:first-child {
                    width:auto; height:0; margin:0 none; padding:0; text-align:left; width:auto;
                    
                    li {
                        padding-right:0;
                        text-align:left;
                        
                        a {
                            padding-right:20px;
                        }
                    }
                }
            }
        }
    }
}