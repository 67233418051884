$font-path: "../fonts" !default;

$icon-dea-special: "\e93e";
$icon-dea-world: "\e93d";
$icon-dea-check-quiz: "\e93c";
$icon-dea-apple: "\e92c";
$icon-dea-actress: "\e92d";
$icon-dea-valentine: "\e92e";
$icon-dea-unlike: "\e92f";
$icon-dea-television: "\e930";
$icon-dea-like: "\e931";
$icon-dea-horoscope: "\e932";
$icon-dea-heart: "\e933";
$icon-dea-gift: "\e934";
$icon-dea-friends: "\e935";
$icon-dea-dress: "\e936";
$icon-dea-dollar: "\e937";
$icon-dea-cupid: "\e938";
$icon-dea-chilli: "\e939";
$icon-dea-calendar: "\e93a";
$icon-dea-book: "\e93b";
$icon-dea-firing: "\e924";
$icon-dea-time-cooking: "\e925";
$icon-dea-category: "\e926";
$icon-dea-time-preparation: "\e927";
$icon-dea-time-total: "\e928";
$icon-dea-portions: "\e929";
$icon-dea-difficulty: "\e92a";
$icon-dea-cooking: "\e92b";
$icon-dea-megaphone: "\e906";
$icon-dea-microphone: "\e922";
$icon-dea-guide: "\e923";
$icon-dea-close: "\e921";
$icon-dea-quiz: "\e920";
$icon-dea-arrow-down: "\e900";
$icon-dea-arrow-left: "\e901";
$icon-dea-arrow-right: "\e902";
$icon-dea-arrow-up: "\e903";
$icon-dea-chat: "\e904";
$icon-dea-chef: "\e905";
$icon-dea-line-down: "\e907";
$icon-dea-line-left: "\e908";
$icon-dea-line-right: "\e909";
$icon-dea-line-up: "\e90a";
$icon-dea-menu: "\e90b";
$icon-dea-photo: "\e90c";
$icon-dea-play: "\e90d";
$icon-dea-rank: "\e90e";
$icon-dea-search: "\e90f";
$icon-dea-social-facebook: "\e910";
$icon-dea-social-instagram: "\e911";
$icon-dea-social-pinterest: "\e912";
$icon-dea-social-twitter: "\e913";
$icon-dea-zodiac-aquarius: "\e914";
$icon-dea-zodiac-aries: "\e915";
$icon-dea-zodiac-cancer: "\e916";
$icon-dea-zodiac-capricorn: "\e917";
$icon-dea-zodiac-gemini: "\e918";
$icon-dea-zodiac-leo: "\e919";
$icon-dea-zodiac-libra: "\e91a";
$icon-dea-zodiac-pisces: "\e91b";
$icon-dea-zodiac-sagittarius: "\e91c";
$icon-dea-zodiac-scorpio: "\e91d";
$icon-dea-zodiac-taurus: "\e91e";
$icon-dea-zodiac-virgo: "\e91f";