body {
    text-align:center; font-size:0;
    background-color:$col-white;

    &.mnzSkin {
        @include media-breakpoint-up(xl-1540) {
            background-size:2370px!important;
        }
        .content-wrapper {
            /*@include media-breakpoint-up(sm-414) {
                max-width:414px;
            }
            @include media-breakpoint-up(dsk) {
                max-width:1110px;
            }*/
            @include media-breakpoint-up(lg) {
                max-width:1000px;
                background-color:transparent;
            }
            @include media-breakpoint-up(xl-1540) {
                max-width:1260px;
            }

            > span {
                position:absolute; left:0; bottom:0;
                display:block;
                width:100%; height:calc(100% - 268px);
                background-color:$col-white;
            }
        }
        .hero-home {
            padding:0!important;
        }

        #adv-Top {
            min-height:0!important;
        }

        .section-title {
            > div:not(.row) {
                h1, .h1 {
                    padding:0 52px;
                }
            }
        }

        .header-image, .header-video {
            > div:not(.cover) {
                > div:not(.sponsor) {
                    @include media-breakpoint-up(xl-1440) {
                        padding:20px 0 0 0;
                    }
                    @include media-breakpoint-up(xxl-1920) {
                        padding:20px 0 0 0;
                    }
                }
            }
        }
        .header-video {
            flex-direction:column;

            > .cover {
                width:100%;
                padding-bottom:56.25%;
            }
            > div:not(.cover) {
                width:100%;
            }
        }
        .header-special {
            flex-direction:column;

            > .cover {
                width:100%;
                padding-bottom:56.25%;
            }
            > div:not(.cover) {
                width:100%;

                @include media-breakpoint-up(lg) {
                    padding:30px 30px 0 30px;
                }
                @include media-breakpoint-up(xl) {
                    padding:50px 50px 0 50px;
                }
            }
        }

        .series-list {
            &.small {
                .series {
                    > .cover {
                        @include media-breakpoint-up(dsk) {
                            width:40%;
                        }
                    }
                    > .content {
                        @include media-breakpoint-up(dsk) {
                            width:60%;
                            padding:28px 20px;
                        }
                    }
                }
            }
        }

        .article-content, .article-tag, .article-note {
            padding-left:0!important;
        }

        .horoscope-head {
            .horoscope-zodiac {
                ul {
                    li {
                        a {
                            @include media-breakpoint-up(xl) {
                                width:84px;

                                p {
                                    font-size:27px; line-height:35px;
                                }
                            }
                        }
                    }
                }
            }
        }

        /*.main-content {
            padding-top:104px;

            @include media-breakpoint-up(lg) {
                padding-top:136px;
            }
            @include media-breakpoint-up(xl) {
                padding-top:78px;
            }
            @include media-breakpoint-up(xl-1440) {
                padding-top:calc(78px + 34 * ((100vw - 1440px) / 480));
            }
            @include media-breakpoint-up(xxl-1920) {
                padding-top:112px;
            }
        }*/
    }

    > span {
        > img {
            width:0!important; height:0!important;
        }
    }
}

.content-wrapper {
    position:relative;
    display:inline-block; float:none;
    width:100%;
    margin:0 auto;
    text-align:left;
    background-color:$col-white;
    overflow:hidden;
    z-index:1;

    &.skin {
        @include media-breakpoint-up(sm-414) {
            max-width:414px;
        }
        @include media-breakpoint-up(dsk) {
            max-width:1110px;
        }
    }
    > span {
        display:none;
    }
    .main-content, .footer {
        position:relative;
        z-index:2;
    }

    &.scrollHead {
        .header {
            position:fixed;
        }
    }
    &.up-scroll {
        .header {
            top:0!important;
            @include prefix(transition, all .4s ease-out, webkit moz o ms);
        }
    }
}

.container {
    max-width:100%;
    padding-right:18px; padding-left:18px;

    @include media-breakpoint-up(sm-414) {
        max-width:414px;
    }
    @include media-breakpoint-up(dsk) {
        max-width:1110px;
    }

    .row {
        margin-right:-18px; margin-left:-18px;

        > div {
            padding-right:18px; padding-left:18px;
        }
    }

    &.small {
        //padding-right:14px; padding-left:14px;

        .row {
            margin-right:-14px; margin-left:-14px;

            > div {
                padding-right:14px; padding-left:14px;
            }
        }
    }
    &.wide {
        @include media-breakpoint-up(dsk) {
            max-width:1318px;
        }
    }
}

section {
    float:left;
    width:100%;
    margin-top:20px;

    @include media-breakpoint-up(dsk) {
        margin-top:30px;
    }

    &[class^='bkg-'], &[class*=' bkg-']{
        padding-top:18px; padding-bottom:44px;

        @include media-breakpoint-up(dsk) {
            padding-top:20px; padding-bottom:70px;
        }

        &.p-small {
            padding-bottom:30px;

            @include media-breakpoint-up(dsk) {
                padding-bottom:30px;
            }
        }

        &.bottom-band-small, &.bottom-band-big, &.bottom-band-medium, &.bottom-band-special, &.bottom-band-special-top, &.bottom-band-half {
            position:relative;
            padding-bottom:0;

            .container {
                position:relative;
                z-index:2;
            }
            &:after {
                content:'';
                position:absolute; left:0; bottom:0;
                width:100%;
                background-color:$col-white;
            }
        }
        &.bottom-band-small {
            &:after {
                height:calc(100% - 450px);

                @include media-breakpoint-up(dsk) {
                    height:180px;
                }
            }
        }
        &.bottom-band-big {
            &:after {
                height:calc(100% - 150px);

                @include media-breakpoint-up(dsk) {
                    height:72.5%;
                }
            }
        }
        &.bottom-band-medium {
            &:after {
                height:calc(100% - 85px);

                @include media-breakpoint-up(dsk) {
                    height:72.5%;
                }
            }
        }
        &.bottom-band-half {
            &:after {
                height:calc(100% - 85px);

                @include media-breakpoint-up(dsk) {
                    height:50%;
                }
            }
        }
        &.bottom-band-special {
            padding-top:20px;

            @include media-breakpoint-up(dsk) {
                padding-top:25px;
            }

            &:after {
                height:calc(100% - 65px);

                @include media-breakpoint-up(dsk) {
                    height:31.5%;
                }
            }
        }
        &.bottom-band-special-top {
            padding-top:20px;

            @include media-breakpoint-up(dsk) {
                padding-top:25px;
            }

            &:after {
                height:calc(100% - 65px);

                @include media-breakpoint-up(dsk) {
                    height:calc(100% - (258px + 210 * ((100vw - 700px) / 900)));
                }
                @include media-breakpoint-up(xl) {
                    height:calc(100% - 468px);
                }
            }
        }
    }

    &.section-highlight {
        padding-top:20px; padding-bottom:30px;

        @include media-breakpoint-up(dsk) {
            padding-top:25px; padding-bottom:40px;
        }
    }

    &.newsletter-section {
        position:relative;
        padding-top:0; padding-bottom:0;
        background-position:-1250px center; background-repeat:no-repeat; background-size:cover;

        @include media-breakpoint-up(sm-320) {
            background-position:calc(-1250px + 250 * ((100vw - 320px) / 380)) center;
        }
        @include media-breakpoint-up(dsk) {
            background-position: center center;
        }

        .container {
            position:relative;
            z-index:2;
        }
        &:before {
            content:'';
            position:absolute; left:0; top:0;
            width:50%; height:100%;
            z-index:1;
        }
    }

    &.p-more {
        padding-top:20px; padding-bottom:20px;

        @include media-breakpoint-up(dsk) {
            padding-top:26px; padding-bottom:36px;
        }
    }
    &.mt-more {
        margin-top:30px!important;

        @include media-breakpoint-up(dsk) {
            margin-top:45px!important;
        }
    }
}

.de-agostini-footer {
    width:100%;
    margin:0 auto;

    @include media-breakpoint-up(sm-414) {
        width:414px;
    }
    @include media-breakpoint-up(dsk) {
        width:1006px;
        margin:0 auto;
    }
}
.main-content, section, aside {
    position:relative;
    display:block; float:left;
    width:100%;
}
.main-content {
    padding-top:124px;

    @include media-breakpoint-up(md) {
        margin-top:0!important;
    }
    @include media-breakpoint-up(lg) {
        padding-top:166px;
    }
    @include media-breakpoint-up(xl) {
        padding-top:108px;
    }
    @include media-breakpoint-up(xl-1440) {
        padding-top:calc(108px + 34 * ((100vw - 1440px) / 480));
    }
    @include media-breakpoint-up(xxl-1920) {
        padding-top:142px;
    }
}

ul, ol {
    display:block; float:left;
    width:100%;
    padding:0; margin:0;
    list-style:none;

    li {
        display:block; float:left;
        width:100%;
    }
}
img {
    width:100%; height:auto;
}
* {
    outline:0!important; outline:none!important;
}
.btn:focus, .btn.focus {
    outline:0!important;
    -webkit-box-shadow:none!important; -moz-box-shadow:none!important; box-shadow:none!important;
}

.shadow {
    @include prefix(box-shadow, 0px 7px 14px 0px rgba(0,0,0,0.2), webkit moz o ms);
}

/* Banner */

.adv-wrapper {
    float:left;
    width:100%;
    margin-top:20px;

    @include media-breakpoint-up(dsk) {
        margin-top:30px;
    }

    &.mt-more {
        margin-top:20px!important; margin-bottom:-10px;

        @include media-breakpoint-up(dsk) {
            margin-top:45px!important; margin-bottom:0;
        }
    }
}

div[id^="adv-"] {
    &.m-adv {
        margin-top:20px; margin-bottom:20px;

        @include media-breakpoint-up(lg) {
            margin-top:0; margin-bottom:0;
        }

        &.mt-10 {
            @include media-breakpoint-up(lg) {
                margin-top:10px;
            }
        }
    }
}

#adv-Top {
    display:flex; justify-content:center; align-items:center;
    min-height:100px;

    @include media-breakpoint-up(dsk) {
        min-height:250px;
    }
}

.side-adv {
    display:block;
    text-align:center;

    @media only screen and (max-width:991.98px) {
        position:static!important;
    }

    div[id^="adv-"] {
        display:inline-block; float:none;

        > * {
            margin-bottom:40px!important;
        }

        &:last-child {
            > * {
                margin-bottom:0!important;
            }
        }

        @include media-breakpoint-up(lg) {
            float:right;
        }
    }

    /* Test */

    > div {
        width:300px;
    }
}

.skin-active {
    .content-wrapper {
        max-width:1110px;

        .hero-home {
            padding:0;
        }
    }
}
