.section-highlight {
    position:relative;

    .header-image, .header-video {
        margin-top:32px;

        @include media-breakpoint-up(dsk) {
            margin-top:44px;
        }
    }

    .highlight-sponsor {
        float:left;
        width:100%;
        text-align:center;
        margin-top:20px; margin-bottom:30px;

        @include media-breakpoint-up(xl) {
            position:absolute; right:30px; top:38px;
            width:auto;
            margin-top:0; margin-bottom:0;
            z-index:2;
        }

        .sponsor {
            display:inline-flex; float:none;
            align-items:center; flex-direction:row; justify-content:flex-end;

            @include media-breakpoint-up(xl) {
                display:flex;
            }

            p {
                width:auto;
                margin-right:16px;
                font-size:11px; line-height:24px; color:$col-lightest-grey; font-weight:600; text-transform:uppercase;
            }
            a {
                display:block; float:left;
                height:50px;

                @include media-breakpoint-up(dsk) {
                    height:70px;
                }

                img {
                    height:100%; width:auto;
                }
            }
        }
    }
}

.header-image, .header-video {
    display:flex;
    width:100%;
    padding:0!important;
    flex-direction:column;

    @include media-breakpoint-up(lg) {
        flex-direction:row;
    }

    > .cover {
        position:relative;
        display:block; float:left;
        width:100%; height:0;
        padding-bottom:56.25%; margin:0;
        background-position:center center; background-repeat:no-repeat; background-size:cover;

        @include media-breakpoint-up(lg) {
            width:50%;
            padding-bottom:470px;
        }
        @include media-breakpoint-up(xl) {
            width:64%;
        }
        @include media-breakpoint-up(xl-1440) {
            padding-bottom:630px;
        }
        > div {
            position:absolute; left:0; top:0;
            float:left;
            width:100%; height:100%;
            background-position:center top; background-repeat:no-repeat; background-size:cover;
            z-index:1;
        }
        > span {
            position:absolute; left:50%; top:50%;
            width:66px; height:66px;
            background-color:$col-red;
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            @include prefix(border-radius, 50%, webkit moz o ms);
            z-index:3;

            &:before {
                content:'';
                position:absolute; left:50%; top:50%;
                width:96px; height:96px;
                border:4px solid $col-white35;
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                @include prefix(border-radius, 50%, webkit moz o ms);
            }
            i {
                position:absolute; left:50%; top:50%;
                width:24px; height:24px;
                font-size:24px; line-height:24px; color:$col-white;
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            }
        }

        &.layer {
            &:before {
                content:'';
                position:absolute; left:0; top:0;
                width:100%; height:100%;
                background-color:$col-black30;
            }
        }
    }
    > div:not(.cover) {
        display:flex;
        width:100%; height:100%;
        padding:14px 16px 16px 16px;
        flex-direction:column; justify-content:space-between; align-items:flex-end;

        @include media-breakpoint-up(lg) {
            width:50%;
            padding:30px 30px 18px 48px;
        }
        > div:not(.sponsor) {
            position:relative;
            float:left;
            width:100%;
            padding:52px 0 0 0;
            text-align:center;

            @include media-breakpoint-up(lg) {
                padding:68px 0 0 0;
                text-align:left;
            }
            @include media-breakpoint-up(xl-1440) {
                padding:68px calc(0% + 33.3 * ((100vw - 1440px) / 480)) 0 0;
            }
            @include media-breakpoint-up(xxl-1920) {
                padding:68px 33.3% 0 0;
            }

            .icon {
                position:absolute; right:0; top:0;
                width:48px; height:48px;
                background-color:$col-red;
                @include prefix(border-radius, 50%, webkit moz o ms);

                i {
                    position:absolute; left:50%; top:50%;
                    width:26px; height:26px;
                    font-size:26px; line-height:26px; color:$col-white;
                    @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                }

                @include media-breakpoint-up(dsk) {
                    width:46px; height:46px;

                    i {
                        width:24px; height:24px;
                        font-size:24px; line-height:24px;
                    }
                }
            }
            .category {
                position:relative;
                display:inline-block; float:none;
                width:auto;
                padding-bottom:3px;
                font-size:19px; line-height:22px; color:$col-red; font-weight:900;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                &:after {
                    content:'';
                    position:absolute; left:0; bottom:0;
                    width:100%; height:2px;
                    background-color:$col-red;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }

                @include media-breakpoint-up(lg) {
                    display:block; float:left;
                    font-size:22px; line-height:26px;

                    &:hover {
                        color:$col-light-red;

                        &:after {
                            background-color:$col-light-red;
                        }
                    }
                }
            }
            h1, .h1 {
                position:relative;
                margin-top:15px; padding-bottom:22px;
                font-size:34px; line-height:40px; color:$col-black; text-transform:inherit;
                text-align:center;

                &:before {
                    content:'';
                    position:absolute; left:50%; bottom:0;
                    width:34px; height:2px;
                    background-color:$col-light-red;
                    @include prefix(transform, translateX(-50%), webkit moz o ms);
                }

                @include media-breakpoint-up(lg) {
                    padding-bottom:42px;
                    font-size:40px; line-height:50px;
                    text-align:left;

                    &:before {
                        left:0;
                        width:50px; height:3px;
                        @include prefix(transform, translateX(0), webkit moz o ms);
                    }
                }
            }
            a.author, p.author {
                display:block; float:left;
                margin-top:10px;
                font-size:14px; line-height:24px; font-weight:900; color:$col-black;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                em {
                    font-style:normal; color:$col-red;
                }
            }
            a.author {
                width:100%;

                @include media-breakpoint-up(lg) {
                    width:auto;

                    &:hover {
                        color:$col-red!important;
                    }
                }
            }
            .social-list {
                margin-top:30px;

                li {
                    margin:0 16px;

                    &:first-child {
                        margin-left:0;
                    }
                    &:last-child {
                        margin-right:0;
                    }

                    a {
                        width:42px; height:42px;
                        @include prefix(box-shadow, 0px 3px 6px 0px rgba(0,0,0,0.1), webkit moz o ms);

                        i {
                            width:18px; height:18px;
                            font-size:18px; line-height:18px;
                            @include prefix(text-shadow, 0px 0px 6px rgba(0,0,0,0.1), webkit moz o ms);
                        }
                        &:before {
                            @include prefix(box-shadow, inset 0px 3px 6px 0px rgba(0,0,0,0.1), webkit moz o ms);
                        }

                        @include media-breakpoint-up(dsk) {
                            width:52px; height:52px;

                            i {
                                width:24px; height:24px;
                                font-size:24px; line-height:24px;
                            }
                        }
                        @include media-breakpoint-up(lg) {
                            &:hover {
                                i {
                                    @include prefix(text-shadow, none, webkit moz o ms);
                                }
                                &:before {
                                    @include prefix(box-shadow, none, webkit moz o ms);
                                }
                            }
                        }
                    }
                }

                @include media-breakpoint-up(dsk) {
                    margin-top:44px;

                    li {
                        margin:0 20px;
                    }
                }
            }
        }
        .sponsor {
            display:flex;
            align-items:center; flex-direction:row; justify-content:flex-end;
            margin-top:28px;

            p {
                width:auto;
                margin-right:16px;
                font-size:11px; line-height:24px; color:$col-grey; font-weight:600; text-transform:uppercase;
            }
            a {
                display:block; float:left;
                height:50px;

                @include media-breakpoint-up(xl) {
                    height:70px;
                }

                img {
                    height:100%; width:auto;
                }
            }
        }
    }

    &.bkg-dark, &.bkg-black {
        > div:not(.cover) {
            > div:not(.sponsor) {
                .category {
                    color:$col-light-red!important;

                    &:after {
                        background-color:$col-light-red!important;
                    }

                    @include media-breakpoint-up(lg) {
                        &:hover {
                            color:$col-white!important;

                            &:after {
                                background-color:$col-white!important;
                            }
                        }
                    }
                }
            }
        }
    }

    &.no-sponsor {
        > div:not(.cover) {
            > div:not(.sponsor) {
                margin-bottom:28px;

                @include media-breakpoint-up(lg) {
                    margin-bottom:78px;
                }
                @include media-breakpoint-up(xl) {
                    margin-bottom:98px;
                }
            }
        }
    }
}

.header-video {
    > a.cover {
        &:before {
            content:'';
            position:absolute; left:0; top:0;
            width:100%; height:100%;
            background-color:$col-black30;
            z-index:2;
        }
        > div {
            @include prefix(filter, grayscale(0), webkit moz o ms);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }

        @include media-breakpoint-up(lg) {
            &:hover {
                > div {
                    @include prefix(filter, grayscale(100%), webkit moz o ms);
                }
            }
        }
    }
    > .cover {
        iframe {
            width:100%; height:100%;
        }
    }
    > div:not(.cover) {
        > div:not(.sponsor) {
            padding:22px 0 0 0;

            @include media-breakpoint-up(lg) {
                padding:68px 0 0 0;
            }
            @include media-breakpoint-up(xl-1440) {
                padding:68px calc(0% + 33.3 * ((100vw - 1440px) / 480)) 0 0;
            }
            @include media-breakpoint-up(xxl-1920) {
                padding:68px 33.3% 0 0;
            }
        }

        > div:not(.sponsor) {
            h1, .h1 {
                color:$col-white;
            }
            a.author, p.author {
                color:$col-white;

                em {
                    color:$col-red;
                }
            }
        }
        .sponsor {
            p {
                color:$col-white;
            }
        }
    }
}

.header-special {
    display:flex;
    width:100%;
    padding:0!important; margin-top:38px; margin-bottom:10px;
    flex-direction:column;

    @include media-breakpoint-up(lg) {
        flex-direction:row;
    }

    > .cover {
        position:relative;
        display:block; float:left;
        width:100%;
        padding-bottom:56.25%; margin:0;
        background-position:center center; background-repeat:no-repeat; background-size:cover;

        @include media-breakpoint-up(lg) {
            width:50%;
            padding-bottom:470px;
        }
        @include media-breakpoint-up(xl) {
            width:64%;
        }
        @include media-breakpoint-up(xl-1440) {
            padding-bottom:630px;
        }
        > div {
            position:absolute; left:0; top:0;
            float:left;
            width:100%; height:100%;
            background-position:center top; background-repeat:no-repeat; background-size:cover;
            z-index:1;
        }
    }
    > div:not(.cover) {
        display:flex;
        width:100%; height:100%;
        padding:20px 16px 16px 16px;
        flex-direction:column; justify-content:space-between; align-items:flex-end;

        @include media-breakpoint-up(lg) {
            width:50%;
            padding:50px 30px;
        }
        @include media-breakpoint-up(xl) {
            width:36%;
            padding:90px 50px;
        }
        > div:not(.sponsor) {
            position:relative;
            float:left;
            width:100%;
            padding:0;
            text-align:center;

            @include media-breakpoint-up(dsk) {
                text-align:left;
            }
            @include media-breakpoint-up(xl-1440) {
                padding:0 calc(0% + 20 * ((100vw - 1440px) / 480)) 0 0;
            }
            @include media-breakpoint-up(xxl-1920) {
                padding:0 20% 0 0;
            }

            h2, .h2 {
                position:relative;
                padding-bottom:32px;
                font-size:24px; line-height:32px; color:$col-white; text-transform:inherit;
                text-align:center;

                &:before {
                    content:'';
                    position:absolute; left:50%; bottom:0;
                    width:34px; height:2px;
                    background-color:$col-light-red;
                    @include prefix(transform, translateX(-50%), webkit moz o ms);
                }

                @include media-breakpoint-up(dsk) {
                    padding-bottom:42px;
                    font-size:30px; line-height:38px;
                    text-align:left;

                    &:before {
                        left:0;
                        width:50px; height:3px;
                        @include prefix(transform, translateX(0), webkit moz o ms);
                    }
                }
            }
            p {
                margin-top:16px;
                font-size:18px; line-height:30px; font-weight:600; color:$col-white;

                @include media-breakpoint-up(dsk) {
                    margin-top:20px;
                    font-size:20px; line-height:32px;
                }
            }
        }
    }
}

.recipes-section {
    margin-bottom:5px;
    background-color:$col-lightest-grey;

    @include media-breakpoint-up(dsk) {
        margin-bottom:10px;
        background-color:transparent;
    }
}
.recipes-info {
    float:left;
    width:100%;
    padding:4px 0 26px 0;
    background-color:$col-lightest-grey;

    @include media-breakpoint-up(dsk) {
        padding:10px 16px 32px 16px;
    }
    > p {
        margin-top:10px;
        font-size:28px; line-height:34px; font-family:$font_caveat; color:$col-red; font-weight:bold; text-align:center;

        @include media-breakpoint-up(dsk) {
            margin-top:12px;
            font-size:32px; line-height:40px;
        }
    }
    ul {
        text-align:center; font-size:0;

        li {
            display:inline-block; float:none;
            width:calc((99.9% / 3) - 4px);
            padding:0 8px; margin-top:26px;
            text-align:center;

            @include media-breakpoint-up(dsk) {
                width:calc((99.9% / 4) - 4px);
            }
            @include media-breakpoint-up(xl) {
                width:calc((99.9% / 8) - 4px);
            }

            i {
                position:relative;
                display:inline-block; float:none;
                width:60px; height:60px;
                margin-bottom:2px;
                font-size:28px; line-height:60px; text-align:center; color:$col-red;

                &:after {
                    content:'';
                    position:absolute; left:0; top:0;
                    width:100%; height:100%;
                    border:1px solid $col-light-red20;
                    @include prefix(border-radius, 50%, webkit moz o ms);
                }

                @include media-breakpoint-up(dsk) {
                    width:68px; height:68px;
                    margin-bottom:12px;
                    font-size:36px; line-height:68px;
                }
            }
            p {
                margin-top:5px;
                font-size:16px; line-height:19px; letter-spacing:0.64px; color:$col-black; font-weight:900;

                &.label {
                    font-size:11px; line-height:14px; letter-spacing:0.4px; font-weight:600; text-transform:uppercase;
                }

                @include media-breakpoint-up(dsk) {
                    margin-top:6px;
                    font-size:18px; line-height:21px; letter-spacing:0.72px;

                    &.label {
                        font-size:12px;
                    }
                }
            }
        }
    }
}

.article-wrapper {
    float:left;
    width:100%;
    margin-top:20px;

    @include media-breakpoint-up(dsk) {
        margin-top:30px;
    }
}

.article-content {
    float:left;
    width:100%;
    color:$col-dark-grey; font-size:16px; line-height:28px;

    @include media-breakpoint-up(dsk) {
        padding-left:40px;
    }
    @include media-breakpoint-up(lg) {
        padding-left:80px;
    }

    > h2, > .h2 {
        margin-top:25px; margin-bottom:0;
        font-size:22px; line-height:32px; text-transform:inherit; font-weight:900; color:$col-green;

        @include media-breakpoint-up(dsk) {
            margin-top:38px;
            font-size:30px; line-height:38px;
        }

        em {
            margin:0; padding:0;
            font-size:22px; line-height:32px; text-transform:inherit; font-weight:900; color:$col-green; font-family:$font_raleway;

            @include media-breakpoint-up(dsk) {
                font-size:30px; line-height:38px;
            }
        }
    }
    > h3, > .h3 {
        margin-top:25px;
        font-size:22px; line-height:26px; text-transform:inherit; font-weight:900; color:$col-dark-grey;

        @include media-breakpoint-up(dsk) {
            margin-top:38px;
            font-size:30px; line-height:35px;
        }
    }
    > h2, > .h2, > h3, > .h3 {
        a {
            position:relative;
            color:inherit; text-decoration:none;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            text-decoration:underline!important;
            text-decoration-style:solid!important;
            text-decoration-thickness:2px!important;
            text-decoration-color:$col-light-red!important;
            text-underline-offset:6px!important;

            /*&:after {
                content:'';
                position:absolute; left:0; bottom:-2px;
                width:100%; height:2px;
                background-color:$col-light-red;
            }*/
            @include media-breakpoint-up(lg) {
                &:hover {
                    color:$col-light-red;
                }
            }
        }
    }
    p {
        margin-top:16px;
        color:$col-dark-grey; font-size:16px; line-height:28px;

        @include media-breakpoint-up(dsk) {
            margin-top:20px;
        }

        > img {
            width:100%!important; height:auto!important;
        }

        &.credits {
            margin-top:14px;
            font-size:13px; line-height:18px; color:$col-note-grey; text-align:left!important;

            @include media-breakpoint-up(dsk) {
                margin-top:16px;
                font-size:14px; line-height:20px;
            }
        }

        > iframe {
            @include media-breakpoint-down(dsk) {
                width:100%!important;
            }
        }
    }
    .btn {
        margin-top:16px;

        @include media-breakpoint-up(dsk) {
            margin-top:20px;
        }
    }
    form {
        margin-top:36px;

        @include media-breakpoint-up(dsk) {
            margin-top:44px;
        }
    }
    ul, ol {
        margin-top:20px;

        li {
            position:relative;
            padding-left:25px; margin-top:12px;
            font-size:16px; line-height:28px; font-family:$font_raleway; font-weight:600; color:$col-dark-grey;

            &:first-child {
                margin-top:0;
            }
            &:before {
                content:'';
                position:absolute; left:0; top:12px;
                width:7px; height:2px;
                background-color:$col-light-red;
            }

            @include media-breakpoint-up(dsk) {
                &:before {
                    top:13px;
                }
            }

            a {
                position:relative;
                color:inherit;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                text-decoration:underline!important;
                text-decoration-style:solid!important;
                text-decoration-thickness:2px!important;
                text-decoration-color:$col-light-red!important;
                text-underline-offset:2px!important;

                /*&:after {
                    content:'';
                    position:absolute; left:0; bottom:-2px;
                    width:100%; height:2px;
                    background-color:$col-red;
                }*/

                @include media-breakpoint-up(lg) {
                    &:hover {
                        color:$col-red;
                    }
                }
            }
        }

        @include media-breakpoint-up(dsk) {
            margin-top:25px;
        }

        &.quiz {
            margin-top:0; margin-bottom:24px;

            li {
                padding-left:0; margin-top:20px;

                &:first-child {
                    margin-top:0;
                }
                &:before {
                    content:none;
                }

                [type="radio"]:checked, [type="radio"]:not(:checked) {
                    position:absolute; left:-9999px;
                }
                [type="radio"]:checked + label, [type="radio"]:not(:checked) + label {
                    position:relative;
                    display:inline-block;
                    width:auto;
                    padding:0 0 0 56px; margin:0;
                    cursor:pointer;
                    font-size:16px; line-height:26px; font-family:$font_raleway; font-weight:600;
                }
                [type="radio"]:checked + label:before, [type="radio"]:not(:checked) + label:before {
                    content:'';
                    position:absolute; left:0; top:0;
                    width:26px; height:26px;
                    border:1px solid $col-quiz-grey;
                    background-color:$col-white;
                    @include prefix(border-radius, 50%, webkit moz o ms);
                    @include prefix(transition, all .15s ease-out, webkit moz o ms);
                }
                [type="radio"]:checked + label:after, [type="radio"]:not(:checked) + label:after {
                    content:'';
                    position:absolute; top:9px; left:9px;
                    width:8px; height:8px;
                    font-size:8px; line-height:8px;
                    background-color:$col-dark-grey;
                    @include prefix(border-radius, 50%, webkit moz o ms);
                    @include prefix(transform, scale(0,0), webkit moz o ms);
                    @include prefix(transition, all .15s ease-out, webkit moz o ms);
                }
                [type="radio"]:checked + label:before {
                    border:2px solid $col-dark-grey;
                }
                [type="radio"]:checked + label:after {
                    @include prefix(transform, scale(1,1), webkit moz o ms);
                }
            }

            @include media-breakpoint-up(dsk) {
                margin-bottom:30px;
            }
        }
    }
    ol {
        counter-reset:li;

        li {
            counter-increment:li;

            &:before {
                content:none;
            }
            &:after {
                content:counter(li)'.';
                position:absolute; left:0; top:-2px;
                font-size:20px; line-height:28px; color:$col-light-red; font-family:$font_raleway; font-weight:700;
            }
        }
    }
    > div {
        float:left;
        width:100%;
        margin-top:16px;

        @include media-breakpoint-up(dsk) {
            margin-top:20px;
        }
    }

    .article-index {
        position:relative;
        float:left;
        width:100%;
        margin:26px 0 0 0; padding:8px 0 25px 0;
        background-color:$col-light-red10;

        &:before, &:after {
            content:'';
            position:absolute; top:0;
            width:100vw; height:100%;
            background-color:$col-band-red;
        }
        &:before {
            right:100%/*calc(100% - 5px)*/;
        }
        &:after {
            left:100%/*calc(100% - 5px)*/;
        }

        @include media-breakpoint-up(dsk) {
            width:calc(100% + 40px);
            margin:40px 0 0 -40px; padding:16px 20px 25px 40px;

            &:before, &:after {
                content:none;
            }
        }
        @include media-breakpoint-up(lg) {
            width:calc(100% + 80px);
            margin:40px 0 0 -80px; padding:16px 20px 25px 80px;
        }
        p {
            margin-top:0;
            font-size:30px; line-height:38px; color:$col-red; font-family:$font_caveat;

            @include media-breakpoint-up(dsk) {
                font-size:32px; line-height:40px;
            }
        }
        ul {
            margin-top:-10px;

            li {
                margin-top:22px; padding-left:0;

                &:before {
                    content:none;
                }

                a {
                    position:relative;
                    display:inline; float:none;
                    width:auto;
                    padding-bottom:8px;
                    font-size:16px; line-height:18px; font-weight:900; color:$col-dark-grey; text-decoration:none!important; letter-spacing:0.6px;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    @include prefix(box-shadow, (inset 0 -6px $col-band-red, inset 0 -8px $col-light-red), webkit moz o ms);

                    &:after {
                        content:none;
                    }
                    @include media-breakpoint-up(dsk) {
                        font-size:15px;
                    }
                    @include media-breakpoint-up(lg) {
                        &:hover {
                            color:$col-grey;
                        }
                    }
                }
            }
        }
    }

    .line-title {
        margin-top:20px; margin-bottom:-5px;

        @include media-breakpoint-up(dsk) {
            margin-top:40px; margin-bottom:-10px;
        }
    }

    &.right-indent {
        @include media-breakpoint-up(dsk) {
            padding-right:12.5%;
        }
    }
}

.article-image {
    float:left;
    width:100%;
    margin-top:20px;

    @include media-breakpoint-up(dsk) {
        margin-top:24px;
    }

    &.mt-more {
        margin-top:30px;

        @include media-breakpoint-up(dsk) {
            margin-top:50px;
        }
    }
}

.article-tag {
    margin-top:20px;

    @include media-breakpoint-up(dsk) {
        margin-top:40px;
        padding-left:40px;
    }
    @include media-breakpoint-up(lg) {
        padding-left:80px;
    }

    li {
        width:auto;
        margin:2px 18px 2px 0;

        &:first-child {
            margin:0 24px 0 0;
        }
        &:last-child {
            margin-right:0;
        }

        p {
            width:auto;
            font-size:22px; line-height:28px; font-family:$font_caveat; color:$col-dark-grey; letter-spacing:0.22px;
        }
        a {
            position:relative;
            display:block; float:left;
            width:auto;
            padding:4px 0;
            font-size:14px; line-height:16px; font-family:$font_raleway; color:$col-red; letter-spacing:0.4px; font-weight:900;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            text-decoration:underline!important;
            text-decoration-style:solid!important;
            text-decoration-thickness:2px!important;
            text-decoration-color:$col-light-red!important;
            text-underline-offset:2px!important;

            /*&:after {
                content:'';
                position:absolute; left:0; bottom:0;
                width:100%; height:2px;
                background-color:$col-red;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }*/

            @include media-breakpoint-up(lg) {
                &:hover {
                    color:$col-light-red;

                    &:after {
                        background-color:$col-light-red;
                    }
                }
            }
        }
    }
}

.article-note {
    float:left;
    width:100%;
    margin-top:24px;

    @include media-breakpoint-up(dsk) {
        margin-top:46px;
        padding-left:40px;
    }
    @include media-breakpoint-up(lg) {
        padding-left:80px;
    }

    p {
        font-size:13px; line-height:18px; color:$col-note-grey;

        @include media-breakpoint-up(dsk) {
            font-size:14px; line-height:20px;
        }
    }
}

.article-letter {
    float:left;
    width:100%;
    margin-top:24px;

    @include media-breakpoint-up(dsk) {
        margin-top:40px;
    }

    > div:not(.line-title) {
        float:left;
        width:100%;
        padding:20px 20px 30px 20px;

        @include media-breakpoint-up(dsk) {
            padding:36px 40px 56px 40px;
        }
        @include media-breakpoint-up(lg) {
            padding:36px 80px 56px 80px;
        }
    }
    > .line-title {
        margin-bottom:16px;

        @include media-breakpoint-up(dsk) {
            margin-bottom:20px;
        }
    }
}

.quiz-result {
    float:left;
    width:100%;
    margin-top:20px;

    > div {
        position:relative;
        float:left;
        width:100%; max-width:240px;

        @include media-breakpoint-up(dsk) {
            max-width:320px;
        }

        &:before {
            content:'';
            position:absolute; right:0px; top:50%;
            width:99px; height:66px;
            background-position:center top; background-repeat:no-repeat; background-size:contain; background-image:url('../img/quiz-lines-result.svg');
            @include prefix(transform, translate(50%,-50%), webkit moz o ms);
            z-index:1;

            @include media-breakpoint-up(dsk) {
                width:116px; height:77px;
            }
        }
        > span {
            position:absolute; right:0px; top:50%;
            width:66px; height:66px;
            background-color:$col-light-red;
            @include prefix(transform, translate(50%,-50%), webkit moz o ms);
            @include prefix(border-radius, 50%, webkit moz o ms);
            z-index:3;

            i {
                position:absolute; left:50%; top:50%;
                width:42px; height:42px;
                font-size:42px; line-height:42px; color:$col-white;
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            }

            @include media-breakpoint-up(dsk) {
                width:82px; height:82px;

                i {
                    width:54px; height:54px;
                    font-size:54px; line-height:54px;
                }
            }
        }
        > div {
            position:relative;
            float:left;
            width:100%;
            overflow:hidden;
            @include prefix(border-radius, 50%, webkit moz o ms);
            z-index:2;

            &:before {
                content:'';
                position:absolute; left:0; top:0;
                width:100%; height:100%;
                background-color:$col-black25;
                z-index:1;
            }
        }
    }
    h3, .h3 {
        position:relative;
        width:100%;
        padding:12px 40px; margin-top:10px;
        color:$col-black;

        @include media-breakpoint-up(dsk) {
            width:70%;
            padding:16px 40px 16px 60px; margin-top:12px;
        }

        &:before, &:after {
            content:'';
            position:absolute;
            width:20px; height:20px;
            background-position:center center; background-repeat:no-repeat; background-size:contain;

            @include media-breakpoint-up(dsk) {
                width:28px; height:28px;
            }
        }
        &:before {
            left:0; top:0;
            background-image:url('../img/quote-light-red-left.svg');
        }
        &:after {
            right:0; bottom:0;
            background-image:url('../img/quote-light-red-right.svg');
        }
    }
    p {
        margin-top:20px;

        @include media-breakpoint-up(dsk) {
            margin-top:30px;
        }
    }
}

section {
    .container {
        &:first-child {
            .row {
                &:first-child {
                    > div {
                        &:first-child {
                            .article-content {
                                &:first-child {
                                    > * {
                                        &:first-child {
                                            margin-top:0!important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.tips-wrapper {
    float:left;
    width:100%;
    margin-top:18px; margin-bottom:-8px;
    text-align:center;

    @include media-breakpoint-up(dsk) {
        margin-bottom:-26px;
    }

    h4, .h4 {
        font-size:20px; line-height:26px; font-weight:800;

        @include media-breakpoint-up(dsk) {
            font-size:24px; line-height:30px;
        }
    }
    p {
        font-size:16px; line-height:28px; color:$col-white;

        @include media-breakpoint-up(dsk) {
            font-size:18px; line-height:30px;
        }
    }
}

.related-wrapper {
    margin-top:28px;

    > div {
        > * {
            &:first-child {
                &.related-box {
                    @include media-breakpoint-up(dsk) {
                        margin-top:-30px!important;
                    }
                }
            }
        }

        &:first-child {
            > * {
                &:first-child {
                    &.related-horoscope {
                        @include media-breakpoint-down(dsk) {
                            margin-top:0!important;
                        }
                    }
                    > * {
                        &:first-child {
                            @include media-breakpoint-down(dsk) {
                                margin-top:0!important;
                            }
                        }
                    }
                }
            }
        }
    }

    .related-video, .related-gallery {
        position:relative;
        float:left;
        width:100%;
        padding:15px;
        @include prefix(border-radius, 15px, webkit moz o ms);
        background-color:$col-black;
        @include prefix(box-shadow, 0px 7px 14px 0px rgba(0,0,0,0.2), webkit moz o ms);

        > a {
            position:absolute; left:32px; top:15px;
            width:calc(100% - 64px);
            z-index:2;

            h4, .h4 {
                color:$col-white;

                em {
                    display:block; float:left;
                    width:100%;
                    margin:0;
                    font-size:24px; line-height:26px; font-weight:normal; color:$col-white; letter-spacing:1.6px;

                    @include media-breakpoint-up(dsk) {
                        font-size:32px; line-height:34px;
                    }
                }
            }
        }
        > div {
            position:absolute; left:32px; bottom:26px;
            width:calc(100% - 64px);
            z-index:2;

            @include media-breakpoint-up(dsk) {
                padding-right:30%;
            }

            h3, .h3 {
                margin-top:15px;

                @include media-breakpoint-up(dsk) {
                    margin-top:18px;
                }
            }
            .category {
                position:relative;
                display:block; float:left;
                width:auto;
                padding-bottom:3px;
                font-size:19px; line-height:22px; color:$col-red; font-weight:900;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                &:after {
                    content:'';
                    position:absolute; left:0; bottom:0;
                    width:100%; height:2px;
                    background-color:$col-red;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }

                @include media-breakpoint-up(dsk) {
                    font-size:22px; line-height:26px;
                }
                @include media-breakpoint-up(lg) {
                    &:hover {
                        color:$col-light-red;

                        &:after {
                            background-color:$col-light-red;
                        }
                    }
                }
            }
        }
        .cover {
            position:relative; left:0; top:0;
            display:block; float:left;
            width:100%;
            text-align:center; font-size:0;
            z-index:1;

            > div {
                display:inline-block; float:none;
                width:auto;
                overflow:hidden;
                @include prefix(border-radius, 15px, webkit moz o ms);
                @include prefix(filter, grayscale(0), webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                img {
                    position:relative;
                    width:auto; max-width:100%;
                    z-index:1;
                }
            }
            &:before {
                content:'';
                position:absolute; left:0; top:0;
                width:100%; height:100%;
                background-color:$col-black50;
                z-index:2;
            }
            span {
                position:absolute; left:50%; top:50%;
                width:48px; height:48px;
                background-color:$col-red;
                @include prefix(border-radius, 50%, webkit moz o ms);
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                z-index:3;

                i {
                    position:absolute; left:50%; top:50%;
                    width:26px; height:26px;
                    font-size:26px; line-height:26px; color:$col-white;
                    @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                }

                @include media-breakpoint-up(dsk) {
                    width:66px; height:66px;

                    i {
                        width:30px; height:30px;
                        font-size:30px; line-height:30px;
                    }
                }
            }
        }
        @include media-breakpoint-up(lg) {
            &:hover {
                .cover {
                    > div {
                        @include prefix(filter, grayscale(100%), webkit moz o ms);
                    }
                }
            }
        }
    }
    .related-video, .related-gallery {
        padding:15px 60px;

        @include media-breakpoint-up(lg) {
            padding:15px 80px;
        }
    }
    .related-gallery {
        padding:15px 32px 26px 32px;

        > a {
            position:relative; left:inherit; top:inherit;
            float:left;
            width:100%;
        }
        > div {
            position:relative; left:inherit; bottom:inherit;
            float:left;
            width:100%;
        }

        .cover {
            margin-top:16px; margin-bottom:22px;

            > div {
                width:100%;
            }
            span {
                i {
                    width:22px; height:22px;
                    font-size:22px; line-height:22px;
                    margin-left:0;
                }

                @include media-breakpoint-up(dsk) {
                    i {
                        width:26px; height:26px;
                        font-size:26px; line-height:26px;
                    }
                }
            }
        }
    }
}

.related-box {
    float:left;
    width:calc(100% + 26px);
    margin-left:-13px; margin-right:-13px;

    > article {
        display:block; float:left;
        width:100%;
        padding:0 13px; margin-top:24px;

        @include media-breakpoint-up(dsk) {
            margin-top:30px;
        }

        @include media-breakpoint-up(lg) {
            width:50%;
        }

        > a:not(.label), > div {
            position:relative;
            display:flex; float:left;
            width:100%; height:242px;
            justify-content:center; flex-direction:column;
            background-position:center top; background-repeat:no-repeat; background-size:cover; background-color:$col-white;
            overflow:hidden;
            @include prefix(border-radius, 15px, webkit moz o ms);
            @include prefix(box-shadow, 0px 7px 14px 0px rgba(0,0,0,0.2), webkit moz o ms);

            &:after {
                content:'';
                position:absolute; left:0; top:0;
                width:100%; height:100%;
                background-color:transparent;
                z-index:1;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }

            .content {
                position:relative;
                padding:10px 28px;
                text-align:left;
                z-index:2;
                margin-top:5px;

                span {
                    position:relative;
                    display:block; float:left;
                    width:66px; height:66px;
                    @include prefix(box-shadow, 0px 3px 6px 0px rgba(0,0,0,0.1), webkit moz o ms);
                    @include prefix(border-radius, 50%, webkit moz o ms);

                    &:before {
                        content:'';
                        position:absolute; left:0; top:0;
                        width:100%; height:100%;
                        border:2px solid $col-light-red;
                        @include prefix(box-shadow, inset 0px 3px 6px 0px rgba(0,0,0,0.1), webkit moz o ms);
                        @include prefix(border-radius, 50%, webkit moz o ms);
                    }
                    i {
                        position:absolute; left:50%; top:50%;
                        width:28px; height:28px;
                        font-size:28px; line-height:28px; color:$col-white;
                        @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                    }
                }
                h5, .h5 {
                    margin-top:20px;
                    font-size:16px; line-height:22px; color:$col-white; font-weight:800;

                    strong {
                        font-weight:900;
                    }
                    @include media-breakpoint-up(dsk) {
                        font-size:18px; line-height:23px;
                    }
                    &:after {
                        content:none;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                &:hover {
                    &:after {
                        background-color:$col-black35;
                    }
                }
            }

            &.bkg-red {
                .content {
                    span {
                        &:before {
                            border:2px solid $col-light-red;
                        }
                    }
                }
            }
            &.bkg-blue, &.bkg-azure, &.bkg-violet, &.bkg-purple {
                .content {
                    span {
                        &:before {
                            border:2px solid $col-white;
                        }
                    }
                }
            }
        }

        &.related-box-quiz {
            a:not(.label) {
                display:block;

                &:after {
                    content:none;
                }
                .image {
                    float:left;
                    width:100%; height:100%;
                    background-position:center top; background-repeat:no-repeat; background-size:cover;
                    @include prefix(filter, grayscale(0), webkit moz o ms);
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }
                .content {
                    position:absolute; left:0; top:50%;
                    @include prefix(transform, translateY(-50%), webkit moz o ms);

                    span {
                        margin:0 13px;
                        background-color:$col-light-red;

                        &:before {
                            position:absolute; left:50%; top:50%;
                            width:92px; height:60px;
                            border:0 none;
                            background-position:center top; background-repeat:no-repeat; background-size:contain; background-image:url('../img/quiz-lines-big.svg');
                            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                            @include prefix(box-shadow, none, webkit moz o ms);
                            @include prefix(border-radius, 0, webkit moz o ms);
                        }
                        i {
                            width:34px; height:34px;
                            font-size:34px; line-height:34px;
                        }
                    }
                }
                .label {
                    position:absolute; right:18px; top:12px;
                    width:auto;
                    padding-bottom:2px;
                    font-size:15px; line-height:20px; letter-spacing:0.45px; font-weight:900; text-decoration:none; color:$col-white;
                    z-index:2;

                    &:after {
                        content:'';
                        position:absolute; left:0; bottom:0;
                        width:100%; height:2px;
                        background-color:$col-white;
                        @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    }
                }

                &:before {
                    content:'';
                    position:absolute; left:0; top:0;
                    width:100%; height:100%;
                    background-color:$col-black50;
                    z-index:1;
                }

                @include media-breakpoint-up(lg) {
                    &:hover {
                        .image {
                            @include prefix(filter, grayscale(100%), webkit moz o ms);
                        }
                        .label {
                            &:after {
                                background-color:$col-red;
                            }
                        }
                    }
                }
            }
        }

        &.related-box-promo {
            a:not(.label) {
                justify-content:flex-start;

                &:after {
                    content:none;
                }

                .promo-image {
                    float:left;
                    width:100%; height:150px;
                    background-position:center top; background-repeat:no-repeat; background-size:cover;
                    @include prefix(filter, grayscale(0), webkit moz o ms);
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }
                .content {
                    margin-top:12px;

                    h5, .h5 {
                        margin-top:0;
                        text-align:center; color:$col-dark-grey;

                        strong {
                            color:$col-red; font-weight:800;
                        }
                        &:after {
                            content:none;
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    &:hover {
                        .promo-image {
                            @include prefix(filter, grayscale(100%), webkit moz o ms);
                        }
                    }
                }
            }
        }

        &.related-box-video {
            position:relative;
            width:100%;

            @include media-breakpoint-up(lg) {
                width:50%;
            }

            > div {
                display:block; float:left;
                height:auto;

                &:after {
                    content:none;
                }
                > .label {
                    position:absolute; right:18px; top:12px;
                    width:auto;
                    padding-bottom:2px;
                    font-size:15px; line-height:20px; letter-spacing:0.45px; font-weight:900; text-decoration:none; color:$col-red;
                    z-index:2;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);

                    &:after {
                        content:'';
                        position:absolute; left:0; bottom:0;
                        width:100%; height:2px;
                        background-color:$col-red;
                        @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    }

                    @include media-breakpoint-up(lg) {
                        &:hover {
                            color:$col-light-red;
                        }
                    }
                }
                > .content {
                    position:absolute; left:0; bottom:0;
                    padding:20px 28px; margin-top:0;

                    h5, .h5 {
                        margin-top:0;
                        font-size:16px; line-height:22px; color:$col-white; font-weight:800;

                        strong {
                            font-weight:900;
                        }
                        @include media-breakpoint-up(dsk) {
                            font-size:18px; line-height:23px;
                        }
                        &:after {
                            content:none;
                        }
                    }
                    .label {
                        position:relative;
                        width:auto;
                        padding-bottom:2px;
                        font-size:15px; line-height:20px; letter-spacing:0.45px; font-weight:900; text-decoration:none; color:$col-red;
                        z-index:2;
                        @include prefix(transition, all .3s ease-out, webkit moz o ms);

                        &:after {
                            content:'';
                            position:absolute; left:0; bottom:0;
                            width:100%; height:2px;
                            background-color:$col-red;
                            @include prefix(transition, all .3s ease-out, webkit moz o ms);
                        }
                        @include media-breakpoint-up(lg) {
                            &:hover {
                                color:$col-light-red;
                            }
                        }
                    }
                }
                > .cover {
                    position:relative;
                    display:block; float:left;
                    width:100%; height:242px;
                    background-position:center center; background-repeat:no-repeat; background-size:cover;
                    @include prefix(filter, grayscale(0), webkit moz o ms);
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);

                    &:before {
                        content:'';
                        position:absolute; left:0; top:0;
                        width:100%; height:100%;
                        background-color:$col-black50;
                        z-index:0;
                    }
                    &:after {
                        content:'';
                        position:absolute; left:0; top:0;
                        width:100%; height:100%;
                        background-color:transparent;
                        z-index:1;
                        @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    }
                }
                > .icon {
                    position:absolute; left:50%; top:50%;
                    width:46px; height:46px;
                    margin-top:-20px;
                    background-color:$col-light-red;
                    @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                    @include prefix(border-radius, 50%, webkit moz o ms);
                    z-index:2;

                    @include media-breakpoint-up(lg) {
                        margin-top:-40px;
                    }
                    @include media-breakpoint-up(xl) {
                        margin-top:-20px;
                    }

                    i {
                        position:absolute; left:50%; top:50%;
                        width:20px; height:20px;
                        margin-left:2px;
                        font-size:20px; line-height:20px; color:$col-white;
                        @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                &:hover {
                    > div {
                        > .cover {
                            @include prefix(filter, grayscale(100%), webkit moz o ms);
                        }
                    }
                    .label {
                        &:after {
                            background-color:$col-red;
                        }
                    }
                }
            }

            &.wide {
                width:100%;

                > div {
                    > .content {
                        h5, .h5 {
                            margin-top:16px;
                            font-size:20px; line-height:26px;

                            @include media-breakpoint-up(dsk) {
                                margin-top:18px;
                                font-size:24px; line-height:32px;
                                padding-right:20%;
                            }
                            &:after {
                                content:none;
                            }
                        }
                        .label {
                            font-size:18px; line-height:20px; letter-spacing:0.45px;

                            @include media-breakpoint-up(dsk) {
                                font-size:22px; line-height:25px; letter-spacing:0.66px;
                            }
                        }
                    }
                    > .cover {
                        height:326px;

                        @include media-breakpoint-up(dsk) {
                            height:376px;
                        }

                        &:before {
                            background:rgb(0,0,0);
                            background:-moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                            background:-webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                            background:linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                            filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
                        }
                    }
                    > .icon {
                        margin-top:0;

                        @include media-breakpoint-up(dsk) {
                            width:66px; height:66px;

                            i {
                                width:30px; height:30px;
                                font-size:30px; line-height:30px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.full {
        > article {
            width:100%;

            @include media-breakpoint-up(dsk) {
                width:50%;
            }
            @include media-breakpoint-up(xl) {
                width:25%;
            }
        }
    }
}
