.horoscope-wrapper {
    .horoscope-content {
        float:left;
        width:100%;
        margin-top:8px;
        text-align:center;
        
        @include media-breakpoint-up(lg) {
            margin-top:40px;
            text-align:left;
        }
        
        .title {
            position:relative;
            display:inline-block; float:none;
            width:auto;
            padding:34px 42px 38px 42px;
            background-image:url('../img/horoscope-stars-sm.svg'); background-position:center center; background-repeat:no-repeat; background-size:contain;
            
            @include media-breakpoint-up(lg) {
                float:left;
                padding:30px 105px 38px 0;
                background-image:url('../img/horoscope-stars.svg'); background-position:right center;
                
                &:hover {
                    h2, .h2 {
                        color:$col-light-red;
                    }
                }
            }
            
            h2, .h2 {
                color:$col-red;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }
            p {
                position:absolute; right:35px; bottom:0;
                width:auto;
                font-size:32px; line-height:40px; font-family:$font_caveat; font-weight:normal; letter-spacing:0.32px; color:$col-black;
                
                @include media-breakpoint-up(lg) {
                    right:105px;
                }
            }
        }
        
        h3, .h3 {
            display:none;
            margin-top:38px;
            font-size:26px; line-height:34px; color:$col-dark-grey;

            @include media-breakpoint-up(lg) {
                display:block;
            }
        }
        p {
            margin-top:12px;
            font-size:16px; line-height:25px; color:$col-grey;
    
            @include media-breakpoint-up(lg) {
                margin-top:15px;
                font-size:15px; line-height:24px;
            }
        }
    }
    .horoscope-zodiac {
        float:left;
        width:100%;
        margin:20px 0 6px 0;
        
        @include media-breakpoint-up(lg) {
            margin:5px 0; padding:51px 36px;
        }
        
        ul {
            position:relative;
            text-align:center;
            
            @include media-breakpoint-up(lg) {
                height:366px;
            }
            
            li {
                position:relative;
                display:inline-block; float:none;
                width:auto;
                margin:4px 3px;
                
                @include media-breakpoint-up(lg) {
                    position:absolute;
                    @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                }
                
                a {
                    display:flex; float:left;
                    width:82px; height:114px;
                    background-color:$col-white;
                    flex-direction:column;  justify-content:center; align-items:center;
                    @include prefix(box-shadow, 0px 3px 8px 0px rgba(0,0,0,0.3), webkit moz o ms);
                    @include prefix(border-radius, 10px, webkit moz o ms);
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    
                    i {
                        width:36px; height:36px;
                        font-size:36px; line-height:36px; color:$col-red;
                    }
                    p {
                        position:relative;
                        margin-top:12px;
                        font-size:25px; line-height:34px; color:$col-green; font-family:$font_caveat; font-weight:normal; letter-spacing:-2px; text-align:center;
                        @include prefix(transform, translateX(-2px), webkit moz o ms);
                        z-index:1;
                        
                        &:before {
                            content:'';
                            position:absolute; left:50%; bottom:4px;
                            width:54px; height:12px;
                            margin-left:2px;
                            background-color:$col-light-green20;
                            @include prefix(transform, translateX(-50%), webkit moz o ms);
                            @include prefix(transition, all .3s ease-out, webkit moz o ms);
                            z-index:-1;
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        width:74px; height:102px;

                        i {
                            width:30px; height:30px;
                            font-size:30px; line-height:30px;
                        }
                        p {
                            margin-top:5px;
                            font-size:24px; line-height:28px;

                            &:before {
                                width:48px; height:10px;
                            }
                        }
                        
                        &:hover {
                            background-color:$col-lightest-green;
                            
                            p {
                                &:before {
                                    background-color:$col-white;
                                }
                            }
                        }
                    }
                }
                
                @include media-breakpoint-up(lg) {
                    &:nth-child(1) {
                        left:50%; top:0%;
                    }
                    &:nth-child(2) {
                        left:75%; top:7%;
                    }
                    &:nth-child(3) {
                        left:100%; top:30%;
                    }
                    &:nth-child(4) {
                        left:100%; top:70%;
                    }
                    &:nth-child(5) {
                        left:75%; top:93%;
                    }
                    &:nth-child(6) {
                        left:50%; top:100%;
                    }
                    &:nth-child(7) {
                        left:25%; top:93%;
                    }
                    &:nth-child(8) {
                        left:0%; top:70%;
                    }
                    &:nth-child(9) {
                        left:0%; top:30%;
                    }
                    &:nth-child(10) {
                        left:25%; top:7%;
                    }
                    &:nth-child(11) {
                        left:34%; top:50%;
                    }
                    &:nth-child(12) {
                        left:66%; top:50%;
                    }
                }
            }
        }
    }
}

.related-horoscope {
    position:relative;
    float:left;
    width:100%;
    padding:38px 27px 42px 27px; margin-top:24px!important;
    text-align:center;
    background-color:$col-white;
    @include prefix(border-radius, 15px, webkit moz o ms);
    @include prefix(box-shadow, 0px 7px 14px 0px rgba(0,0,0,0.2), webkit moz o ms);
    
    @include media-breakpoint-up(lg) {
        margin-top:0!important;
    }
    
    .title {
        position:relative;
        display:inline-block; float:none;
        width:auto;
        padding:30px 40px;
        background-image:url('../img/related-horoscope-stars.svg'); background-position:center center; background-repeat:no-repeat; background-size:contain;

        @include media-breakpoint-up(lg) {
            padding:30px 100px 40px 100px;

            &:hover {
                h2, .h2 {
                    color:$col-light-red;
                }
            }
        }

        h2, .h2 {
            color:$col-red;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }
        p {
            position:absolute; right:15px; bottom:0;
            width:auto;
            font-size:24px; line-height:30px; font-family:$font_caveat; font-weight:normal; letter-spacing:0.32px; color:$col-black;

            @include media-breakpoint-up(lg) {
                right:105px;
                font-size:32px; line-height:40px;
            }
        }
    }
    .horoscope-zodiac {
        float:left;
        width:100%;
        margin-top:14px;
        text-align:center;
        
        @include media-breakpoint-up(lg) {
            margin-top:14px;
        }
            
        li {
            position:relative;
            display:inline-block; float:none;
            width:calc(99.9% / 3);
            padding:18px 3px 0 3px;

            @include media-breakpoint-up(lg) {
                width:calc(99.9% / 4);
            }
            @include media-breakpoint-up(xl) {
                width:calc(99.9% / 6);
            }

            a {
                display:flex; float:left;
                width:100%; height:64px;
                background-color:$col-white;
                flex-direction:column;  justify-content:center; align-items:center;
                @include prefix(box-shadow, 0px 3px 8px 0px rgba(0,0,0,0.2), webkit moz o ms);
                @include prefix(border-radius, 10px, webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                p {
                    position:relative;
                    font-size:25px; line-height:34px; color:$col-green; font-family:$font_caveat; font-weight:normal; letter-spacing:-2px; text-align:center;
                    @include prefix(transform, translateX(-2px), webkit moz o ms);
                    z-index:1;

                    &:before {
                        content:'';
                        position:absolute; left:50%; bottom:8px;
                        width:54px; height:12px;
                        margin-left:2px;
                        background-color:$col-light-green20;
                        @include prefix(transform, translateX(-50%), webkit moz o ms);
                        @include prefix(transition, all .3s ease-out, webkit moz o ms);
                        z-index:-1;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &:hover {
                        background-color:$col-lightest-green;

                        p {
                            &:before {
                                background-color:$col-white;
                            }
                        }
                    }
                }
            }
        }
    }
    
    .horoscope-month {
        display:block; float:left;
        width:100%;
        margin-top:30px; margin-bottom:-6px;
        
        @include media-breakpoint-up(dsk) {
            margin-top:60px; margin-bottom:-2px;
        }
        
        .icon {
            float:left;
            width:100%;
            text-align:center; font-size:0;
            
            i {
                display:inline-block; float:none;
                width:80px; height:80px;
                font-size:80px; line-height:80px; color:$col-black;
                
                @include media-breakpoint-up(dsk) {
                    width:120px; height:120px;
                    font-size:120px; line-height:120px;
                }
            }
            p {
                margin-top:10px;
                font-size:20px; line-height:24px; color:$col-red; font-weight:800;
                
                @include media-breakpoint-up(dsk) {
                    margin-top:12px;
                    font-size:24px;
                }
            }
        }
        .content {
            float:left;
            width:100%;
            margin-top:26px;
            text-align:center;
            
            @include media-breakpoint-up(dsk) {
                margin-top:32px;
                text-align:left;
            }
            
            div {
                display:inline-flex; float:none;
                width:80px; height:58px;
                background-color:$col-white;
                flex-direction:column;  justify-content:center; align-items:center;
                @include prefix(box-shadow, 0px 3px 8px 0px rgba(0,0,0,0.2), webkit moz o ms);
                @include prefix(border-radius, 10px, webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
                
                @include media-breakpoint-up(dsk) {
                    display:flex; float:left;
                    height:64px;
                }

                p {
                    position:relative;
                    font-size:25px; line-height:34px; color:$col-green; font-family:$font_caveat; font-weight:normal; letter-spacing:-2px; text-align:center;
                    @include prefix(transform, translateX(-2px), webkit moz o ms);
                    z-index:1;

                    &:before {
                        content:'';
                        position:absolute; left:50%; bottom:8px;
                        width:54px; height:12px;
                        margin-left:2px;
                        background-color:$col-light-green20;
                        @include prefix(transform, translateX(-50%), webkit moz o ms);
                        @include prefix(transition, all .3s ease-out, webkit moz o ms);
                        z-index:-1;
                    }
                }                
            }
            > h5, > .h5 {
                margin-top:18px;
                font-size:20px; line-height:26px; color:$col-black; font-weight:800;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
                
                @include media-breakpoint-up(dsk) {
                    margin-top:20px;
                    font-size:24px; line-height:32px;
                }
                
                &:after {
                    content:none;
                }
            }
            > p {
                margin-top:8px;
                font-size:14px; line-height:22px; color:$col-black; font-weight:600;
                
                @include media-breakpoint-up(dsk) {
                    margin-top:10px;
                    font-size:15px; line-height:24px;
                }
            }
        }
        
        @include media-breakpoint-up(lg) {
            &:hover {
                .content {
                    div {
                        background-color:$col-lightest-green;

                        p {
                            &:before {
                                background-color:$col-white;
                            }
                        }                
                    }
                    > h5, > .h5 {
                        color:$col-grey;
                    }
                }
            }
        }
    }
}
    
.horoscope-title {
    position:relative;
    float:left;
    width:100%;
    
    h2, .h2 {
        color:$col-dark-grey; text-transform:inherit;
    }
    p {
        margin-top:12px;
        color:$col-dark-grey;

        @include media-breakpoint-up(dsk) {
            margin-top:15px;
        }
    }
    a.author, p.author {
        position:relative;
        display:block; float:left;
        margin-top:34px; padding-top:12px;
        font-size:13px; line-height:24px; font-weight:900; color:$col-black;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        em {
            font-style:normal; color:$col-red;
        }
        &:after {
            content:'';
            position:absolute; left:0; top:0;
            width:50px; height:2px;
            background-color:$col-light-red;
        }

        @include media-breakpoint-up(dsk) {
            margin-top:48px; padding-top:14px;
            font-size:14px;
        }
    }
    a.author {
        width:100%;
        
        @include media-breakpoint-up(lg) {
            width:auto;
            
            &:hover {
                color:$col-red;
            }
        }
    }
    .social-list {
        margin-top:30px;

        li {
            display:inline-block; float:none;
            margin:0 16px;

            &:first-child {
                margin-left:0;
            }
            &:last-child {
                margin-right:0;
            }

            a {
                width:42px; height:42px;
                @include prefix(box-shadow, 0px 3px 6px 0px rgba(0,0,0,0.1), webkit moz o ms);

                i {
                    width:18px; height:18px;
                    font-size:18px; line-height:18px;
                    @include prefix(text-shadow, 0px 0px 6px rgba(0,0,0,0.1), webkit moz o ms);
                }
                &:before {
                    @include prefix(box-shadow, inset 0px 3px 6px 0px rgba(0,0,0,0.1), webkit moz o ms);
                }

                @include media-breakpoint-up(dsk) {
                    width:52px; height:52px;

                    i {
                        width:24px; height:24px;
                        font-size:24px; line-height:24px;
                    }
                }
                @include media-breakpoint-up(lg) {
                    &:hover {            
                        i {
                            @include prefix(text-shadow, none, webkit moz o ms);
                        }
                        &:before {
                            @include prefix(box-shadow, none, webkit moz o ms);
                        }
                    }
                }
            }
        }
        
        @include media-breakpoint-up(dsk) {
            li {
                display:block; float:left;
            }
        }
        @include media-breakpoint-up(lg) {
            margin-top:42px;

            li {
                margin:0 20px;
            }
        }
    }
}

.horoscope-head {
    margin-top:32px;
    
    @include media-breakpoint-up(dsk) {
        margin-top:38px;
    }
    
    .horoscope-title {
        padding-top:84px;
        background-image:url('../img/horoscope-symbol.svg'); background-position:center top; background-repeat:no-repeat; background-size:106px 64px;
        text-align:center;
        
        @include media-breakpoint-up(dsk) {
            padding-top:122px;
            background-size:165px 100px;
            text-align:left;
            background-position:left top;
        }
        @include media-breakpoint-up(lg) {
            margin-top:20px;
        }
        @include media-breakpoint-up(xl) {
            margin-top:100px;
        }
        
        a.author, p.author {
            &:after {
                left:50%;                
                @include prefix(transform, translateX(-50%), webkit moz o ms);
                
                @include media-breakpoint-up(dsk) {
                    left:0;
                    @include prefix(transform, translateX(0), webkit moz o ms);
                }
            }
        }
    }
    .horoscope-zodiac {
        position:relative;
        float:left;
        width:100%;
        margin:34px 0 6px 0;
        
        @include media-breakpoint-up(dsk) {
            margin:14px 0 6px 0;
        }
        @include media-breakpoint-up(lg) {
            margin:0; padding:70px 49px;
        }
        
        ul {
            position:relative;
            text-align:center;
            z-index:2;
            
            @include media-breakpoint-up(lg) {
                padding-bottom:100%;
            
                &:before, &:after {
                    content:'';
                    position:absolute; left:50%; top:50%;
                    border:1px dashed $col-note-grey;
                    @include prefix(border-radius, 50%, webkit moz o ms);
                    @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                    z-index:1;
                }
                &:before {
                    width:102%; height:102%;
                }
                &:after {
                    width:40%; height:40%;
                }
            }
            
            li {
                position:relative;
                display:inline-block; float:none;
                width:auto;
                margin:4px 3px;
                z-index:2;
                
                @include media-breakpoint-up(lg) {
                    position:absolute;
                    @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                }
                
                a {
                    display:flex; float:left;
                    width:82px; height:104px;
                    background-color:$col-white;
                    flex-direction:column;  justify-content:center; align-items:center;
                    @include prefix(box-shadow, 0px 3px 8px 0px rgba(0,0,0,0.3), webkit moz o ms);
                    @include prefix(border-radius, 10px, webkit moz o ms);
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    
                    i {
                        width:34px; height:34px;
                        font-size:34px; line-height:34px; color:$col-red;
                    }
                    p {
                        position:relative;
                        margin-top:8px;
                        font-size:25px; line-height:28px; color:$col-green; font-family:$font_caveat; font-weight:normal; letter-spacing:-2px; text-align:center;
                        @include prefix(transform, translateX(-2px), webkit moz o ms);
                        z-index:1;
                        
                        &:before {
                            content:'';
                            position:absolute; left:50%; bottom:4px;
                            width:54px; height:12px;
                            margin-left:2px;
                            background-color:$col-light-green20;
                            @include prefix(transform, translateX(-50%), webkit moz o ms);
                            @include prefix(transition, all .3s ease-out, webkit moz o ms);
                            z-index:-1;
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        height:114px;

                        i {
                            width:36px; height:36px;
                            font-size:36px; line-height:36px;
                        }
                        p {
                            margin-top:12px;
                            font-size:27px; line-height:34px;

                            &:before {
                                width:54px; height:12px;
                            }
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        width:98px; height:140px;

                        i {
                            width:38px; height:38px;
                            font-size:38px; line-height:38px;
                        }
                        p {
                            margin-top:8px;
                            font-size:29px; line-height:36px;

                            &:before {
                                width:66px; height:15px;
                            }
                        }
                        
                        &:hover {
                            background-color:$col-lightest-green;
                            
                            p {
                                &:before {
                                    background-color:$col-white;
                                }
                            }
                        }
                    }
                }
                
                @include media-breakpoint-up(lg) {
                    &:nth-child(1) {
                        left:50%; top:0%;
                    }
                    &:nth-child(2) {
                        left:75%; top:7%;
                    }
                    &:nth-child(3) {
                        left:100%; top:30%;
                    }
                    &:nth-child(4) {
                        left:100%; top:70%;
                    }
                    &:nth-child(5) {
                        left:75%; top:93%;
                    }
                    &:nth-child(6) {
                        left:50%; top:100%;
                    }
                    &:nth-child(7) {
                        left:25%; top:93%;
                    }
                    &:nth-child(8) {
                        left:0%; top:70%;
                    }
                    &:nth-child(9) {
                        left:0%; top:30%;
                    }
                    &:nth-child(10) {
                        left:25%; top:7%;
                    }
                    &:nth-child(11) {
                        left:34%; top:50%;
                    }
                    &:nth-child(12) {
                        left:66%; top:50%;
                    }
                }
            }
        }
    }
}

.horoscope-list {
    margin-top:-15px;
    
    @include media-breakpoint-up(dsk) {
        margin-top:-28px;
    }
    
    article {
        position:relative;
        display:block; float:left;
        width:100%;
        margin-top:15px;
        @include prefix(border-radius, 8px, webkit moz o ms);
        overflow:hidden;

        @include media-breakpoint-up(dsk) {
            margin-top:28px;
        }
        
        > div {
            position:absolute; left:0; bottom:0;
            width:100%;
            padding:24px 34px;
            z-index:2;
            
            > a {
                display:block; float:left;
                width:100%;
            }
            .label {
                position:relative;
                width:auto;
                padding-bottom:2px;
                font-size:13px; line-height:20px; letter-spacing:0.45px; font-weight:900; text-decoration:none; color:$col-light-red;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                &:after {
                    content:'';
                    position:absolute; left:0; bottom:0;
                    width:100%; height:2px;
                    background-color:$col-light-red;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }
                
                @include media-breakpoint-up(dsk) {
                    font-size:15px;
                }
                @include media-breakpoint-up(lg) {
                    &:hover {
                        color:$col-white;

                        &:after {
                            background-color:$col-white;
                        }
                    }
                }
            }
            h3, .h3 {
                margin-top:8px;
                
                @include media-breakpoint-up(dsk) {
                    margin-top:10px;
                }
            }
            .author {
                display:block;
                margin-top:10px;
                font-size:13px; line-height:24px; font-weight:900; color:$col-white;

                em {
                    font-style:normal; color:$col-red;
                }
            }
        }
        > a {
            position:relative;
            display:block; float:left;
            width:100%;
            padding-bottom:92%;
            text-decoration:none;
            z-index:1;
            
            @include media-breakpoint-up(lg) {
                padding-bottom:116%;
            }
            
            > i {
                position:absolute; left:50%; top:18px;
                width:72px; height:72px;
                font-size:72px; line-height:72px;
                opacity:1;
                @include prefix(transform, translateX(-50%), webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
                z-index:1;
                
                @include media-breakpoint-up(sm-320) {
                    top:calc(18px + 6 * ((100vw - 320px) / 94));
                    width:calc(72px + 70 * ((100vw - 320px) / 94)); height:calc(72px + 70 * ((100vw - 320px) / 94));
                    font-size:calc(72px + 70 * ((100vw - 320px) / 94)); line-height:calc(72px + 70 * ((100vw - 320px) / 94));
                }
                @include media-breakpoint-up(sm-414) {
                    top:24px;
                    width:142px; height:142px;
                    font-size:142px; line-height:142px;
                }
                @include media-breakpoint-up(dsk) {
                    top:calc(24px + 6 * ((100vw - 700px) / 292));
                    width:calc(90px + 70 * ((100vw - 700px) / 292)); height:calc(90px + 70 * ((100vw - 700px) / 292));
                    font-size:calc(90px + 70 * ((100vw - 700px) / 292)); line-height:calc(90px + 70 * ((100vw - 700px) / 292));
                }
                @include media-breakpoint-up(lg) {
                    top:calc(30px + 30 * ((100vw - 992px) / 208));
                    width:126px; height:126px;
                    font-size:126px; line-height:126px;
                }
                @include media-breakpoint-up(xl) {
                    top:60px;
                }
            }
            
            &.medium {
                > i {
                    @include media-breakpoint-up(lg) {
                        top:110px;
                        width:150px; height:150px;
                        font-size:150px; line-height:150px;
                    }
                }
            }
            &.big {
                > i {
                    @include media-breakpoint-up(lg) {
                        top:50px;
                        width:210px; height:210px;
                        font-size:210px; line-height:210px;
                    }
                }
            }
        }
        
        &.large {
            @include prefix(border-radius, 15px, webkit moz o ms);
            
            > a {
                padding-bottom:92%;
            }
        }
        
        &.bkg-dark-grey, &.bkg-dark {
            > a {
                > i {
                    color:$col-light-red;
                }
            }
        }
        &.bkg-green {
            > a {
                > i {
                    color:$col-light-green;
                }
            }
        }
        &.bkg-light-red {
            > div {
                .label {
                    color:$col-white;

                    &:after {
                        background-color:$col-white;
                    }

                    @include media-breakpoint-up(lg) {
                        &:hover {
                            color:$col-quiz-grey;

                            &:after {
                                background-color:$col-quiz-grey;
                            }
                        }
                    }
                }
            }
            > a {
                > i {
                    color:$col-red;
                }
            }
        }
        &.bkg-light-green {
            > div {
                .label {
                    color:$col-green;

                    &:after {
                        background-color:$col-green;
                    }

                    @include media-breakpoint-up(lg) {
                        &:hover {
                            color:$col-white;

                            &:after {
                                background-color:$col-white;
                            }
                        }
                    }
                }
            }
            > a {
                > i {
                    color:$col-green;
                }
            }
        }
        
        @include media-breakpoint-up(lg) {
            &:hover {
                > a {
                    i {
                        opacity:0.5;
                    }
                }
            }
        }
    }

    &.horoscope-slider {
        display:none;
        float:left;
        width:calc(100% + 16px);
        margin-left:-8px; margin-right:-8px;
        
        &.slick-initialized {
            display:block;
        }
        .slick-list {
            overflow:visible;
            padding:0;
        }
        
        .slick-slide {
            padding:0 8px;
            
            .teaser {
                flex-direction:column;
                
                .teaser-top {
                    width:100%;
                }
                .content {
                    width:100%;
                    padding:0;
                    margin-top:10px;
                }
            }
        }
        
        @include media-breakpoint-up(dsk) {
            width:calc(100% + 36px);
            margin-left:-18px; margin-right:-18px;
            
            .slick-slider {
                float:left;
                padding:0 18px;
            }
        }
        @include media-breakpoint-up(lg) {
            display:block!important;
            
            > div {
                float:left;
                width:33.3%;
                padding:0 18px;
            }
        }
    
        article {
            > a {
                > i {
                    /*@include media-breakpoint-up(dsk) {
                        top:30px;
                        width:90px; height:90px;
                        font-size:90px; line-height:90px;
                    }
                    @include media-breakpoint-up(lg) {
                        top:60px;
                        width:126px; height:126px;
                        font-size:126px; line-height:126px;
                    }*/
                }
            }
        }
    }
}

.header-horoscope {
    margin-top:30px;
    
    > .order-dsk-2 {
        text-align:center;
    }
    
    .horoscope-title {
        float:left;
        width:100%;
        margin-top:30px;
        text-align:center;
        
        @include media-breakpoint-up(dsk) {
            margin-top:0;
            text-align:left;
        }
        @include media-breakpoint-up(lg) {
            margin-top:38px;
        }
        @include media-breakpoint-up(xl) {
            padding-right:14%;
        }
        
        .label {
            position:relative;
            display:inline-block; float:none;
            width:auto;
            padding-bottom:3px;
            font-size:18px; line-height:22px; letter-spacing:0.45px; font-weight:900; text-decoration:none; color:$col-light-red;
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            &:after {
                content:'';
                position:absolute; left:0; bottom:0;
                width:100%; height:2px;
                background-color:$col-light-red;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }

            @include media-breakpoint-up(dsk) {
                display:block; float:left;
                font-size:22px; line-height:26px;
            }
            @include media-breakpoint-up(lg) {
                &:hover {
                    color:$col-red;

                    &:after {
                        background-color:$col-red;
                    }
                }
            }
        }
        h2, .h2 {
            margin-top:12px;
            
            @include media-breakpoint-up(dsk) {
                margin-top:14px;
            }
        }
        a.author, p.author {
            &:after {
                left:50%;                
                @include prefix(transform, translateX(-50%), webkit moz o ms);
                
                @include media-breakpoint-up(dsk) {
                    left:0;
                    @include prefix(transform, translateX(0), webkit moz o ms);
                }
            }
        }
    }
    
    .horoscope-icon {
        position:relative;
        display:inline-block; float:none;
        width:180px;
        padding-bottom:180px;
        
        @include media-breakpoint-up(dsk) {
            width:100%;
            padding-bottom:100%;
        }
        
        &:before {
            content:'';
            position:absolute; left:50%; top:50%;
            width:100%; height:100%;
            border:1px dashed $col-note-grey;
            z-index:1;
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            @include prefix(border-radius, 50%, webkit moz o ms);
        }
        > div {
            position:absolute; left:50%; top:50%;
            width:100%;
            padding:15px;
            z-index:2;
            text-align:center;
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            
            @include media-breakpoint-up(lg) {
                padding:30px;
            }
            
            > span {
                position:relative;
                display:inline-block; float:none;
                width:74px; height:74px;
                margin-top:6px;
                
                i {
                    position:absolute; left:0; top:0;
                    width:100%; height:100%;
                    font-size:74px; line-height:74px; color:$col-red;
                    z-index:2;
                }
                &:before {
                    content:'';
                    position:absolute; left:50%; bottom:6px;
                    width:126px; height:44px;
                    background-color:$col-light-green20;
                    @include prefix(transform, translateX(-50%), webkit moz o ms);
                }
                @include media-breakpoint-up(dsk) {
                    &:before {
                        width:146px;
                    }
                }
                @include media-breakpoint-up(lg) {
                    width:134px; height:134px;
                    margin-top:16px;

                    i {
                        width:100%; height:100%;
                        font-size:134px; line-height:134px;
                    }
                    &:before {
                        width:246px; height:74px;
                    }
                }
            }
            > p {
                display:inline-block; float:none;
                margin-top:0;
                font-size:40px; line-height:46px; color:$col-green; font-family:$font_caveat; text-align:center; letter-spacing:-4.2px;
                
                @include media-breakpoint-up(lg) {
                    margin-top:4px;
                    font-size:60px; line-height:76px;
                }
            }
        }
    }
}

.zodiac-sign {
    position:relative;
    float:left;
    width:100%;
    padding:40px 20px 30px 20px;
    background-color:$col-white;
    overflow:hidden;
    @include prefix(box-shadow, 0px 7px 14px 0px rgba(0,0,0,0.2), webkit moz o ms);
    @include prefix(border-radius, 15px, webkit moz o ms);
    
    @include media-breakpoint-up(dsk) {
        padding:80px 36px 60px 36px;
    }
    
    &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        background-position:center center; background-repeat:no-repeat; background-size:160%; background-image:url('../img/horoscope-image.svg');
        z-index:1;
        
        @include media-breakpoint-up(dsk) {
            background-size:contain;
        }
    }
    > div {
        position:relative;
        text-align:center; font-size:0;
        z-index:2;
        
        i {
            display:inline-block; float:none;
            width:80px; height:80px;
            font-size:80px; line-height:80px; color:$col-black;
            
            @include media-breakpoint-up(dsk) {
                width:120px; height:120px;
                font-size:120px; line-height:120px;
            }
        }
        p {
            display:inline-block; float:none;
            margin-top:12px;
            font-size:20px; line-height:20px; color:$col-red; font-weight:800;
            
            @include media-breakpoint-up(dsk) {
                margin-top:16px;
                font-size:24px; line-height:24px;
            }
        }
        h5, .h5 {
            display:inline-block; float:none;
            margin-top:10px;
            
            @include media-breakpoint-up(dsk) {
                margin-top:12px;
            }
            &:after {
                content:none;
            }
        }
    }
    
    @include media-breakpoint-up(lg) {
        &:hover {
            > div {
                h5, .h5 {
                    color:$col-grey;
                    
                    &:after {
                        background-color:$col-grey;
                    }
                }
            }
        }
    }
}

.horoscope-quote-wrapper {
    > div {
        &:last-child {
            margin-top:30px;
            
            @include media-breakpoint-up(lg) {
                margin-top:0;
            }
        }
    }
}
.horoscope-quote {
    float:left;
    width:100%;
    margin-top:16px;
    text-align:center;
    
    @include media-breakpoint-up(dsk) {
        padding:0 80px;
    }
    @include media-breakpoint-up(lg) {
        padding:0 20px; margin-bottom:-20px;
    }
    @include media-breakpoint-up(xl) {
        padding:0 50px;
    }
    @include media-breakpoint-up(xl-1440) {
        padding:0 80px;
    }
    @include media-breakpoint-up(xxl) {
        padding:0 90px;
    }
    
    > span {
        display:inline-block; float:none;
        width:190px; height:190px;
        overflow:hidden;
        background-position:center center; background-repeat:no-repeat; background-size:cover;
        @include prefix(border-radius, 50%, webkit moz o ms);
    }
    h3, .h3 {
        position:relative;
        margin-top:30px; padding:0 40px;
        
        &:before, &:after {
            content:'';
            position:absolute;
            width:20px; height:20px;
            background-position:center center; background-repeat:no-repeat; background-size:contain;
            
            @include media-breakpoint-up(dsk) {
                width:28px; height:28px;
            }
        }
        &:before {
            left:0; top:-12px;
            background-image:url('../img/quote-left.svg');
            
            @include media-breakpoint-up(dsk) {
                top:-16px;
            }
        }
        &:after {
            right:0; bottom:-12px;
            background-image:url('../img/quote-right.svg');
            
            @include media-breakpoint-up(dsk) {
                bottom:-16px;
            }
        }
        
        @include media-breakpoint-up(dsk) {
            margin-top:35px; padding:0 60px;
        }
    }
    .author {
        display:block;
        margin-top:18px;
        font-size:13px; line-height:24px; font-weight:900; color:$col-white;

        em {
            font-style:normal; color:$col-red;
        }

        @include media-breakpoint-up(dsk) {
            margin-top:22px;
        }
    }
}