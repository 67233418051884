@import "icomoon_variables";

@font-face {
  font-family: 'icomoon';
  src:  url('#{$font-path}/icomoon.eot?j9u9y0');
  src:  url('#{$font-path}/icomoon.eot?j9u9y0#iefix') format('embedded-opentype'),
    url('#{$font-path}/icomoon.ttf?j9u9y0') format('truetype'),
    url('#{$font-path}/icomoon.woff?j9u9y0') format('woff'),
    url('#{$font-path}/icomoon.svg?j9u9y0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-dea-"], [class*=" icon-dea-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  display: inline-block;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dea-special {
  &:before {
    content: $icon-dea-special; 
  }
}
.icon-dea-world {
  &:before {
    content: $icon-dea-world; 
  }
}
.icon-dea-check-quiz {
  &:before {
    content: $icon-dea-check-quiz; 
  }
}
.icon-dea-apple {
  &:before {
    content: $icon-dea-apple; 
  }
}
.icon-dea-actress {
  &:before {
    content: $icon-dea-actress; 
  }
}
.icon-dea-valentine {
  &:before {
    content: $icon-dea-valentine; 
  }
}
.icon-dea-unlike {
  &:before {
    content: $icon-dea-unlike; 
  }
}
.icon-dea-television {
  &:before {
    content: $icon-dea-television; 
  }
}
.icon-dea-like {
  &:before {
    content: $icon-dea-like; 
  }
}
.icon-dea-horoscope {
  &:before {
    content: $icon-dea-horoscope; 
  }
}
.icon-dea-heart {
  &:before {
    content: $icon-dea-heart; 
  }
}
.icon-dea-gift {
  &:before {
    content: $icon-dea-gift; 
  }
}
.icon-dea-friends {
  &:before {
    content: $icon-dea-friends; 
  }
}
.icon-dea-dress {
  &:before {
    content: $icon-dea-dress; 
  }
}
.icon-dea-dollar {
  &:before {
    content: $icon-dea-dollar; 
  }
}
.icon-dea-cupid {
  &:before {
    content: $icon-dea-cupid; 
  }
}
.icon-dea-chilli {
  &:before {
    content: $icon-dea-chilli; 
  }
}
.icon-dea-calendar {
  &:before {
    content: $icon-dea-calendar; 
  }
}
.icon-dea-book {
  &:before {
    content: $icon-dea-book; 
  }
}
.icon-dea-firing {
  &:before {
    content: $icon-dea-firing; 
  }
}
.icon-dea-time-cooking {
  &:before {
    content: $icon-dea-time-cooking; 
  }
}
.icon-dea-category {
  &:before {
    content: $icon-dea-category; 
  }
}
.icon-dea-time-preparation {
  &:before {
    content: $icon-dea-time-preparation; 
  }
}
.icon-dea-time-total {
  &:before {
    content: $icon-dea-time-total; 
  }
}
.icon-dea-portions {
  &:before {
    content: $icon-dea-portions; 
  }
}
.icon-dea-difficulty {
  &:before {
    content: $icon-dea-difficulty; 
  }
}
.icon-dea-cooking {
  &:before {
    content: $icon-dea-cooking; 
  }
}
.icon-dea-megaphone {
  &:before {
    content: $icon-dea-megaphone; 
  }
}
.icon-dea-microphone {
  &:before {
    content: $icon-dea-microphone; 
  }
}
.icon-dea-guide {
  &:before {
    content: $icon-dea-guide; 
  }
}
.icon-dea-close {
  &:before {
    content: $icon-dea-close; 
  }
}
.icon-dea-quiz {
  &:before {
    content: $icon-dea-quiz; 
  }
}
.icon-dea-arrow-down {
  &:before {
    content: $icon-dea-arrow-down; 
  }
}
.icon-dea-arrow-left {
  &:before {
    content: $icon-dea-arrow-left; 
  }
}
.icon-dea-arrow-right {
  &:before {
    content: $icon-dea-arrow-right; 
  }
}
.icon-dea-arrow-up {
  &:before {
    content: $icon-dea-arrow-up; 
  }
}
.icon-dea-chat {
  &:before {
    content: $icon-dea-chat; 
  }
}
.icon-dea-chef {
  &:before {
    content: $icon-dea-chef; 
  }
}
.icon-dea-line-down {
  &:before {
    content: $icon-dea-line-down; 
  }
}
.icon-dea-line-left {
  &:before {
    content: $icon-dea-line-left; 
  }
}
.icon-dea-line-right {
  &:before {
    content: $icon-dea-line-right; 
  }
}
.icon-dea-line-up {
  &:before {
    content: $icon-dea-line-up; 
  }
}
.icon-dea-menu {
  &:before {
    content: $icon-dea-menu; 
  }
}
.icon-dea-photo {
  &:before {
    content: $icon-dea-photo; 
  }
}
.icon-dea-play {
  &:before {
    content: $icon-dea-play; 
  }
}
.icon-dea-rank {
  &:before {
    content: $icon-dea-rank; 
  }
}
.icon-dea-search {
  &:before {
    content: $icon-dea-search; 
  }
}
.icon-dea-social-facebook {
  &:before {
    content: $icon-dea-social-facebook; 
  }
}
.icon-dea-social-instagram {
  &:before {
    content: $icon-dea-social-instagram; 
  }
}
.icon-dea-social-pinterest {
  &:before {
    content: $icon-dea-social-pinterest; 
  }
}
.icon-dea-social-twitter {
  &:before {
    content: $icon-dea-social-twitter; 
  }
}
.icon-dea-zodiac-aquarius {
  &:before {
    content: $icon-dea-zodiac-aquarius; 
  }
}
.icon-dea-zodiac-aries {
  &:before {
    content: $icon-dea-zodiac-aries; 
  }
}
.icon-dea-zodiac-cancer {
  &:before {
    content: $icon-dea-zodiac-cancer; 
  }
}
.icon-dea-zodiac-capricorn {
  &:before {
    content: $icon-dea-zodiac-capricorn; 
  }
}
.icon-dea-zodiac-gemini {
  &:before {
    content: $icon-dea-zodiac-gemini; 
  }
}
.icon-dea-zodiac-leo {
  &:before {
    content: $icon-dea-zodiac-leo; 
  }
}
.icon-dea-zodiac-libra {
  &:before {
    content: $icon-dea-zodiac-libra; 
  }
}
.icon-dea-zodiac-pisces {
  &:before {
    content: $icon-dea-zodiac-pisces; 
  }
}
.icon-dea-zodiac-sagittarius {
  &:before {
    content: $icon-dea-zodiac-sagittarius; 
  }
}
.icon-dea-zodiac-scorpio {
  &:before {
    content: $icon-dea-zodiac-scorpio; 
  }
}
.icon-dea-zodiac-taurus {
  &:before {
    content: $icon-dea-zodiac-taurus; 
  }
}
.icon-dea-zodiac-virgo {
  &:before {
    content: $icon-dea-zodiac-virgo; 
  }
}