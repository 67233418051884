.hero-home {
    position:relative;
    padding:0;

    @include media-breakpoint-up(lg) {
        padding:0 calc((8% + 22 * ((100vw - 992px) / 448)) + 30px) 0 30px;
    }
    @include media-breakpoint-up(xl-1440) {
        padding:0 calc(22% + (80px + 22 * ((100vw - 1440px) / 480))) 0 calc(80px + 22 * ((100vw - 1440px) / 480));
    }
    @include media-breakpoint-up(xxl-1920) {
        padding:0 calc(22% + 102px) 0 102px;
    }

    .hero-slider {
        position:relative;
        /*display:none;*/ float:left;
        opacity:0;
        width:100%; height:464px;
        z-index:1;

        @include media-breakpoint-up(dsk) {
            height:auto;
        }

        &.slick-initialized {
            opacity:1;
        }
        .slick-list {
            overflow:visible;
        }

        .slick-slide {
            position:relative;

            a {
                display:block; float:left;
                width:100%;

                > div {
                    &.content {
                        position:absolute; left:0; top:0;
                        display:flex;
                        padding:30px 20px;
                        flex-direction:column; justify-content:flex-end; align-items:center;
                        width:100%; height:100%;
                        z-index:2;

                        @include media-breakpoint-up(dsk) {
                            padding:24px 70px 35px 70px;
                            justify-content:space-between; align-items:flex-start;
                        }

                        .label {
                            position:relative;
                            width:auto;
                            padding:8px 10px 14px 42px; margin-bottom:24px;
                            background-color:$col-light-grey;
                            @include prefix(border-top-left-radius, 15px, webkit moz o ms);
                            @include prefix(border-bottom-right-radius, 15px, webkit moz o ms);
                            @include prefix(box-shadow, 0px 3px 6px 0px rgba(0,0,0,0.3), webkit moz o ms);

                            img {
                                position:absolute; left:10px; top:50%;
                                width:22px; height:auto;
                                @include prefix(transform, translateY(-50%), webkit moz o ms);
                            }
                            p {
                                position:relative;
                                display:block; float:left;
                                width:auto;
                                padding-bottom:3px;
                                font-size:22px; line-height:26px; color:$col-dark-grey; font-weight:900;

                                &:before {
                                    content:'';
                                    position:absolute; left:0; bottom:0;
                                    width:100%; height:2px;
                                    background-color:$col-dark-grey;
                                }
                            }

                            @include media-breakpoint-up(dsk) {
                                padding:8px 22px 22px 60px; margin-bottom:0;

                                img {
                                    left:15px;
                                    width:35px;
                                }
                                p {
                                    padding-bottom:6px;
                                    font-size:38px; line-height:44px;
                                }
                            }
                        }

                        .title {
                            float:left;
                            width:100%;

                            @include media-breakpoint-up(xl-1440) {
                                width:80%;
                            }
                            @include media-breakpoint-up(xxl) {
                                width:66%;
                            }

                            h1, .h1, h2, .h2 {
                                position:relative;
                                padding-bottom:20px;
                                font-size:30px; line-height:40px; font-weight:900; color:$col-white; text-transform:inherit; text-align:center;

                                &:before {
                                    content:'';
                                    position:absolute; left:50%; bottom:0;
                                    width:34px; height:2px;
                                    background-color:$col-white;
                                    @include prefix(transform, translateX(-50%), webkit moz o ms);
                                }

                                @include media-breakpoint-up(dsk) {
                                    padding-bottom:44px;
                                    font-size:44px; line-height:52px; text-align:left;

                                    &:before {
                                        left:0;
                                        width:68px; height:3px;
                                        @include prefix(transform, translateX(0%), webkit moz o ms);
                                    }
                                }
                            }
                        }
                    }
                    &.image {
                        position:relative;
                        float:left;
                        width:100%; padding-bottom:464px;
                        background-position:center top; background-repeat:no-repeat; background-size:cover;
                        z-index:1;

                        @include media-breakpoint-up(dsk) {
                            padding-bottom:65%;
                        }

                        &:before {
                            content:'';
                            position:absolute; left:0; top:0;
                            width:100%; height:100%;
                            opacity:0.75;
                            background:rgb(0,0,0);
                            background:-moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                            background:-webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                            background:linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                            filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
                            z-index:1
                        }
                        &:after {
                            content:'';
                            position:absolute; left:0; top:0;
                            width:100%; height:100%;
                            background-color:$col-red50;
                            opacity:0;
                            z-index:2;
                            @include prefix(transition, all .3s ease-out, webkit moz o ms);
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    &:hover {
                        > div {
                            &.image {
                                &:after {
                                    opacity:1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .slick-arrow {
        position:absolute; top:50%;
        display:inline-block!important;
        width:54px; height:64px;
        border:0 none;
        background-color:transparent;
        @include prefix(transform, translateY(-50%), webkit moz o ms);
        z-index:2;

        /*@include media-breakpoint-up(lg) {
            display:inline-block!important;
        }*/

        &:after {
            content:'';
            position:absolute; left:0; bottom:0;
            width:100%; height:3px;
            background-color:$col-light-red;
        }
        i {
            position:absolute; top:50%;
            width:24px; height:24px;
            font-size:24px; line-height:24px; color:$col-white;
            @include prefix(transform, translateY(-50%), webkit moz o ms);
        }

        &.slick-prev {
            left:-30px;

            i {
                right:-4px;
            }

            @include media-breakpoint-up(dsk) {
                left:-15px;
            }
            @include media-breakpoint-up(lg) {
                left:0;
            }
        }
        &.slick-next {
            right:-30px;

            i {
                left:-4px;
            }

            @include media-breakpoint-up(dsk) {
                right:-15px;
            }
            @include media-breakpoint-up(lg) {
                right:0;
            }
        }
    }

    .slick-dots {
        margin:10px 0 12px 0;
        text-align:center;
        font-size:0;

        @include media-breakpoint-up(lg) {
            display:none!important;
        }

        li {
            display:inline-block; float:none;
            width:auto;
            margin:0 3px;

            button {
                display:block; float:left;
                width:3px; height:3px;
                margin:0; padding:0; border:0 none;
                text-indent:-9999px;
                background-color:$col-medium-grey;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
                @include prefix(border-radius, 50%, webkit moz o ms);
            }

            &.slick-active {
                button {
                    width:12px;
                    background-color:$col-red;
                    @include prefix(border-radius, 0%, webkit moz o ms);
                }
            }
        }
    }
}

.skin {
    .hero-home {
        padding:0!important;
    }
}

.quiz-wrapper {
    display:none; float:left;
    width:calc(100% + 36px);
    margin-left:-18px; margin-right:-18px;
    z-index:1;

    @include media-breakpoint-up(dsk) {
        width:calc(100% + 40px);
        display:block!important;
        margin-left:-20px; margin-right:-20px;
    }

    &.slick-initialized {
        display:block;
    }

    .slick-list {
        overflow:visible;
    }
    .slick-slide {
        padding:0 17px;
    }
    > div {
        @include media-breakpoint-up(dsk) {
            float:left;
            width:33.3%;
            padding:0 20px;
        }
    }

    .quiz {
        display:block; float:left;
        width:100%;
        margin-top:18px;

        @include media-breakpoint-up(dsk) {
            margin-top:35px;
        }

        a {
            display:block; float:left;
            width:100%;

            .image {
                position:relative;
                float:left;
                width:100%;
                @include prefix(box-shadow, 0px 3px 6px 0px rgba(0,0,0,0.3), webkit moz o ms);
                @include prefix(border-radius, 50%, webkit moz o ms);

                > div {
                    position:absolute; left:50%; bottom:-20px;
                    width:100%; height:calc(100% + 40px);
                    text-align:center; font-size:0;
                    @include prefix(transform, translateX(-50%), webkit moz o ms);
                    z-index:3;

                    @include media-breakpoint-up(dsk) {
                        bottom:-25px;
                        /*width:auto;*/ height:auto;
                        padding:0 16px;
                        display:flex; flex-direction:column; align-items:center;
                    }

                    p {
                        position:absolute; left:50%; top:50%;
                        width:auto;
                        padding-bottom:3px;
                        position:relative;
                        font-size:19px; line-height:22px; color:$col-white; letter-spacing:0.66px; font-weight:900;
                        z-index:2;
                        @include prefix(transform, translate(-50%,-50%), webkit moz o ms);

                        &:after {
                            content:'';
                            position:absolute; left:0; bottom:0;
                            width:100%; height:2px;
                            background-color:$col-white;
                            @include prefix(transition, all .3s ease-out, webkit moz o ms);
                        }

                        @include media-breakpoint-up(dsk) {
                            position:relative; left:0; top:0;
                            display:inline-block; float:none;
                            font-size:22px; line-height:25px;
                            @include prefix(transform, translate(0%,0%), webkit moz o ms);
                        }
                    }
                    span {
                        position:absolute; left:50%; bottom:0;
                        display:inline-block; float:none;
                        width:52px; height:52px;
                        margin-top:0;
                        background-color:$col-light-red;
                        z-index:1;
                        @include prefix(border-radius, 50%, webkit moz o ms);
                        @include prefix(transform, translateX(-50%), webkit moz o ms);

                        i {
                            position:absolute; left:50%; top:50%;
                            width:26px; height:26px;
                            font-size:26px; line-height:26px; color:$col-white;
                            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                        }
                        &:before {
                            content:'';
                            position:absolute; left:50%; bottom:0;
                            width:94px; height:21px;
                            background-position:center top; background-repeat:no-repeat; background-size:contain; background-image:url('../img/quiz-lines.svg');
                            @include prefix(transform, translateX(-50%), webkit moz o ms);
                        }

                        @include media-breakpoint-up(dsk) {
                            position:relative; left:0; bottom:0;
                            width:82px; height:82px;
                            margin-top:26px;
                            @include prefix(transform, translateX(0%), webkit moz o ms);

                            i {
                                width:46px; height:46px;
                                font-size:46px; line-height:46px;
                            }
                            &:before {
                                width:109px; height:24px;
                            }
                        }
                    }
                }
                &:before {
                    content:'';
                    position:absolute; left:0; top:0;
                    width:100%; height:100%;
                    background-color:$col-black50;
                    @include prefix(border-radius, 50%, webkit moz o ms);
                    z-index:2;

                    @include media-breakpoint-up(dsk) {
                        background-color:$col-black35;
                    }
                }
                > img {
                    position:relative;
                    z-index:1;
                    @include prefix(border-radius, 50%, webkit moz o ms);
                    @include prefix(filter, grayscale(0), webkit moz o ms);
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }

            }
            h3, .h3 {
                margin-top:36px;
                text-align:center;
                color:$col-green;

                @include media-breakpoint-up(dsk) {
                    margin-top:46px;
                }
            }

            @include media-breakpoint-up(lg) {
                &:hover {
                    .image {
                        > div {
                            p {
                                &:after {
                                    background-color:$col-red;
                                }
                            }
                        }
                        > img {
                            @include prefix(filter, grayscale(100%), webkit moz o ms);
                        }
                    }
                }
            }
        }
    }
}

.side-teaser {
    display:none; float:left;
    width:calc(100% + 16px);
    margin-left:-8px; margin-right:-8px;
    z-index:1;
    justify-content:center; flex-direction:column;

    @include media-breakpoint-up(dsk) {
        width:100%; height:100%;
        display:flex!important;
    }

    &.slick-initialized {
        display:block;
    }

    .slick-list {
        overflow:visible;
    }
    .slick-slide {
        padding:0 8px;
    }

    > div {
        @include media-breakpoint-up(dsk) {
            margin-top:35px;

            &:first-child {
                margin-top:0;
            }
        }
    }

    .teaser {
        display:flex;
        flex-direction:column!important; align-items:center;

        @include media-breakpoint-up(dsk) {
            flex-direction:row!important;
        }

        .teaser-top {
            width:100%!important;

            @include media-breakpoint-up(dsk) {
                width:52%!important;
            }
        }
        .content {
            width:100%!important;

            @include media-breakpoint-up(dsk) {
                width:48%!important;
                padding:0 0 0 10px!important;
            }
        }
    }
}

.media-gallery-slider {
    position:relative;
    display:none; float:left;
    width:100%;
    padding:0 16px;

    @include media-breakpoint-up(dsk) {
        padding:0 100px;
    }

    &.slick-initialized {
        display:block;
    }

    .slick-slide {
        text-align:center;

        .image {
            display:inline-block; float:none;
            height:auto; width:100%;

            img {
                height:auto; width:100%;
            }

            @include media-breakpoint-up(dsk) {
                height:700px; width:auto;

                img {
                    height:100%; width:auto;
                }
            }
        }
        p {
            margin-top:26px;
            font-size:14px; line-height:22px; color:$col-white; font-weight:600;

            strong {
                font-weight:800;
            }

            @include media-breakpoint-up(dsk) {
                margin-top:36px;
            }
        }
    }

    .slick-arrow {
        position:absolute; top:50%;
        display:none!important;
        width:46px; height:46px;
        border:0 none;
        background-color:transparent;
        @include prefix(transform, translateY(-50%), webkit moz o ms);
        z-index:2;

        @include media-breakpoint-up(lg) {
            display:inline-block!important;
        }

        &:after {
            content:'';
            position:absolute; left:0; bottom:0;
            width:100%; height:3px;
            background-color:$col-light-red;
        }
        i {
            position:absolute; top:50%;
            width:18px; height:18px;
            font-size:18px; line-height:18px; color:$col-white;
            @include prefix(transform, translateY(-50%), webkit moz o ms);
        }

        &.slick-prev {
            left:0;

            i {
                right:-2px;
            }
        }
        &.slick-next {
            right:0;

            i {
                left:-2px;
            }
        }
    }

    .slick-dots {
        margin:24px 0 12px 0;
        text-align:center;
        font-size:0;

        li {
            display:inline-block; float:none;
            width:auto;
            margin:0 3px;

            button {
                display:block; float:left;
                width:3px; height:3px;
                margin:0; padding:0; border:0 none;
                text-indent:-9999px;
                background-color:$col-medium-grey;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
                @include prefix(border-radius, 50%, webkit moz o ms);
            }

            &.slick-active {
                button {
                    width:12px;
                    background-color:$col-red;
                    @include prefix(border-radius, 0%, webkit moz o ms);
                }
            }
        }
    }
}
