$grid-breakpoints: (
  xs: 0,
  sm: 567px,
  sm-320: 320px,
  sm-360: 360px,
  sm-375: 375px,
  sm-414: 414px,
  dsk: 700px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xl-1400: 1400px,
  xl-1440: 1440px,
  xl-1540: 1540px,
  xxl: 1600px,
  xxl-1920: 1920px
);

$container-max-widths: (
  sm: 567px,
  sm-320: 320px,
  sm-360: 360px,
  sm-375: 375px,
  sm-414: 414px,
  dsk: 700px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xl-1400: 1400px,
  xl-1440: 1440px,
  xl-1540: 1540px,
  xxl: 1600px,
  xxl-1920: 1920px
);