/*!
Project Name: DeAbyDay
Author: Tinext
Author URI: http://www.tinext.com
*/

/* -----------------------------------------------------------*/

@import "bootstrap/bootstrap";
@import "breakpoints";
@import "colors";
@import "typhography";
@import "fonticons/icomoon";

/*--------------------------------------------------------------
# Page Layouts
--------------------------------------------------------------*/
@import "layout/general";
@import "layout/header";
@import "layout/footer";

/*--------------------------------------------------------------
# Plug-in
--------------------------------------------------------------*/
@import "plugin/slick";

/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/
@import "components/components";
@import "components/slider";
@import "components/button";
@import "components/form";
@import "components/horoscope";
@import "components/article";
@import "components/series";
@import "components/media";
@import "components/expert";
@import "components/love";