.btn {
    position:relative;
    display:inline-block; float:none;
    border:0 none; margin-bottom:8px; padding:11px 14px;
    font-size:13px; line-height:20px; font-weight:900; color:$col-white!important; text-transform:uppercase; letter-spacing:0.26px;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);
    
    @include media-breakpoint-up(dsk) {
        padding:13px 18px;
    }
    
    &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        border:2px solid $col-white;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
    }
    &:after {
        content:'';
        position:absolute; left:0; bottom:-8px;
        width:100%; height:3px;
        background-color:$col-white;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
    }
    
    @include media-breakpoint-up(lg) {
        &:hover {
            color:$col-white!important;
    
            &:before {
                border:2px solid $col-light-red;
            }
            &:after {
                background-color:$col-light-red;
            }
        }
    } 
    
    &.btn-black {
        color:$col-black!important;

        &:before {
            border:2px solid $col-black;
        }
        &:after {
            background-color:$col-black;
        }

        @include media-breakpoint-up(lg) {
            &:hover {
                color:$col-black!important;

                &:before {
                    border:2px solid $col-light-red;
                }
                &:after {
                    background-color:$col-light-red;
                }
            }
        } 
    }
}

a {
    @include media-breakpoint-up(lg) {
        &:hover {
            .btn {
                color:$col-white;

                &:before {
                    border:2px solid $col-light-red;
                }
                &:after {
                    background-color:$col-light-red;
                }
            }
        }
    }    
}