/* Prefixes */

@mixin prefix($property, $value, $prefixes: ()) {
    @each $prefix in $prefixes {
        #{'-' + $prefix + '-' + $property}: $value;
    }
    #{$property}: $value;
}

/* --- */

$font_caveat:'Caveat', cursive;
$font_raleway:'Raleway', sans-serif;
// Semi-bold: 600 | Bold: 700 | Extra-bold: 800 | Black: 900

html, body {
	font-family:$font_raleway; font-weight:600; letter-spacing:0.4px;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    display:block; float:left;
    width:100%;
    margin-bottom:0;
    font-family:$font_raleway; font-weight:600; color:$col-dark-grey;
}

h1, .h1 {
    font-size:34px; line-height:40px; text-transform:uppercase; font-weight:900; color:$col-light-red;

    @include media-breakpoint-up(dsk) {
        font-size:62px; line-height:72px;
    }
}
h2, .h2 {
    font-size:30px; line-height:40px; text-transform:uppercase; font-weight:900; color:$col-black;

    @include media-breakpoint-up(dsk) {
        font-size:40px; line-height:46px;
    }
    
    em {
        margin-right:8px;
        font-style:normal; font-family:$font_caveat; font-weight:normal; letter-spacing:-2px; text-transform:none;
    }
    strong {
        color:$col-red; font-weight:900;
    }
}
h3, .h3 {
    font-size:22px; line-height:30px; color:$col-white; font-weight:800;

    @include media-breakpoint-up(dsk) {
        font-size:24px; line-height:32px;
    }
}
h4, .h4 {
    font-size:28px; line-height:34px; font-weight:900; color:$col-white;

    @include media-breakpoint-up(dsk) {
        font-size:40px; line-height:46px;
    }
}
h5, .h5 {
    position:relative;
    width:auto;
    padding:0 2px 3px 2px;
    font-size:20px; line-height:20px; font-weight:800; color:$col-black;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);
    
    &:after {
        content:'';
        position:absolute; left:0; bottom:0;
        width:100%; height:3px;
        background-color:$col-black;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
    }

    @include media-breakpoint-up(dsk) {
        font-size:24px; line-height:24px;
    }
}
h6, .h6 {
    display:inline-block; float:none;
    width:auto;
    font-size:16px; line-height:20px; color:$col-light-red; font-weight:900; text-transform:uppercase; letter-spacing:0.76px;

    @include media-breakpoint-up(dsk) {
        display:block; float:left;
        font-size:19px; line-height:22px;
    }
}

.bkg-black, .bkg-dark, .bkg-light-green, .bkg-green {
    h2, .h2 {
        color:$col-white;
        
        strong {
            color:$col-red;
        }
    }
}
.text-center {
    h2, .h2 {        
        em {
            display:block; float:left;
            width:100%;
            margin-right:0; margin-bottom:-6px;
            font-size:28px; line-height:34px; letter-spacing:1.6px;
            
            @include media-breakpoint-up(dsk) {
                font-size:32px; line-height:40px;
            }
        }
    }
}

p {
    display:block; float:left;
    width:100%;
    margin-bottom:0;
    font-size:14px; line-height:26px; font-family:$font_raleway; font-weight:600;
    
    @include media-breakpoint-up(dsk) {
        font-size:16px; line-height:28px;
    }
    
    strong {
        font-weight:900;
    }
    a, u {
        position:relative;
        color:inherit;
        
        text-decoration:underline!important;
        text-decoration-style:solid!important;
        text-decoration-thickness:2px!important;
        text-decoration-color:$col-light-red!important;
        text-underline-offset:2px!important;
        
        /*&:after {
            content:'';
            position:absolute; left:0; bottom:-2px;
            width:100%; height:2px;
            background-color:$col-light-red;
        }*/
    }
    u {
        font-weight:900;
    }
    a {
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
        
        @include media-breakpoint-up(lg) {
            &:hover {
                color:$col-light-red;
            }
        }
    }
}
a {
    text-decoration:none!important;
    
    &.phone {
        
        @include media-breakpoint-up(lg) {
            cursor:default;
            pointer-events:none;
        }
    }
}

.text-inherit {
    text-transform:inherit!important;
}