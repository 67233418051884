.section-media {
    position:relative;
    padding-top:24px; padding-bottom:24px;
    margin-bottom:5px;
    background-color:$col-black;
    
    @include media-breakpoint-up(dsk) {
        padding-top:28px; padding-bottom:28px;
    }
    
    &.podcast {
        padding-bottom:30px!important; margin-bottom:0!important;
        
        @include media-breakpoint-up(dsk) {
            padding-bottom:40px!important;
        }
    }
    
    > div {
        float:left;
        width:100%;
    }
    .video {
        position:relative;
        float:left;
        width:100%;
        padding-bottom:56.25%;
        
        > * {
            position:absolute; left:0; top:0;
            width:100%; height:100%;
        }
    }
    
    .media-video, .media-gallery-title {
        float:left;
        width:100%;
        margin-top:20px;
        
        @include media-breakpoint-up(dsk) {
            margin-top:45px;
        }
        
        .content {
            float:left;
            width:100%;
            text-align:center;
            
            @include media-breakpoint-up(dsk) {
                width:70%;
                text-align:left;
            }
            @include media-breakpoint-up(lg) {
                width:75%;
            }
            
            .category {
                position:relative;
                display:inline-block; float:none;
                width:auto;
                padding-bottom:3px;
                font-size:19px; line-height:22px; color:$col-light-red; font-weight:900;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                &:after {
                    content:'';
                    position:absolute; left:0; bottom:0;
                    width:100%; height:2px;
                    background-color:$col-light-red;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }

                @include media-breakpoint-up(dsk) {
                    display:block; float:left;
                    font-size:22px; line-height:26px;
                }
                @include media-breakpoint-up(lg) {
                    &:hover {
                        color:$col-white;

                        &:after {
                            background-color:$col-white;
                        }
                    }
                }
            }
            
            h1, .h1 {
                position:relative;
                margin-top:15px; padding-bottom:22px;
                font-size:34px; line-height:40px; color:$col-white; text-transform:inherit;
                text-align:center;
                
                &:before {
                    content:'';
                    position:absolute; left:50%; bottom:0;
                    width:34px; height:2px;
                    background-color:$col-light-red;
                    @include prefix(transform, translateX(-50%), webkit moz o ms);
                }
                
                @include media-breakpoint-up(dsk) {
                    padding-bottom:42px;
                    font-size:40px; line-height:50px;
                    text-align:left;
                
                    &:before {
                        left:0;
                        width:50px; height:3px;
                        @include prefix(transform, translateX(0), webkit moz o ms);
                    }
                }
            }
            
            a.author, p.author {
                display:block; float:left;
                margin-top:10px;
                font-size:13px; line-height:24px; font-weight:900; color:$col-white;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                em {
                    font-style:normal; color:$col-red;
                }
                
                @include media-breakpoint-up(dsk) {
                    margin-top:14px;
                }
            }
            a.author {
                width:100%;
                
                @include media-breakpoint-up(lg) {
                    width:auto;
                    
                    &:hover {
                        color:$col-red;
                    }
                }
            }
        }
        .sponsor {
            display:flex; float:right;
            align-items:center; flex-direction:row; justify-content:flex-end;
            margin-top:30px;
            
            @include media-breakpoint-up(dsk) {
                margin-top:5px;
            }

            p {
                width:auto;
                margin-right:16px;
                font-size:11px; line-height:24px; color:$col-lightest-grey; font-weight:600; text-transform:uppercase;
            }
            a {
                display:block; float:left;
                height:50px;

                @include media-breakpoint-up(dsk) {
                    height:70px;
                }

                img {
                    height:100%; width:auto;
                }
            }
        }

        .social-list {
            margin-top:20px;
            text-align:center; font-size:0;

            @include media-breakpoint-up(dsk) {
                margin-top:44px;
            }

            li {
                @include media-breakpoint-up(lg) {                
                    &:first-child {
                        margin-left:0!important;
                    }
                }
                @include media-breakpoint-up(xl) {
                    margin-left:40px;
                }

                a {
                    width:42px; height:42px;
                    @include prefix(box-shadow, 0px 3px 6px 0px rgba(0,0,0,0.1), webkit moz o ms);

                    i {
                        width:18px; height:18px;
                        font-size:18px; line-height:18px;
                        @include prefix(text-shadow, 0px 0px 6px rgba(0,0,0,0.1), webkit moz o ms);
                    }
                    &:before {
                        @include prefix(box-shadow, inset 0px 3px 6px 0px rgba(0,0,0,0.1), webkit moz o ms);
                    }

                    @include media-breakpoint-up(dsk) {
                        width:52px; height:52px;

                        i {
                            width:24px; height:24px;
                            font-size:24px; line-height:24px;
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        &:hover {            
                            i {
                                @include prefix(text-shadow, none, webkit moz o ms);
                            }
                            &:before {
                                @include prefix(box-shadow, none, webkit moz o ms);
                            }
                        }
                    }
                }
            }
        }
    }
    
    .media-gallery {
        margin-top:20px; margin-bottom:10px;
        text-align:center;
        
        @include media-breakpoint-up(md) {
            //display:flex;
            //flex-direction:row-reverse; justify-content:space-between;
            margin-top:30px; margin-bottom:20px;
            //text-align:left;
        }
        @include media-breakpoint-up(lg) {
            margin-top:36px; margin-bottom:26px;
        }
        
        .content {
            width:100%;
            margin-top:20px;
            text-align:center;
            
            @include media-breakpoint-up(lg) {
                //width:calc(100% - 420px);
                margin-top:25px;
                text-align:left;
            }
            
            .category {
                position:relative;
                display:inline-block; float:none;
                width:auto;
                padding-bottom:3px;
                font-size:19px; line-height:22px; color:$col-light-red; font-weight:900;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                &:after {
                    content:'';
                    position:absolute; left:0; bottom:0;
                    width:100%; height:2px;
                    background-color:$col-light-red;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }

                @include media-breakpoint-up(lg) {
                    display:block; float:left;
                    font-size:22px; line-height:26px;
                }
                @include media-breakpoint-up(lg) {
                    &:hover {
                        color:$col-white;

                        &:after {
                            background-color:$col-white;
                        }
                    }
                }
            }
            
            h1, .h1 {
                position:relative;
                margin-top:15px; padding-bottom:22px;
                font-size:34px; line-height:40px; color:$col-white; text-transform:inherit;
                text-align:center;
                
                &:before {
                    content:'';
                    position:absolute; left:50%; bottom:0;
                    width:34px; height:2px;
                    background-color:$col-light-red;
                    @include prefix(transform, translateX(-50%), webkit moz o ms);
                }
                
                @include media-breakpoint-up(dsk) {
                    padding-bottom:42px;
                    font-size:40px; line-height:50px;
                
                    &:before {
                        width:50px; height:3px;
                    }
                }
                @include media-breakpoint-up(lg) {
                    text-align:left;
                
                    &:before {
                        left:0;
                        @include prefix(transform, translateX(0), webkit moz o ms);
                    }
                }
            }
            
            a.author, p.author {
                display:block; float:left;
                margin-top:10px;
                font-size:13px; line-height:24px; font-weight:900; color:$col-white;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                em {
                    font-style:normal; color:$col-red;
                }
                
                @include media-breakpoint-up(dsk) {
                    margin-top:14px;
                }
            }
            a.author {
                width:100%;
                
                @include media-breakpoint-up(lg) {
                    width:auto;
                    
                    &:hover {
                        color:$col-red;
                    }
                }
            }

            .social-list {
                margin-top:20px;
                text-align:center; font-size:0;

                @include media-breakpoint-up(dsk) {
                    margin-top:44px;
                }

                li {
                    @include media-breakpoint-up(lg) {                
                        &:first-child {
                            margin-left:0!important;
                        }
                    }
                    @include media-breakpoint-up(xl) {
                        margin-left:40px;
                    }

                    a {
                        width:42px; height:42px;
                        @include prefix(box-shadow, 0px 3px 6px 0px rgba(0,0,0,0.1), webkit moz o ms);

                        i {
                            width:18px; height:18px;
                            font-size:18px; line-height:18px;
                            @include prefix(text-shadow, 0px 0px 6px rgba(0,0,0,0.1), webkit moz o ms);
                        }
                        &:before {
                            @include prefix(box-shadow, inset 0px 3px 6px 0px rgba(0,0,0,0.1), webkit moz o ms);
                        }

                        @include media-breakpoint-up(dsk) {
                            width:52px; height:52px;

                            i {
                                width:24px; height:24px;
                                font-size:24px; line-height:24px;
                            }
                        }
                        @include media-breakpoint-up(lg) {
                            &:hover {            
                                i {
                                    @include prefix(text-shadow, none, webkit moz o ms);
                                }
                                &:before {
                                    @include prefix(box-shadow, none, webkit moz o ms);
                                }
                            }
                        }
                    }
                }
            }
        }
        .media-gallery-thumb {
            position:relative;
            display:inline-block; float:none;
            width:calc(100% + 16px); max-width:426px;
            margin-left:-8px; margin-right:-8px;
            
            @include media-breakpoint-up(lg) {
                //display:block; float:left;
                //height:364px;
                width:calc(100% + 32px); max-width:710px;
                margin-left:0; margin-right:-16px;
            }
            
            .slick-slide {
                position:relative;
                padding:0 8px;
                
                @include media-breakpoint-up(lg) {
                    padding:0 16px;
                }
                
                &:before {
                    content:none;
                    position:absolute; left:8px; top:0;
                    width:calc(100% - 16px); height:100%;
                    border:2px solid $col-light-red;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    z-index:1;

                    @include media-breakpoint-up(lg) {
                        //content:none;
                        left:16px;
                        width:calc(100% - 32px);
                    }
                }
                        
                img {
                    cursor:pointer;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }
                
                > div {
                    > div {
                        position:relative;
                        padding:16px;
                        
                        &:before {
                            content:none;
                            position:absolute; left:16px; top:16px;
                            width:calc(100% - 32px); height:calc(100% - 32px);
                            border:2px solid $col-light-red;
                            @include prefix(transition, all .3s ease-out, webkit moz o ms);
                            z-index:1;
                        }
                        a {
                            display:block; float:left;
                            width:100%;
                        }
                        
                        &.active {
                            &:before {
                                content:'';
                            }                            
                            a {
                                > img {
                                    opacity:0.3;
                                }
                            }
                        }
                    }
                }
                        
                &.active {
                    &:before {
                        content:'';
                    }                            
                    a {
                        > img {
                            opacity:0.3;
                        }
                    }
                }
            }
            
            .slick-arrow {
                position:absolute;
                width:34px; height:100%;
                border:0 none;
                background-color:transparent;
                z-index:2;
                
                /*@include media-breakpoint-up(lg) {
                    width:100%; height:34px;
                }*/

                &:after {
                    content:'';
                    position:absolute; left:0; top:0;
                    width:100%; height:100%;
                    z-index:1;
                }
                &:before {
                    content:'';
                    position:absolute;
                    width:8px; height:100%;
                    z-index:1;
                    background-color:$col-black;
                    
                    /*@include media-breakpoint-up(lg) {
                        width:100%; height:16px;
                    }*/
                }
                i {
                    position:absolute;
                    width:18px; height:18px;
                    font-size:18px; line-height:18px; color:$col-white;
                    z-index:2;
                }

                &.slick-prev {
                    left:0; top:0;
                    
                    /*@include media-breakpoint-up(lg) {
                        top:16px;
                    }*/

                    &:after {
                        background:rgb(0,0,0);
                        background:-moz-linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                        background:-webkit-linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                        background:linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                        filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
                        
                        /*@include media-breakpoint-up(lg) {
                            background:rgb(0,0,0);
                            background:-moz-linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                            background:-webkit-linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                            background:linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                            filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
                        }*/
                    }
                    &:before {
                        left:-8px; top:0;
                        
                        /*@include media-breakpoint-up(lg) {
                            left:0; top:-16px;
                        }*/
                    }
                    i {
                        top:50%; left:0;
                        @include prefix(transform, translateY(-50%), webkit moz o ms);
                        
                        /*@include media-breakpoint-up(lg) {
                            top:0; left:50%;
                            @include prefix(transform, translateX(-50%), webkit moz o ms);
                        }*/
                    }
                }
                &.slick-next {
                    right:0; bottom:0;
                    
                    /*@include media-breakpoint-up(lg) {
                        bottom:16px;
                    }*/

                    &:after {
                        background:rgb(0,0,0);
                        background:-moz-linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                        background:-webkit-linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                        background:linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                        filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
                        
                        /*@include media-breakpoint-up(lg) {
                            background:rgb(0,0,0);
                            background:-moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                            background:-webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                            background:linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                            filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
                        }*/
                    }
                    &:before {
                        right:-8px; bottom:0;
                        
                        /*@include media-breakpoint-up(lg) {
                            right:0; bottom:-16px;
                        }*/
                    }
                    i {
                        bottom:50%; right:0;
                        @include prefix(transform, translateY(50%), webkit moz o ms);
                        
                        /*@include media-breakpoint-up(lg) {
                            bottom:0; right:50%;
                            @include prefix(transform, translateX(50%), webkit moz o ms);
                        }*/
                    }
                }
                
                &.slick-disabled {
                    display:none!important;
                }
            }
        }
    }
}

.media-gallery-image {
    position:relative;
    float:left;
    width:100%;
    padding:0 16px; margin-top:35px;
    
    @include media-breakpoint-up(dsk) {
        margin-top:45px;
    }
    
    .media-gallery-pagination {
        position:absolute; right:30px; top:10px;
        display:none;
        width:46px;
        text-align:center;
        z-index:2;
        
        @include media-breakpoint-up(dsk) {
            display:block;
        }
        
        > span {
            position:relative;
            display:block; float:left;
            width:46px; height:46px;
            background-color:$col-red;
            @include prefix(border-radius, 50%, webkit moz o ms);
            flex:none;

            i {
                position:absolute; left:50%; top:50%;
                width:24px; height:24px;
                font-size:24px; line-height:24px; color:$col-white;
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            }
        }
        p {
            width:100%;
            margin-top:12px; padding:0;
            font-size:14px; line-height:16px; color:$col-white; text-align:center;
        }
    }
    > div {
        position:relative;
        float:left;
        width:calc(100% + 32px);
        margin-left:-16px; margin-right:-16px;
        text-align:center;
        z-index:1;
        
        @include media-breakpoint-up(dsk) {
            width:100%;
            margin-left:0; margin-right:0;
        }
        
        .image {
            display:inline-block; float:none;
            height:auto; width:100%;
            
            img {
                height:auto; width:100%; max-height:600px;
                object-fit:contain;
            }
            
            /*@include media-breakpoint-up(dsk) {
                img {
                    width:500px;
                }
            }
            @include media-breakpoint-up(lg) {
                img {
                    width:585px;
                }
            }*/
        }
        p {
            display:inline-block; float:none;
            margin-top:26px; padding:0 16px;
            font-size:14px; line-height:22px; color:$col-white; font-weight:600;
            
            strong {
                font-weight:800;
            }
            
            @include media-breakpoint-up(dsk) {
                max-width:500px;
                margin-top:36px;
            }
            @include media-breakpoint-up(lg) {
                max-width:586px;
            }
        }
    }
    
    .slick-arrow {
        position:absolute;
        width:46px; height:46px;
        border:0 none;
        background-color:transparent;
        @include prefix(transform, translateY(-50%), webkit moz o ms);
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
        z-index:2;
        
        &:before {
            content:'';
            position:absolute; left:0; top:0;
            width:100%; height:100%;
            background-color:$col-black50;
            z-index:1;
        }
        &:after {
            content:'';
            position:absolute; bottom:0;
            width:70%; height:3px;
            background-color:$col-light-red;
            z-index:2;
            
            @include media-breakpoint-up(dsk) {
                width:100%;
            }
        }
        i {
            position:absolute; top:50%;
            width:14px; height:14px;
            font-size:14px; line-height:14px; color:$col-white;
            @include prefix(transform, translateY(-50%), webkit moz o ms);
            z-index:3;
            
            @include media-breakpoint-up(dsk) {
                width:18px; height:18px;
                font-size:18px; line-height:18px;
            }
        }
        
        &.slick-prev {
            left:0;
            
            &:after {
                left:0;
            }
            i {
                right:12px;
                
                @include media-breakpoint-up(dsk) {
                    right:-2px;
                }
            }
        }
        &.slick-next {
            right:0;
            
            &:after {
                right:0;
            }
            i {
                left:12px;
                
                @include media-breakpoint-up(dsk) {
                    left:-2px;
                }
            }
        }
    }
    
    .slick-dots {
        margin:24px 0 12px 0;
        text-align:center;
        font-size:0;
        
        li {
            display:inline-block; float:none;
            width:auto;
            margin:0 3px;
            
            button {
                display:block; float:left;
                width:3px; height:3px;
                margin:0; padding:0; border:0 none;
                text-indent:-9999px;
                background-color:$col-medium-grey;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
                @include prefix(border-radius, 50%, webkit moz o ms);
            }
            
            &.slick-active {            
                button {
                    width:12px;
                    background-color:$col-red;
                    @include prefix(border-radius, 0%, webkit moz o ms);
                }
            }
        }
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .media-gallery-image {
        > div {        
            .image {            
                img {
                    max-height:none;
                    
                    @include media-breakpoint-up(dsk) {
                        max-width:70%;
                    }
                }
            }
        }
    }
}

.podcast-wrapper {
    float:left;
    width:100%;
    text-align:center;
    
    /*> a {
        display:inline-block; float:none;
        width:auto;
        font-size:16px; line-height:20px; color:$col-light-red; font-weight:900; text-transform:uppercase; letter-spacing:0.76px;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
        
        @include media-breakpoint-up(dsk) {
            display:block; float:left;
            font-size:19px; line-height:22px;
        }
        @include media-breakpoint-up(lg) {
            &:hover {
                color:$col-white;
            }
        }
    }*/
    .line-title {
        margin-top:15px;
        text-align:center;
        
        @include media-breakpoint-up(dsk) {
            margin-top:18px;
        }
        
        h4, .h4 {
            display:inline-block; float:none;
            padding:0 22px;
            font-size:24px; line-height:32px;

            @include media-breakpoint-up(dsk) {
                font-size:30px; line-height:38px;
            }
        }
    }
    
    .podcast-list {
        float:left;
        width:100%;
        margin-top:6px;
        text-align:left;
        
        li {
            margin-top:15px;
            
            @include media-breakpoint-up(dsk) {
                margin-top:20px;
            }
            
            a {
                display:block; float:left;
                width:100%;
                padding:10px 12px;
                background-color:$col-white;
                @include prefix(border-radius, 8px, webkit moz o ms);
                
                .cover {
                    float:left;
                    width:100%;
                    padding-bottom:150px;
                    background-position:center top; background-repeat:no-repeat; background-size:cover;
                    @include prefix(border-radius, 8px, webkit moz o ms);
                    @include prefix(filter, grayscale(0), webkit moz o ms);
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    
                    @include media-breakpoint-up(dsk) {
                        width:215px;
                        padding-bottom:100px;
                    }
                }
                .content {
                    display:flex; float:left;
                    width:100%;
                    padding:16px 0 2px 0;
                    flex-direction:column; justify-content:space-between; align-items:center;
                    
                    @include media-breakpoint-up(dsk) {
                        width:calc(100% - 215px); height:100px;
                        flex-direction:row;
                        padding:0 14px 0 40px;
                    }
                    
                    h3, .h3 {
                        width:100%;
                        font-size:18px; line-height:24px; color:$col-black; font-weight:800;
                        @include prefix(transition, all .3s ease-out, webkit moz o ms);
                        
                        @include media-breakpoint-up(dsk) {
                            width:70%;
                            font-size:19px; line-height:25px;
                        }
                    }
                    > div {
                        display:flex;
                        width:auto;
                        margin-top:14px;
                        flex-direction:row; justify-content:flex-end; align-items:center; align-self:flex-end;
                        
                        @include media-breakpoint-up(dsk) {
                            margin-top:0;
                            align-self:center;
                        }
                        
                        p {
                            font-size:12px; line-height:24px; color:$col-red; font-weight:900;
                        }
                        span {
                            position:relative;
                            width:46px; height:46px;
                            margin-left:10px;
                            background-color:$col-red;
                            @include prefix(border-radius, 50%, webkit moz o ms);
                            flex:none;

                            i {
                                position:absolute; left:50%; top:50%;
                                width:24px; height:24px;
                                font-size:24px; line-height:24px; color:$col-white;
                                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                            }
                        }
                    }
                }
                
                @include media-breakpoint-up(lg) {
                    &:hover {
                        .cover {
                            @include prefix(filter, grayscale(100%), webkit moz o ms);
                        }
                        .content {
                            h3, .h3 {
                                color:$col-grey;
                            }
                        }
                    }
                }
            }
        }
    }
}