/* Colors variables */

$col-black:#000000;
$col-dark:$col-black;
$col-black10:rgba($col-black, .10);
$col-black15:rgba($col-black, .15);
$col-black25:rgba($col-black, .25);
$col-black30:rgba($col-black, .30);
$col-black35:rgba($col-black, .35);
$col-black40:rgba($col-black, .40);
$col-black50:rgba($col-black, .50);
$col-white:#FFFFFF;
$col-white20:rgba($col-white, .20);
$col-white35:rgba($col-white, .35);
$col-white60:rgba($col-white, .60);

$col-red:#F32735;
$col-red50:rgba($col-red, .50);
$col-light-red:#FF5959;
$col-light-red10:rgba($col-light-red, .10);
$col-light-red20:rgba($col-light-red, .20);
$col-light-red50:rgba($col-light-red, .50);
$col-band-red:#FFEEEE;

$col-green:#00626D;
$col-green60:rgba($col-green, .60);
$col-light-green:#00A19B;
$col-light-green20:rgba($col-light-green, .20);
$col-lightest-green:#CBECEB;

$col-dark-grey:#161615;
$col-grey:#5C6670;
$col-grey20:rgba($col-grey, .20);
$col-medium-grey:#8B8B8B;
$col-light-grey:#E0DFDD;
$col-lightest-grey:#F5F5F5;
$col-scroll-grey:#404040;
$col-note-grey:#707070;
$col-quiz-grey:#CBCBCB;

$col-blue:#1976D2;
$col-azure:#00ACEE;
$col-violet:#833AB4;
$col-purple:#E60023;

/* - Text color class */

.txt-black { color:$col-black!important }
.txt-white { color:$col-white!important }

.txt-red { color:$col-red!important }
.txt-light-red { color:$col-light-red!important }

.txt-green { color:$col-green!important }
.txt-light-green { color:$col-light-green!important }
.txt-lightest-green { color:$col-light-green!important }

.txt-dark-grey { color:$col-dark-grey!important }
.txt-light-grey { color:$col-light-grey!important }

.txt-blue { color:$col-blue!important }
.txt-azure { color:$col-azure!important }
.txt-violet { color:$col-violet!important }
.txt-purple { color:$col-purple!important }

/* - Background color class */

.bkg-black { background-color:$col-black!important }
.bkg-dark { background-color:$col-black!important }
.bkg-white { background-color:$col-white!important }

.bkg-red { background-color:$col-red!important }
.bkg-light-red { background-color:$col-light-red!important }
.bkg-light-red10 { background-color:$col-light-red10!important }
.bkg-band-red { background-color:$col-band-red!important }

.bkg-green { background-color:$col-green!important }
.bkg-light-green { background-color:$col-light-green!important }
.bkg-lightest-green { background-color:$col-light-green!important }

.bkg-dark-grey { background-color:$col-dark-grey!important }
.bkg-light-grey { background-color:$col-light-grey!important }
.bkg-lightest-grey { background-color:$col-lightest-grey!important }

.bkg-blue { background-color:$col-blue!important }
.bkg-azure { background-color:$col-azure!important }
.bkg-violet { background-color:$col-violet!important }
.bkg-purple { background-color:$col-purple!important }

/* Section colors function */

@mixin section_color($color-class, $section-color) {
            
    section.bkg-#{$color-class}{
        .line-title {
            h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
                background-color:$section-color!important;
            }
        }
        .section-title {
            > div {
                h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
                    background-color:$section-color!important;
                }
            }
        }
        
        &.newsletter-section {
            &:before {
                background-color:$section-color!important;
            }            
            .newsletter {
                &:before {
                    background-color:$section-color!important;
                }
                &:after {
                    background-color:$section-color!important;
                }
            }
        }
    }
    
    .social-list {
        li {
            a.social-#{$color-class} {   
                &:before {
                    border:2px solid $section-color!important;
                }
                i {
                    color:$section-color!important;
                }
                
                @include media-breakpoint-up(lg) {
                    &:hover { 
                        background-color:$section-color!important;
                        
                        i {
                            color:$col-white!important;
                        }
                    }
                }
            }
        }
    }
}

@include section_color(black, $col-black);
@include section_color(dark, $col-black);
@include section_color(white, $col-white);
@include section_color(red, $col-red);
@include section_color(light-red, $col-light-red);
@include section_color(band-red, $col-band-red);
@include section_color(green, $col-green);
@include section_color(light-green, $col-light-green);
@include section_color(lightest-green, $col-lightest-green);
@include section_color(dark-grey, $col-dark-grey);
@include section_color(light-grey, $col-light-grey);
@include section_color(lightest-grey, $col-lightest-grey);
@include section_color(blue, $col-blue);
@include section_color(azure, $col-azure);
@include section_color(violet, $col-violet);
@include section_color(purple, $col-purple);