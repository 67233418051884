.line-title {
    position:relative;
    float:left;
    width:100%;

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        position:relative;
        width:auto;
        z-index:2;
        background-color:$col-white;

        @include media-breakpoint-up(dsk) {
            padding-right:30px;
        }
    }

    &:before {
        content:none;
        position:absolute; left:50%; bottom:11px;
        width:100%; height:3px;
        background-position:top left; background-repeat:repeat-x; background-size:6px 3px;
        z-index:1;
        @include prefix(transform, translateX(-50%), webkit moz o ms);

        @include media-breakpoint-up(dsk) {
            content:''!important;
        }
    }

    &.m-line {
        &:before {
            content:''!important;
        }
    }

    &.red {
        &:before {
            background-image:url('../img/dots-line-red.svg');
        }
    }
    &.white {
        &:before {
            background-image:url('../img/dots-line-white.svg');
        }
    }
    &.black {
        &:before {
            background-image:url('../img/dots-line-black.svg');
        }
    }

    &.tips {
        position:relative;
        margin-bottom:30px;

        em {
            position:absolute; right:-20px; bottom:-24px;
        }
        &:before {
            content:''!important;
        }

        @include media-breakpoint-up(dsk) {
            margin-bottom:36px;

            em {
                right:-26px; bottom:-30px;
            }
        }
    }
}

.text-center {
    .line-title {
        display:flex;
        justify-content:center;

        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
            position:relative;
            display:flex;
            flex-direction:column;
            width:auto;
            padding-right:22px; padding-left:22px;
            z-index:2;

            em {
                width:auto;
            }
        }
    }
}

.sponsor-title {
    display:flex;
    align-self:flex-end; align-items:center;
    flex-direction:row; justify-content:flex-end;
    margin-top:12px;

    p {
        width:auto;
        margin-right:8px;
        font-size:11px; line-height:24px; color:$col-white; font-weight:800;

        @include media-breakpoint-up(dsk) {
            font-size:12px;
        }

        em {
            font-style:normal; color:$col-red;
        }
    }
    a {
        display:block; float:left;
        height:43px;

        @include media-breakpoint-up(dsk) {
            height:60px;
        }

        img {
            height:100%; width:auto;
        }
    }
}

.social-title {
    text-align:center;

    @include media-breakpoint-up(lg) {
        text-align:left;
    }

    p {
        font-size:15px; line-height:20px; color:$col-dark-grey; text-transform:uppercase;

        @include media-breakpoint-up(dsk) {
            font-size:17px;
        }
    }
    h2, .h2 {
        margin-top:5px; padding:0;
        color:$col-dark-grey;

        &:after {
            content:none;
        }

        em {
            margin:0 0 0 -6px;
            font-size:32px;
        }

        @include media-breakpoint-up(dsk) {
            margin-top:10px;
        }
    }
}

.bottom-band-big {
    .teaser-wrapper {
        margin-top:15px;

        @include media-breakpoint-up(dsk) {
            margin-top:25px;
        }
    }
}
.teaser-wrapper-article {
    float:left;
    width:100%;

    .teaser-wrapper {
        margin-top:0;
    }
}
.teaser-wrapper {
    margin-top:0;
    justify-content:center;

    @include media-breakpoint-up(dsk) {
        margin-top:25px;
    }

    .teaser {
        display:flex; float:left;
        width:100%;
        margin-top:15px;
        cursor:pointer;
        flex-direction:row; align-items:center;

        @include media-breakpoint-up(dsk) {
            margin-top:35px;
            flex-direction:column;

            &:first-child {
                margin-top:0;
            }
        }

        .teaser-top {
            position:relative;
            float:left;
            width:48%;
            overflow:hidden;
            @include prefix(border-radius, 8px, webkit moz o ms);

            @include media-breakpoint-up(dsk) {
                width:100%;
            }

            a {
                position:relative;
                display:block; float:left;
                width:100%;
                text-decoration:none;
            }

            > .content {
                position:absolute; left:12px; bottom:24px;
                display:flex;
                flex-direction:column;
                width:calc(100% - 24px);
                z-index:3;

                @include media-breakpoint-up(dsk) {
                    left:20px; bottom:38px;
                    width:calc(100% - 40px);
                }

                .intro {
                    display:flex; float:left;
                    width:100%;
                    flex-direction:row; align-items:flex-end; justify-content:space-between;

                    .category {
                        position:relative;
                        width:auto;
                        padding-bottom:3px;
                        font-size:19px; line-height:22px; color:$col-light-red; font-weight:900;
                        @include prefix(transition, all .3s ease-out, webkit moz o ms);

                        &:after {
                            content:'';
                            position:absolute; left:0; bottom:0;
                            width:100%; height:2px;
                            background-color:$col-light-red;
                            @include prefix(transition, all .3s ease-out, webkit moz o ms);
                        }

                        @include media-breakpoint-up(dsk) {
                            font-size:22px; line-height:26px;
                        }
                        @include media-breakpoint-up(lg) {
                            &:hover {
                                color:$col-white;

                                &:after {
                                    background-color:$col-white;
                                }
                            }
                        }

                        &.txt-green {
                            color:$col-green!important;

                            &:after {
                                background-color:$col-green!important;
                            }

                            @include media-breakpoint-up(lg) {
                                &:hover {
                                    color:$col-light-green!important;

                                    &:after {
                                        background-color:$col-light-green!important;
                                    }
                                }
                            }
                        }
                    }
                    .icon {
                        position:relative;
                        float:right;
                        width:48px; height:48px;
                        background-color:$col-red;
                        @include prefix(border-radius, 50%, webkit moz o ms);

                        i {
                            position:absolute; left:50%; top:50%;
                            width:26px; height:26px;
                            font-size:26px; line-height:26px; color:$col-white;
                            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                        }

                        @include media-breakpoint-up(dsk) {
                            width:46px; height:46px;

                            i {
                                width:24px; height:24px;
                                font-size:24px; line-height:24px;
                            }
                        }
                    }
                }

                h3, .h3 {
                    margin-top:12px;
                }
                p {
                    display:none;
                    margin-top:6px;
                    font-size:15px; line-height:24px; color:$col-white;

                    @include media-breakpoint-up(dsk) {
                        display:block;
                    }

                    &.author {
                        display:block;
                        margin-top:10px;
                        font-size:14px; font-weight:900;

                        em {
                            font-style:normal; color:$col-red;
                        }

                        @include media-breakpoint-up(dsk) {
                            font-size:13px;
                        }
                    }
                }
            }
            > .icon {
                position:absolute; right:10px; bottom:10px;
                width:48px; height:48px;
                background-color:$col-red;
                @include prefix(border-radius, 50%, webkit moz o ms);
                z-index:2;

                i {
                    position:absolute; left:50%; top:50%;
                    width:26px; height:26px;
                    font-size:26px; line-height:26px; color:$col-white;
                    @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                }

                @include media-breakpoint-up(dsk) {
                    right:12px; bottom:12px;
                    width:46px; height:46px;

                    i {
                        width:24px; height:24px;
                        font-size:24px; line-height:24px;
                    }
                }
            }
            > .image {
                position:relative;
                display:block; float:left;
                width:100%;
                background-position:center top; background-repeat:no-repeat; background-size:cover;
                @include prefix(filter, grayscale(0), webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
                z-index:1;

                &:before {
                    content:'';
                    position:absolute; left:0; top:0;
                    width:100%; height:100%;
                    background-color:$col-black10;
                }
            }

            &.video {
                > .icon {
                    left:50%; top:50%;
                    @include prefix(transform, translate(-50%,-50%), webkit moz o ms);

                    i {
                        width:16px; height:16px;
                        font-size:16px; line-height:16px;
                        margin-left:2px;
                    }

                    @include media-breakpoint-up(dsk) {
                        i {
                            width:22px; height:22px;
                            font-size:22px; line-height:22px;
                        }
                    }
                }
            }
        }

        > .content {
            position:relative;
            float:left;
            width:52%;
            padding-left:15px;

            @include media-breakpoint-up(dsk) {
                width:100%;
                margin-top:10px; padding-left:0;
            }

            .intro {
                display:flex; float:left;
                width:100%;
                flex-direction:row; align-items:flex-end; justify-content:space-between;

                .category {
                    position:relative;
                    width:auto;
                    padding-bottom:2px;
                    font-size:15px; line-height:18px; color:$col-red; font-weight:900; text-decoration:none;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);

                    &:after {
                        content:'';
                        position:absolute; left:0; bottom:0;
                        width:100%; height:2px;
                        background-color:$col-red;
                        @include prefix(transition, all .3s ease-out, webkit moz o ms);
                    }

                    @include media-breakpoint-up(lg) {
                        &:hover {
                            color:$col-light-red;

                            &:after {
                                background-color:$col-light-red;
                            }
                        }
                    }

                    &.txt-green {
                        color:$col-green!important;

                        &:after {
                            background-color:$col-green!important;
                        }

                        @include media-breakpoint-up(lg) {
                            &:hover {
                                color:$col-light-green!important;

                                &:after {
                                    background-color:$col-light-green!important;
                                }
                            }
                        }
                    }
                }
                .icon {
                    position:relative;
                    float:right;
                    width:48px; height:48px;
                    background-color:$col-red;
                    @include prefix(border-radius, 50%, webkit moz o ms);

                    i {
                        position:absolute; left:50%; top:50%;
                        width:26px; height:26px;
                        font-size:26px; line-height:26px; color:$col-white;
                        @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                    }

                    @include media-breakpoint-up(dsk) {
                        width:46px; height:46px;

                        i {
                            width:24px; height:24px;
                            font-size:24px; line-height:24px;
                        }
                    }
                }
            }

            h3, .h3 {
                margin-top:13px;
                font-size:16px; line-height:22px; color:$col-black;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                &.big {
                    font-size:24px; line-height:32px;
                }

                @include media-breakpoint-up(dsk) {
                    font-size:18px; line-height:23px;
                }
            }
            p {
                margin-top:6px;
                font-size:15px; line-height:24px; color:$col-black;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                &.author {
                    margin-top:10px;
                    font-size:13px; font-weight:900;

                    em {
                        font-style:normal; color:$col-red;
                    }
                }
            }
            .sponsor {
                float:left;
                width:100%;
                margin-top:4px;

                p {
                    width:100%;
                    font-size:12px; line-height:24px; color:$col-black; font-weight:800;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);

                    em {
                        font-style:normal; color:$col-red;
                    }
                }
                a {
                    display:block; float:left;
                    width:50px;
                    margin-top:4px;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            &:hover {
                .teaser-top {
                    > .image {
                        @include prefix(filter, grayscale(100%), webkit moz o ms);
                    }
                }
                > .content {
                    h3, .h3 {
                        color:$col-grey!important;
                    }
                    p {
                        color:$col-grey!important;

                        &.author {
                            em {
                                color:$col-red!important;
                            }
                        }
                    }
                    .sponsor {
                        float:left;
                        width:100%;

                        p {
                            color:$col-grey;

                            em {
                                color:$col-red;
                            }
                        }
                    }
                }
            }
        }

        &.gradient {
            .teaser-top {
                > a.image {
                    &:before {
                        content:'';
                        position:absolute; left:0; top:0;
                        width:100%; height:100%;
                        opacity:0.75;
                        background:rgb(0,0,0);
                        background:-moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                        background:-webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                        background:linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
                        filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
                        z-index:2;
                    }
                }
            }
        }

        &.large {
            .teaser-top {
                @include prefix(border-radius, 15px, webkit moz o ms);
            }
        }

        &.vertical {
            .teaser-top {
                > .image {
                    padding-bottom:136px;

                    @include media-breakpoint-up(dsk) {
                        padding-bottom:122%;
                    }
                    @include media-breakpoint-up(lg) {
                        padding-bottom:300px;
                    }
                }
            }
            &.large {
                flex-direction:column;

                .teaser-top {
                    width:100%;

                    > .image {
                        padding-bottom:450px;

                        @include media-breakpoint-up(dsk) {
                            padding-bottom:180%;
                        }
                        @include media-breakpoint-up(lg) {
                            padding-bottom:752px;
                        }
                    }
                }
            }
        }
        &.horizontal {
            .teaser-top {
                > .image {
                    padding-bottom:136px;

                    @include media-breakpoint-up(dsk) {
                        padding-bottom:62%;
                    }
                    @include media-breakpoint-up(lg) {
                        padding-bottom:150px;
                    }
                }
            }
            &.large {
                .teaser-top {
                    > .image {
                        padding-bottom:136px;

                        @include media-breakpoint-up(dsk) {
                            padding-bottom:70%;
                        }
                        @include media-breakpoint-up(lg) {
                            padding-bottom:300px;
                        }
                    }
                }
            }
        }
        &.square {
            .teaser-top {
                > .image {
                    padding-bottom:138%;

                    @include media-breakpoint-up(dsk) {
                        padding-bottom:105%;
                    }
                }
            }
            &.large {
                flex-direction:column;

                .teaser-top {
                    width:100%;

                    > .content {
                        > a {
                            @include media-breakpoint-up(dsk) {
                                padding-right:28%;
                            }
                        }
                    }
                    > .image {
                        padding-bottom:325px;

                        @include media-breakpoint-up(dsk) {
                            padding-bottom:435px;
                        }
                    }
                }
            }

            &.full {
                margin-bottom:15px;
                flex-direction:column;

                @include media-breakpoint-up(dsk) {
                    margin-bottom:0;
                }

                .teaser-top {
                    width:100%;

                    > .image {
                        padding-bottom:105%;
                    }
                }
                > .content {
                    position:relative;
                    float:left;
                    width:100%;
                    margin-top:10px; padding-left:0;
                }
            }
        }
        &.square-small {
            .teaser-top {
                > .image {
                    padding-bottom:108%;

                    @include media-breakpoint-up(dsk) {
                        padding-bottom:103%;
                    }
                }
            }

            &.large {
                margin-bottom:15px;
                flex-direction:column;

                @include media-breakpoint-up(dsk) {
                    margin-bottom:0;
                }

                .teaser-top {
                    width:100%;

                    > .image {
                        padding-bottom:250px;

                        @include media-breakpoint-up(dsk) {
                            padding-bottom:350px;
                        }
                    }
                }
                > .content {
                    position:relative;
                    float:left;
                    width:100%;
                    margin-top:10px; padding-left:0;
                }
            }
        }
        &.vertical-video {
            flex-direction:column;

            .teaser-top {
                width:100%;

                > .image {
                    padding-bottom:120%;
                }
            }
        }
        &.square-video {
            flex-direction:column;

            .teaser-top {
                width:100%;

                > .image {
                    padding-bottom:92%;
                }
            }
            > .content {
                position:relative;
                float:left;
                width:100%;
                margin-top:10px; padding-left:0;
            }
        }
        &.special {
            .teaser-top {
                > .image {
                    padding-bottom:152%;

                    @include media-breakpoint-up(dsk) {
                        padding-bottom:114%;
                    }
                }
            }
            .content {
                h3, .h3 {
                    margin-top:12px;
                    font-size:20px; line-height:28px; color:$col-black; font-weight:800;
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);

                    @include media-breakpoint-up(dsk) {
                        margin-top:14px;
                        font-size:24px; line-height:32px;
                    }
                }
            }
        }
    }

    &.mb {
        margin-bottom:28px;
    }

    &.mt-mobile {
        > .col-lg-5, > .col-lg-3 {
            @include media-breakpoint-up(dsk) {
                margin-top:-35px;
            }
        }
        .teaser {
            margin-top:20px;

            @include media-breakpoint-up(dsk) {
                margin-top:35px;
            }
        }
    }

    &.multiline {
        margin-top:-30px;

        @include media-breakpoint-up(dsk) {
            margin-top:-45px;

            .teaser {
                &:first-child {
                    margin-top:45px;
                }
            }
        }
    }

    &.teaser-slider {
        display:none;
        width:calc(100% + 16px);
        margin-left:-8px; margin-right:-8px;

        @include media-breakpoint-up(dsk) {
            width:100%;
            margin-left:0; margin-right:0;
        }

        &.slick-initialized {
            display:block;
        }
        .slick-list {
            overflow:visible;
        }

        .slick-slide {
            padding:0 8px;

            .teaser {
                flex-direction:column;

                .teaser-top {
                    width:100%;
                }
                .content {
                    width:100%;
                    padding:0;
                    margin-top:10px;
                }
            }
        }

        @include media-breakpoint-up(dsk) {
            display:flex!important; flex-direction:row;
            width:calc(100% + 36px);
            margin-left:-18px; margin-right:-18px;

            > div {
                display:flex;
                //float:left;
                width:33.3%;
                padding:0 18px;
            }
        }
    }
}

.category {
    &.txt-light-red {
        color:$col-light-red!important;

        &:after {
            background-color:$col-light-red!important;
        }

        @include media-breakpoint-up(lg) {
            &:hover {
                color:$col-white!important;

                &:after {
                    background-color:$col-white!important;
                }
            }
        }
    }
}

.quote-wrapper {
    text-align:center;

    .quote {
        position:relative;
        float:left;
        width:100%;
        margin-top:52px; padding:0;

        @include media-breakpoint-up(dsk) {
            margin-top:60px; adding:0 10px;
        }

        &:before, &:after {
            content:'';
            position:absolute;
            width:37px; height:31px;
            background-position:center top; background-repeat:no-repeat; background-size:contain;

            @include media-breakpoint-up(dsk) {
                width:49px; height:41px;
            }
        }
        &:before {
            left:0; top:-36px;
            background-image:url('../img/quote-left.svg');

            @include media-breakpoint-up(dsk) {
                left:-70px; top:-20px;
            }
        }
        &:after {
            right:0; bottom:8px;
            background-image:url('../img/quote-right.svg');

            @include media-breakpoint-up(dsk) {
                right:-70px; bottom:0;
            }
        }

        p {
            position:relative;
            padding:0 6px 22px 6px;
            font-size:24px; line-height:32px; color:$col-light-red; letter-spacing:1.08px; text-align:center; text-transform:uppercase; font-weight:900;

            &:after {
                content:'';
                position:absolute; left:50%; bottom:0;
                width:34px; height:2px;
                background-color:$col-white;
                @include prefix(transform, translateX(-50%), webkit moz o ms);
            }

            &.text {
                padding-bottom:0; margin-top:32px; margin-bottom:10px;
                font-size:18px; line-height:30px; color:$col-white; letter-spacing:0.4px; text-transform:inherit; font-weight:600;

                &:after {
                    content:none;
                }
            }
            &.signature {
                padding:0; margin-top:16px; padding-right:54px;
                font-size:20px; line-height:35px; color:$col-white; letter-spacing:0.4px; text-align:right; text-transform:inherit; font-weight:normal; font-family:$font_caveat;

                &:after {
                    content:none;
                }
            }

            @include media-breakpoint-up(dsk) {
                padding:0 20px 26px 20px;
                font-size:36px; line-height:45px;

                &:after {
                    width:50px; height:3px;
                }

                &.text {
                    margin-top:40px; margin-bottom:28px;
                }
                &.signature {
                    margin-top:10px; padding-right:0;
                    font-size:28px;
                }
            }
        }
    }
    .btn {
        margin-top:20px;

        @include media-breakpoint-up(dsk) {
            margin-top:12px;
        }
    }

    &.left {
        p:not(.signature) {
            padding-left:28px; padding-right:0;
            text-align:left;

            &:after {
                left:28px;
                @include prefix(transform, translate(0%), webkit moz o ms);
            }

            @include media-breakpoint-up(dsk) {
                padding-left:0;

                &:after {
                    left:0;
                }
            }
        }

        .quote {
            @include media-breakpoint-up(dsk) {
                width:calc(100% - 80px);
                margin-left:80px;
            }
        }
    }
}

.newsletter-section {
    .newsletter {
        position:relative;
        float:left;
        width:100%;
        padding:65px 100px 135px 0;
        text-align:left; font-size:0;

        @include media-breakpoint-up(lg) {
            padding:80px 0 135px 0;
            text-align:center;
        }

        > * {
            position:relative;
            z-index:3;
        }

        &:before {
            content:'';
            position:absolute; left:0; top:0;
            width:60%; height:100%;

            @include media-breakpoint-up(dsk) {
                width:80%;
            }
        }
        &:after {
            content:'';
            position:absolute; right:0; top:0;
            width:80%; height:100%;
            @include prefix(border-radius, 100%, webkit moz o ms);

            @include media-breakpoint-up(dsk) {
                width:40%;
            }
        }

        p {
            font-size:14px; line-height:20px; color:$col-white; text-transform:uppercase;

            @include media-breakpoint-up(lg) {
                font-size:17px;
            }
        }
        h4, .h4 {
            margin-top:17px;

            @include media-breakpoint-up(lg) {
                margin-top:12px;
            }
        }
        div {
            position:relative;
            display:inline-block; float:none;
            width:100%;
            margin-top:28px;

            @include media-breakpoint-up(dsk) {
                width:70%;
            }
            @include media-breakpoint-up(lg) {
                width:40%;
                margin-top:30px;
            }

            &:after {
                content:'';
                position:absolute; left:0; bottom:0;
                width:100%; height:2px;
                background-color:$col-white;
            }
            input {
                display:block; float:left;
                width:100%;
                padding:10px 12px 10px 0; margin:0; border:0 none;
                font-size:16px; line-height:16px; color:$col-white; text-align:left; text-transform:uppercase; font-family:$font_raleway; font-weight:600;
                background-color:transparent;

                @include media-breakpoint-up(lg) {
                    padding:12px;
                    font-size:14px;
                    text-align:center;
                }

                &::-webkit-input-placeholder {
                    color:$col-white;
                }
                &::-moz-placeholder {
                    color:$col-white;
                }
                &:-ms-input-placeholder {
                    color:$col-white;
                }
                &:-moz-placeholder {
                    color:$col-white;
                }
            }

            button {
                position:absolute; right:-46px; top:100%;
                width:46px; height:46px;
                padding:0; margin:-23px 0 0 0; border:0 none;
                background-color:$col-light-green;
                @include prefix(border-radius, 100%, webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                i {
                    position:absolute; left:50%; top:50%;
                    width:16px; height:16px;
                    font-size:16px; line-height:16px; color:$col-white;
                    @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }
                span {
                    position:absolute; left:50%; bottom:-23px;
                    width:auto;
                    font-size:14px; line-height:16px; letter-spacing:0.16px; font-weight:800; text-transform:uppercase; color:$col-white;
                    @include prefix(transform, translateX(-50%), webkit moz o ms);
                }

                @include media-breakpoint-up(lg) {
                    &:hover {
                        background-color:$col-white;

                        i {
                            color:$col-red;
                        }
                    }
                }
            }
        }
    }
}

.social-list {
    margin-top:18px;
    text-align:center; font-size:0;

    @include media-breakpoint-up(dsk) {
        margin-top:26px;
    }

    li {
        display:inline-block; float:none;
        width:auto;
        margin-left:12px; margin-right:12px;

        @include media-breakpoint-up(sm-375) {
            margin-left:15px; margin-right:15px;
        }
        @include media-breakpoint-up(lg) {
            float:left;
            margin-left:40px; margin-right:0;
        }
        @include media-breakpoint-up(xl) {
            margin-left:50px;
        }

        a {
            position:relative;
            display:block; float:left;
            width:44px; height:44px;
            background-color:transparent;
            @include prefix(border-radius, 100%, webkit moz o ms);
            @include prefix(box-shadow, 0px 3px 6px 0px rgba(0,0,0,0.2), webkit moz o ms);
            @include prefix(transition, all .3s ease-out, webkit moz o ms);

            i {
                position:absolute; left:50%; top:50%;
                width:16px; height:16px;
                font-size:16px; line-height:16px; color:$col-dark-grey;
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                @include prefix(text-shadow, 0px 0px 6px rgba(0,0,0,0.2), webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }
            &:before {
                content:'';
                position:absolute; left:50%; top:50%;
                width:100%; height:100%;
                border:1px solid $col-red;
                @include prefix(border-radius, 100%, webkit moz o ms);
                @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
                @include prefix(box-shadow, inset 0px 3px 6px 0px rgba(0,0,0,0.2), webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
            }

            @include media-breakpoint-up(dsk) {
                width:66px; height:66px;

                i {
                    width:22px; height:22px;
                    font-size:22px; line-height:22px;
                }
            }
            @include media-breakpoint-up(lg) {
                &:hover {
                    background-color:$col-red;

                    i {
                        color:$col-white!important;
                        @include prefix(text-shadow, none, webkit moz o ms);
                    }
                    &:before {
                        @include prefix(box-shadow, none, webkit moz o ms);
                    }
                }
            }
        }
    }
}

.addthis-container .at-resp-share-element .at-share-btn {
	margin-right:10px;
}

.section-title {
    float:left;
    width:100%;
    margin-top:5px;
    text-align:center;

    > .row {
        width:100%;
        margin:0;
    }
    > div:not(.row) {
        position:relative;
        float:left;
        width:100%;
        text-align:center;

        em {
            display:block; float:left;
            width:100%;
            margin-bottom:-6px;
            font-size:34px; line-height:44px; font-style:normal; font-family:$font_caveat; font-weight:normal; letter-spacing:-2px; text-transform:none; color:$col-black;

            @include media-breakpoint-up(dsk) {
                margin-bottom:-10px;
                font-size:40px; line-height:50px;
            }
        }
        h1, .h1 {
            position:relative;
            display:inline-block; float:none;
            width:auto;
            padding:0 22px;
            flex-direction:column;
            background-color:$col-white;
            z-index:2;
        }

        &.line {
            &:before {
                content:none;
                position:absolute; left:50%; bottom:15px;
                width:100%; height:3px;
                background-position:top left; background-repeat:repeat-x; background-size:6px 3px; background-image:url('../img/dots-line-black.svg');
                z-index:1;
                @include prefix(transform, translateX(-50%), webkit moz o ms);

                @include media-breakpoint-up(dsk) {
                    content:''!important;
                }
            }

            &.white {
                &:before {
                    background-image:url('../img/dots-line-white.svg');
                }
            }
            &.red {
                &:before {
                    background-image:url('../img/dots-line-red.svg');
                }
            }
            &.bottom {
                position:relative;
                margin-bottom:40px;

                em {
                    position:absolute; left:50%; bottom:-30px;
                    width:auto;
                    white-space:nowrap;
                    @include prefix(transform, translateX(-50%), webkit moz o ms);
                    z-index:2;
                }

                @include media-breakpoint-up(dsk) {
                    margin-bottom:50px;
                }
            }
            &.horoscope {
                > h1, > .h1 {
                    position:relative;

                    &:before, &:after {
                        content:'';
                        position:absolute; top:-8px;
                        width:144px; height:91px;
                        background-position:center center; background-repeat:no-repeat; background-size:contain;

                        @include media-breakpoint-up(dsk) {
                            top:8px;
                        }
                    }
                    &:before {
                        left:-144px;
                        background-image:url('../img/horoscope-left.svg');
                    }
                    &:after {
                        right:-144px;
                        background-image:url('../img/horoscope-right.svg');
                    }
                }
            }

            &.love {
                > h1, > .h1 {
                    position:relative;

                    &:after {
                        content:'';
                        position:absolute; right:-32px; top:-28px;
                        width:38px; height:35px;
                        background-image:url('../img/example/love/love-message.svg'); background-position:center center; background-repeat:no-repeat; background-size:contain;

                        @include media-breakpoint-up(dsk) {
                            right:-60px; top:-28px;
                            width:62px; height:58px;
                        }
                    }
                }
            }
        }
    }
    p {
        &.abstract {
            margin-top:8px;
            font-size:17px; line-height:26px; color:$col-black;

            @include media-breakpoint-up(dsk) {
                font-size:24px; line-height:34px;
            }
        }
    }
}

.header-full {
    > a, > div {
        position:relative;
        display:block; float:left;
        width:100%;
        margin-top:8px;

        > div {
            position:relative;
            float:left;
            width:100%;

            &.content {
                position:absolute; left:0; bottom:0;
                display:flex;
                padding:30px 20px;
                flex-direction:column; justify-content:flex-end; align-items:center;
                width:100%; height:100%;
                z-index:3;

                @include media-breakpoint-up(dsk) {
                    padding:30px 18px;
                    align-items:flex-start;
                }
                @include media-breakpoint-up(xl) {
                    padding:30px 70px;
                }
                @include media-breakpoint-up(xl-1440) {
                    padding:30px 130px;
                }

                .label {
                    position:relative;
                    width:auto;
                    padding:8px 10px 14px 42px; margin-bottom:24px;
                    background-color:$col-light-grey;
                    @include prefix(border-top-left-radius, 15px, webkit moz o ms);
                    @include prefix(border-bottom-right-radius, 15px, webkit moz o ms);
                    @include prefix(box-shadow, 0px 3px 6px 0px rgba(0,0,0,0.3), webkit moz o ms);

                    img {
                        position:absolute; left:10px; top:50%;
                        width:22px; height:auto;
                        @include prefix(transform, translateY(-50%), webkit moz o ms);
                    }
                    p {
                        position:relative;
                        display:block; float:left;
                        width:auto;
                        padding-bottom:2px;
                        font-size:22px; line-height:26px; color:$col-dark-grey; font-weight:900;

                        &:before {
                            content:'';
                            position:absolute; left:0; bottom:0;
                            width:100%; height:2px;
                            background-color:$col-dark-grey;
                        }
                    }

                    @include media-breakpoint-up(dsk) {
                        padding:6px 12px 17px 46px; margin-bottom:32px;

                        img {
                            left:12px;
                            width:26px;
                        }
                        p {
                            font-size:30px; line-height:35px;
                        }
                    }
                }

                .title {
                    float:left;
                    width:100%;

                    @include media-breakpoint-up(lg) {
                        width:80%;
                    }
                    @include media-breakpoint-up(xxl) {
                        width:44%;
                    }

                    h2, .h2 {
                        position:relative;
                        padding-bottom:20px;
                        font-size:30px; line-height:40px; font-weight:900; color:$col-white; text-transform:inherit; text-align:center;

                        &:before {
                            content:'';
                            position:absolute; left:50%; bottom:0;
                            width:34px; height:2px;
                            background-color:$col-white;
                            @include prefix(transform, translateX(-50%), webkit moz o ms);
                        }

                        @include media-breakpoint-up(dsk) {
                            padding-bottom:30px;
                            font-size:34px; line-height:40px; text-align:left;

                            &:before {
                                left:0;
                                width:50px; height:3px;
                                @include prefix(transform, translateX(0%), webkit moz o ms);
                            }
                        }
                    }
                }
            }
            &.image {
                position:relative;
                float:left;
                width:100%; padding-bottom:464px;
                background-position:center top; background-repeat:no-repeat; background-size:cover;
                z-index:1;
                @include prefix(filter, grayscale(0), webkit moz o ms);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                @include media-breakpoint-up(dsk) {
                    padding-bottom:470px;
                }
                @include media-breakpoint-up(xl-1440) {
                    padding-bottom:630px;
                }

                &:before {
                    content:'';
                    position:absolute; left:0; top:0;
                    width:100%; height:100%;
                    background-color:$col-black40;
                }
            }
        }
    }
    > a {
        @include media-breakpoint-up(lg) {
            &:hover {
                .image {
                    @include prefix(filter, grayscale(100%), webkit moz o ms);
                }
            }
        }
    }
}

.filter-wrapper {
    position:relative;

    &:before, &:after {
        position:absolute; top:0;
        width:65px; height:100%;
        z-index:3;
    }
    &:before {
        content:none;
        left:-4px;
        background:rgb(255,255,255);
        background:-moz-linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        background:-webkit-linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        background:linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    }
    &:after {
        content:'';
        right:-4px;
        background:rgb(255,255,255);
        background:-moz-linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        background:-webkit-linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        background:linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        filter:progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#000000",GradientType=1);
    }

    &.no-scroll {
        &:before, &:after {
            content:none!important;
        }
    }
    &.filter-scroll-start {
        &:before {
            content:'';
        }
    }
    &.filter-scroll-end {
        &:after {
            content:none;
        }
    }
}

.filter {
    position:relative;
    float:left;
    width:calc(100% + 36px);
    padding:0 18px 15px 18px;
    margin-left:-18px; margin-right:-18px;
    text-align:center; font-size:0;
    overflow:auto; overflow-y:hidden;

    @include media-breakpoint-up(lg) {
        width:100%;
        padding:0;
        /*margin-bottom:15px;*/ margin-left:0; margin-right:0;
        overflow:visible;
    }

    ul {
        display:inline-block; float:none;
        width:auto;
        white-space:nowrap;

        @include media-breakpoint-up(lg) {
            white-space:normal;
        }

        li {
            display:inline-block; float:none;
            width:auto;
            margin:0 12px;

            @include media-breakpoint-up(lg) {
                margin:0 20px 15px 20px;
            }

            &:first-child {
                margin-left:0;
            }
            &:last-child {
                margin-right:0;
            }

            a {
                position:relative;
                display:block; float:left;
                width:auto;
                padding-bottom:6px;
                font-size:18px; line-height:20px; color:$col-black; letter-spacing:0.6px; font-weight:900; text-decoration:none;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                &:after {
                    content:'';
                    position:absolute; left:0; bottom:0;
                    width:100%; height:2px;
                    background-color:$col-light-red;
                }

                @include media-breakpoint-up(lg) {
                    font-size:15px; line-height:18px;

                    &:hover {
                        color:$col-grey;
                    }
                }
            }

            &.active {
                a {
                    color:$col-light-red;

                    &:after {
                        bottom:-4px;
                        height:6px;
                    }
                }
            }
        }
    }
}

.pagination-wrapper {
    float:left;
    width:100%;
    padding:20px 0; margin-top:30px;
    background-color:$col-band-red;

    @include media-breakpoint-up(dsk) {
        padding:24px 0; margin-top:40px;
    }

    ul {
        text-align:center;

        li {
            display:inline-block; float:none;
            width:auto;
            margin:0 16px;

            @include media-breakpoint-up(dsk) {
                margin:0 20px;
            }

            p {
                margin:8px 0;
                font-size:14px; line-height:28px; color:$col-black; font-weight:600;

                @include media-breakpoint-up(dsk) {
                    margin:10px 0;
                    font-size:16px;
                }
            }
            a {
                position:relative;
                display:block; float:left;
                width:40px; height:44px;

                @include media-breakpoint-up(dsk) {
                    width:44px; height:48px;
                }
                @include media-breakpoint-up(lg) {
                    &:hover {
                        i {
                            color:$col-red;
                        }
                    }
                }

                i {
                    position:absolute; top:50%;
                    width:18px; height:18px;
                    font-size:18px; line-height:18px; color:$col-black;
                    @include prefix(transform, translateY(-50%), webkit moz o ms);
                    @include prefix(transition, all .3s ease-out, webkit moz o ms);
                }
                &:after {
                    content:'';
                    position:absolute; left:0; bottom:0;
                    width:100%; height:3px;
                    background-color:$col-light-red;
                }

                &.disabled {
                    cursor:default; pointer-events:none;

                    i {
                        color:$col-black15;
                    }
                    &:after {
                        background-color:$col-light-red20;
                    }
                }
            }

            &:first-child {
                a {
                    i {
                        right:-3px;
                    }
                }
            }
            &:last-child {
                a {
                    i {
                        left:-3px;
                    }
                }
            }
        }
    }
}

.component-button {
    margin-top:24px;
    text-align:center;

    @include media-breakpoint-up(dsk) {
        margin-top:30px;
    }
}

.breadcrumb {
    display:none; float:left;
    margin-top:24px; margin-bottom:-15px;
    background-color:transparent;

    @include media-breakpoint-up(lg) {
        display:block;
        padding:0 30px;
    }
    @include media-breakpoint-up(xl-1440) {
        padding:0 calc(80px + 22 * ((100vw - 1440px) / 480));
    }
    @include media-breakpoint-up(xxl-1920) {
        padding:0 102px;
    }

    &.mt-0 {
        margin-top:24px!important;
    }

    ul {
        li {
            position:relative;
            width:auto;
            padding-right:17px;

            &:after {
                content:'';
                position:absolute; right:6px; top:50%;
                width:5px; height:1px;
                background-color:$col-red;
                @include prefix(transform, translateY(-50%), webkit moz o ms);
            }

            &:last-child {
                padding-right:0;

                &:after {
                    content:none;
                }
            }

            a, span {
                position:relative;
                display:block; float:left;
                width:auto;
                font-size:14px; line-height:16px; font-family:$font_raleway;
            }
            a {
                font-weight:600; color:$col-black;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);

                @include media-breakpoint-up(lg) {
                    &:hover {
                        color:$col-red;
                    }
                }
            }
            span {
                font-weight:800; color:$col-red;
            }
        }
    }
}
